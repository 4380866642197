export class MonitoringTreshold {
    monitoringTresholdId: number;
    monitoringId: number;
    type: CompareType;
    value: number;
    column: string;
    columnToCompare: string;
    compareWithColumn: boolean;
    offset?: number;
}

export enum CompareType {
    Greater = 0,
    Less = 1,
    Equals = 2,
    GreaterOrEquals = 3,
    LessOrEquals = 4
}
  