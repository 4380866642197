import { PermissionType } from './../enums/PermissionType';

export class Permission {
  permissionId: number;
  key: string;
  type: string;
  name: string;
  description: string;
  controller: string;
  action: string;
  pageId: number;
  permissionActive: boolean
  assignedPermissionsGroups: number[];
  typeEnum: number;
}
