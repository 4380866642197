<div class="monitoring-history mb-3">
    <div class="row">
        <div class="col-xl-6">
            <h5>Monitoring Historie</h5>
        </div>
        <div class="col-xl-6 d-flex align-items-center mt-3 mt-xl-0" *ngIf="yearButtons" style="overflow: auto;">
            <nb-button-group>
                <button *ngFor="let yearText of yearButtons" nbButtonToggle [pressed]="year == yearText" (click)="routeToYear(yearText)">{{yearText}}</button>
                <button nbButtonToggle [pressed]="recent" (click)="routeToRecent()">30 Tage</button>
            </nb-button-group>
        </div>
    </div>
</div>

<nwa-historychart (datasetClicked)="onDataSetClicked($event)" [year]="year" [recent]="recent"></nwa-historychart>

@if(!historyFailures) {
    <nb-card>
        <nb-card-body class="info d-flex justify-content-center align-items-center flex-column text-center">
            <nb-icon icon="paper-plane-outline" style="font-size: 4rem;" status="primary" class="mb-3"></nb-icon>
            <h3>Wähle einen Punkt im Diagramm aus,<br> um die Details eines Monitorings anzusehen!</h3>
        </nb-card-body>
    </nb-card>
}

@if(historyFailures) {
    <div class="details-header">
        <span><b>Anschluss:</b> {{historyFailures[0].nodeId}}</span>
        <span class="ms-5"><b>Tag:</b> {{historyFailures[0].startDay | date: 'dd.MM.YY HH:mm'}}</span>
    </div>
}

@for (failure of historyFailures; track failure.monitoringFailureId) {

    <nb-card>
        <nb-card-body>
            <div class="row">
                <div class="col d-flex align-items-center">
                    <nb-icon icon="alert-triangle-outline" style="color: red;"></nb-icon>
                    <b class="ms-2">{{failure.title}}</b>
                </div>
                <div class="col d-flex align-items-center" *ngIf="failure.nodeDescription">
                    <nb-icon icon="home-outline"></nb-icon>
                    <b class="ms-2">{{failure.nodeDescription}}</b>
                </div>
                <div class="col d-flex align-items-center">
                    <nb-icon icon="clock-outline"></nb-icon>
                    <b class="ms-2">{{failure.startTime | date: 'dd.MM.YY HH:mm'}} - {{failure.endTime | date: 'dd.MM.YY HH:mm'}}</b>
                </div>
                <div class="col d-flex justify-content-end">
                    <div class="details-monitoring">
                        <button nbButton status="control" (click)="routeTo(failure.monitoringFailureId)">
                            <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                        </button>
                    </div>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
}
