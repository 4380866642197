export class ChartCardConfig {
  consumptionTitle: string;
  temperatureTitle: string;
  efficiencyTitle: string;

  consumptionSeriesTitle: string;
  temperatureSeriesTitle: string;

  unit: string;

  showClickHint: boolean;
  yAxisConsumptionTitle: string;
  yAxisTemperatureTitle: string;
  xAxisTitle: string;
}
