export let chartOptions = {
  series: [],
  chart: {
    height: 250,
    type: 'radialBar',
    offsetY: -20,
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: '#ffffff',
        strokeWidth: '97%',
        margin: 5, // margin is in pixels
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -2,
          fontSize: '22px',
          color: 'black',
          fontFamily: 'Open Sans',
          fontWeight: 400
        },
      },
    },
  },
  fill: {
    type: 'solid',
    colors: ['#28b59b']
  },
  stroke: {
    lineCap: 'round',
  }
};
