import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'nwa-earth',
  templateUrl: './earth.component.svg',
  styleUrls: ['./earth.component.less']
})
export class EarthComponent implements OnInit, AfterViewInit {
   @ViewChild('pfadGn') pfad;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(){
    let length = this.pfad.nativeElement.getTotalLength();
 }
}
