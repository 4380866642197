import { ContractualAmountOfHeat } from './../models/ContractualAmountOfHeat';
import { PriceFilter as Filter } from './../models/PriceFilter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http2ServerRequest } from 'http2';

@Injectable({
  providedIn: 'root'
})
export class HeatAmountService {

  constructor(private http: HttpClient) { }

  public getContractualAmountOfHeats(filter: Filter) {
    return this.http.get<ContractualAmountOfHeat[]>('/api/bill/amountofheat?filter=' + encodeURIComponent(JSON.stringify(filter)));
  }

  public updateAmountOfHeat(amountOfHeat: ContractualAmountOfHeat) {
    return this.http.post('/api/bill/amountofheat', amountOfHeat);
  }

  public deleteAmountOfHeat(amountOfHeat: ContractualAmountOfHeat) {
    return this.http.delete('/api/bill/amountofheat/' + amountOfHeat.id);
  }
}
