<!-- <div class="row selection-row">
    <div class="col-xl-4 mt-1 mt-xl-0">
        <div class="price-button d-flex align-items-center flex-column">
            <nb-icon icon="fa-globe" pack="fa"></nb-icon>
            Grundpreis
        </div>
    </div>
    <div class="col-xl-4 mt-1 mt-xl-0">
        <div class="price-button d-flex align-items-center flex-column">
            <nb-icon icon="fa-fire" pack="fa"></nb-icon>
            Wärmepreis
        </div>
    </div>
    <div class="col-xl-4 mt-1 mt-xl-0">
        <div class="price-button d-flex align-items-center flex-column">
            <nb-icon icon="fa-percent" pack="fa"></nb-icon>
            Steuern
        </div>
    </div>
</div> -->
<h5>Preiseinstellungen</h5>
<span class="subtitle">Pflege der grundlegenden Preise als Grundlage für die Rechnungserstellung</span>
<nb-card class="mt-3">
    <nb-card-body>
        <nb-tabset (changeTab)="onChangeTab($event)" fullWidth>
            <nb-tab tabTitle="Grundpreis" tabIcon="globe-outline" responsive>
                <nwa-pricelist [priceService]="_basePriceService" id="Grundpreis" [loadPrices]="loadPriceEvent" unitString="€"></nwa-pricelist>
            </nb-tab>
            <nb-tab tabTitle="Wärmepreis" [tabIcon]="{'icon': 'fa-fire', 'pack': 'fa'}" responsive>
                <nwa-pricelist [priceService]="_heatPriceService" id="Wärmepreis" [loadPrices]="loadPriceEvent" unitString="€"></nwa-pricelist>
            </nb-tab>
            <nb-tab tabTitle="Steuern" tabIcon="percent-outline" responsive>
                <nwa-pricelist [priceService]="_vatService" id="Steuern" [loadPrices]="loadPriceEvent" unitString="%"></nwa-pricelist>
            </nb-tab>
        </nb-tabset>
    </nb-card-body>
</nb-card>
