<div class="Verlauf" style="position: relative;">
    <nwa-fastnavigation></nwa-fastnavigation>
    <h5>Verlauf</h5>
    <span class="subtitle">{{statsInfo}}</span>
    <div class="row mt-3">
        <div class="col-12 col-xxl-3 d-none d-xxl-block">
            <nb-card size="giant">
                <nb-card-header>Konfiguration
                    <button nbButton class="float-end" size="small" (click)="unCheckAllColumns()" shape="round"><nb-icon icon="checkmark-square-outline"></nb-icon></button>
                </nb-card-header>
                <nb-card-body>
                    <ng-container *ngFor="let column of columns">
                        <nb-checkbox style="display: inline-block; vertical-align: middle;" [value]="column.name" [(checked)]="column.checked" (checkedChange)="changedConfiguration($event)" [nbTooltip]="column.longDescription || column.description" nbTooltipStatus="primary">
                            <span>{{column.description | truncate: 35}}</span>
                        </nb-checkbox>
                        <br>
                    </ng-container>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="col-12 col-xxl-9" style="position: relative;">

            <!-- Mobile Konfiguration -->
            <nb-card *ngIf="showMobileConfig" size="small" class="mobile-config d-xxl-none" #mobileConfig>
                <nb-card-header style="line-height: 35px;">
                    <span style="vertical-align: middle;">Konfiguration</span>
                    <button nbButton shape="round" class="float-end" (click)="hideMobileConfig()"><i class="fas fa-times"></i></button>
                    <button nbButton class="float-end me-1" shape="round"><nb-icon icon="checkmark-square-outline" (click)="unCheckAllColumns()"></nb-icon></button>
                </nb-card-header>
                <nb-card-body>
                    <ng-container *ngFor="let column of columns">
                        <nb-checkbox style="display: inline-block; vertical-align: middle;" [value]="column.name" [(checked)]="column.checked" (checkedChange)="changedConfiguration($event)">
                            <span>{{column.description | truncate: 35}}</span>
                        </nb-checkbox>
                        <!-- <nb-icon style="vertical-align: middle;" class="ms-1 d-none d-xxl-inline-block" icon="info" status="info" [nbTooltip]="column.longDescription || column.description" nbTooltipStatus="primary"></nb-icon> -->
                        <br>
                    </ng-container>
                </nb-card-body>
            </nb-card>
            <!---->

            <nb-card size="giant" [nbSpinner]="loading">
                <nb-card-header>
                    <span style="vertical-align: middle;">Verlauf</span>
                    <nb-icon icon="info" status="info" nbTooltip="Stellt Protokolldaten der Heizzentrale zeitlich dar." nbTooltipStatus="primary" class="ms-1"></nb-icon>
                    <button nbButton shape="round" class="ms-1 d-xxl-none" (click)="openMobileConfig()"><i class="fas fa-cog"></i></button>
                    <div class="float-md-end">
                        <div style="overflow: auto;">
                            <nb-icon icon="refresh-outline" status="basic" (click)="showData()" style="cursor: pointer"></nb-icon>

                            <nb-button-group size="small" class="ms-2">
                                <button nbButtonToggle value="1" (click)="changedTimeRange(1)" [pressed]="firstRangePressed">Letzte 2 Tage</button>
                                <button nbButtonToggle value="2" (click)="changedTimeRange(2)" [pressed]="rangePressed">Letzte Woche</button>
                                <button nbButtonToggle value="3" (click)="changedTimeRange(3)" [pressed]="rangePressed">Letzter Monat</button>
                                <!-- <button nbButtonToggle value="4" (click)="changedTimeRange(4)">Letzte 3 Monate</button> -->
                                <nb-form-field class="ms-2">
                                    <input id="rangeInput" nbInput [nbDatepicker]="rangepicker" placeholder="Zeitraum wählen..." [style.border]="dateRangeBorder()" #dateinput>
                                    <button nbSuffix nbButton ghost (click)="resetDateRange()">
                            <nb-icon icon="close-outline" pack="eva">
                            </nb-icon>
                          </button>
                                </nb-form-field>
                                <!-- <button nbButtonToggle value="3" (click)="changedTimeRange(3)">Letztes Jahr</button> -->
                            </nb-button-group>

                            <nb-rangepicker #rangepicker format="dd.MM.yyyy" (rangeChange)="dateChanged($event)"></nb-rangepicker>
                        </div>
                    </div>

                </nb-card-header>
                <nwa-protocolchart #chart></nwa-protocolchart>
            </nb-card>
        </div>
    </div>
    <!-- <div class="row">
    <div class="col-md-5">
        <nb-card size="tiny">
            <nb-card-header>Preis</nb-card-header>
            <div class="price">
                8ct/kWh
            </div>
        </nb-card>
        <nb-card size="tiny">
            <nb-tabset>
                <nb-tab tabTitle="Simple Tab #1">
                    Tab content 1
                </nb-tab>
                <nb-tab tabTitle="Simple Tab #2">
                    Tab content 2
                </nb-tab>
            </nb-tabset>
        </nb-card>
    </div>
    <div class="col-md-7">
        <nb-card size="medium">
            <nb-card-header>Test</nb-card-header>
            <nb-card-body>
                Tescht
            </nb-card-body>
        </nb-card>
    </div>
</div> -->

</div>
