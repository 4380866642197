import { PriceService } from './../helpers/priceservice';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PriceFilter } from '../models/PriceFilter';
import { Price } from '../models/Price';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VatService implements PriceService {

  constructor(private http: HttpClient) { }

  public getPrices(filter: PriceFilter) {
    return this.http.get<Price[]>('/api/bill/vat?filter=' + encodeURIComponent(JSON.stringify(filter)))
    .pipe(
      map((prices)=>{
        prices.map(price => {
          price.validFrom = price.validFrom ? new Date(price.validFrom) : null;
          price.validTo = price.validTo ? new Date(price.validTo) : null;
          return price;
        })
        return prices;
      })
    )
  }

  public updatePrice(price: Price) {
    return this.http.post('/api/bill/vat', price);
  }

  public deletePrice(price: Price) {
    return this.http.delete('/api/bill/vat/' + price.id);
  }
}
