import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthKey } from '@root/app/models/AuthKey';
import { UserpickerComponent } from '@root/app/components/userpicker/userpicker.component';
import { UserModel } from '@root/app/models/UserModel';
import { AuthenticationService } from '@root/app/services/authentication.service';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'nwa-authkey',
  templateUrl: './authkey.component.html',
  styleUrl: './authkey.component.less'
})
export class AuthkeyComponent implements OnInit{
  @ViewChild(UserpickerComponent) userPicker: UserpickerComponent;
  @ViewChild('deleteConfirmDialog') deleteConfirmDialogTemplate;
  @ViewChild('qrDialogTemplate') qrDialogTemplate;
  @ViewChild('qrCode') qrCode;
  deletionDialog;
  qrDialog
  loading: boolean = true;
  authKeys: AuthKey[];
  authKeyIdToDelete: number;
  selectedKey: AuthKey;

  constructor(private authenticationService: AuthenticationService,
    private dialogService: NbDialogService) {}

  ngOnInit(): void {
    this.fetchKeys();
  }

  public fetchKeys() {
    this.loading = true;
    this.authenticationService.getAuthKeys().subscribe((keys)=> {
      this.authKeys = keys as AuthKey[];
      this.loading = false;
    })
  }

  public add() {
    this.userPicker.open();
  }

  public onUserPicked(user: UserModel[]) {
    user.forEach((user, index) => {
      this.authenticationService.createAuthKey(user.userId).subscribe(x => {
        this.fetchKeys();
      });
    });
  }

  public showQrCode(authKey: AuthKey) {
    this.selectedKey = authKey;
    this.qrDialog = this.dialogService.open(this.qrDialogTemplate);
  }

  public generateLoginUrl(authKey: AuthKey) {
    return window.location.origin + "/login#qrKey=" + authKey.qrKey;
  }

  public saveAsImage(authKey: AuthKey) {
    let qrCodeElement = this.qrCode.qrcElement.nativeElement.querySelector("canvas").toDataURL("image/png");
    if (qrCodeElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(qrCodeElement);
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      // name of the file
      link.download = `NWDashboard Zugangscode ${authKey.user.firstname} ${authKey.user.lastname}`;
      link.click()
    }
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  //                    Key Manipulation
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  

  public updateValidFrom(authKeyId: number, date) {
    let authKey = this.getKey(authKeyId);
    authKey.validFrom = date;

    this.authenticationService.updateQrKey(authKey).subscribe();
  }

  public updateValidTo(authKeyId: number, date) {
    let authKey = this.getKey(authKeyId);
    authKey.validTo = date;

    this.authenticationService.updateQrKey(authKey).subscribe();
  }

  public requestKeyDeletion(authKeyId: number) {
    this.authKeyIdToDelete = authKeyId;
    this.deletionDialog = this.dialogService.open(this.deleteConfirmDialogTemplate);
  }

  public deleteKey() {
    this.authenticationService.deleteQrKey(this.authKeyIdToDelete).subscribe(x => {
      this.fetchKeys();
      this.deletionDialog.close();
    });
  }

  public toggleAuthKeyActivation(authKeyId: number, event) {
    let authKey = this.getKey(authKeyId);
    this.authenticationService.updateQrKey(authKey).subscribe();
  }

  private getKey(authKeyId: number): AuthKey {
    return this.authKeys.find(x => x.authKeyId == authKeyId);
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  //                    Helpers
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }
}
