<div class="pwlessauth">
    <div class="d-flex align-items-center mb-3">
        <i class="fas fa-fingerprint me-2 fa-2x"></i><h5>Passwortlose Anmeldung einrichten</h5>
    </div>

    <span>Einfach, schnell und sicher anmelden, ohne sich an komplizierte Passwörter erinnern zu müssen.<br> Die neue passwortlose Anmeldung bietet eine starke Authentifizierungsmethode, die auf biometrischen Daten oder Sicherheitsschlüsseln basiert.
        <br>Richte jetzt einmalig die passwortlose Anmeldung ein und melde dich zukünftig in sekundenschnelle an!
    </span>

    <div class="row">
        <div class="col">
            <div class="spacer"></div>
            <div class="point d-flex flex-row align-items-center">
                <div class="circle">1</div>
                <span>QR-Code abscannen</span>
            </div>
            <div class="explain">
                Klicke auf den <b>Einrichten</b>-Button, es erscheint ein QR-Code. Scanne diesen mit deinem Smartphone ab! 
                <br><br><i>Hinweis: An Smartphone-Geräten entfällt dieser Schritt!</i>
            </div>
        
            <div class="spacer"></div>
            <div class="point d-flex flex-row align-items-center">
                <div class="circle">2</div>
                <span>Folge den Anweisungen auf deinem Smartphone</span>
            </div>
            <div class="explain">
                Auf deinem Smartphone werden die nächsten Anweisungen angezeigt. Akzeptiere, dass du dich über den QR-Code verbinden willst und erstelle einen 
                neuen PassKey, indem du auf <b>Weiter</b> klickst.
            </div>
        
            <div class="spacer"></div>
            <div class="point d-flex flex-row align-items-center">
                <div class="circle">3</div>
                <span>Fertig!</span>
            </div>
            <div class="explain">
                Das wars! Du kannst dich nun, über deinen PassKey ohne Angabe eines Benutzernamens oder Passworts anmelden! Klicke hierfür auf dem Anmelde-Formular des Dashboards auf den
                <b>PassKey</b>-Button, wähle im aufploppenden Menü dein Smartphone aus. Du erhältst auf deinem Gerät eine Benachrichtigung. Klicke diese an, und bestätige die Anmeldung.
            </div>
            <div class="spacer"></div>
            
            <button *ngIf="!usesWebAuthn" nbButton type="submit" (click)="register()" class="login-button" status="primary" size="large" fullWidth>Einrichten</button>

            <nb-card *ngIf="usesWebAuthn" class="webauthn-info">
                <nb-card-body>
                    Du verwendest bereits die passwortlose Anmeldung!
                    <button nbButton type="submit" outline (click)="remove()" status="danger" size="tiny" class="float-end"><nb-icon icon="close-outline"></nb-icon> Deaktivieren</button>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="col-md d-flex justify-content-center">
            <img src="assets/images/loginwebauthn.svg" height="70%" width="100%" class="d-none d-md-block">
        </div>
    </div>
</div>