import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Monitoring } from '../../../models/Monitoring';
import { MonitoringService } from '../../../services/monitoring.service';

@Component({
  selector: 'nwa-managemonitorings',
  templateUrl: './managemonitorings.component.html',
  styleUrls: ['./managemonitorings.component.less']
})
export class ManagemonitoringsComponent implements OnInit {
  @ViewChild('dialogRefEditMonitoring') dialogTemplateEditMonitoring;
  @ViewChild('dialogDeleteMonitoring') dialogTemplateDeleteMonitoring;
  loading: boolean = true;

  monitorings: Monitoring[];
  editingMonitoring: Monitoring;
  dialogRefEditMonitoring;
  dialogRefDeleteMonitoring;
  monitoringToDelete: Monitoring;

  constructor(
    private monitoringService: MonitoringService,
    private dialogService: NbDialogService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    // setTimeout(() => this.openEditMonitoring(), 500);
    this.loadMonitorings();
  }

  private loadMonitorings() {
    this.loading = true;
    this.monitoringService.getMonitorings().subscribe(res => {
      this.monitorings = res;
      this.loading = false;
    });
  }

  public openEditMonitoring(monitoringObject: Monitoring = null) {
    if (monitoringObject) {
      this.editingMonitoring = monitoringObject;
    } else {
      this.editingMonitoring = new Monitoring();
      this.editingMonitoring.monitoringId = 0;
    }

    this.dialogRefEditMonitoring = this.dialogService.open(this.dialogTemplateEditMonitoring);

    // setTimeout(() => this.dialogRefEditMonitoring.close(), 1000);
  }

  public updateMonitoring(monitoring: Monitoring){
    this.monitoringService.updateMonitoring(monitoring).subscribe(() => {
      this.dialogRefEditMonitoring.close();
      this.toastr.success("Monitoring gespeichert");
      this.loadMonitorings();
    });
  }

  public requestMonitoringDelete(monitoring: Monitoring) {
    this.monitoringToDelete = monitoring;
    this.dialogRefDeleteMonitoring = this.dialogService.open(this.dialogTemplateDeleteMonitoring);
  }

  public deleteMonitoring(monitoring: Monitoring) {
    this.monitoringService.deleteMonitoring(monitoring.monitoringId).subscribe(() => {
      this.dialogRefDeleteMonitoring.close();
      this.toastr.success("Monitoring gelöscht");
      this.loadMonitorings();
    })
  }

  public abortMonitoringEdit() {
    this.dialogRefEditMonitoring.close();
    this.loadMonitorings();
  }

  public toggleMonitoringActivation(monitoring: Monitoring) {
    this.monitoringService.toggleMonitoringActivation(monitoring.monitoringId, !monitoring.isActive).subscribe(x => {
      this.loadMonitorings();
    })
  }

  public hasMonitorings() {
    return Array.isArray(this.monitorings) && this.monitorings.length
  }
}
