import { YearstatsComponent } from './pages/stats/yearstats/yearstats.component';
import { InfoComponent } from './pages/info/info.component';
import { HeatAmountComponent } from './pages/bill/heatamount/heatamount.component';
import { PricesettingsComponent } from './pages/bill/pricesettings/pricesettings.component';
import { BillComponent } from './pages/bill/bill.component';
import { MonthlystatsComponent } from './pages/stats/monthlystats/monthlystats.component';
import { ErrorComponent } from './pages/error/error.component';
import { ManageComponent } from './pages/settings/permissions/manage/manage.component';
import { PermissionsComponent } from './pages/settings/permissions/permissions.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { NodesComponent } from './pages/settings/nodes/nodes.component';
import { UsersComponent } from './pages/settings/users/users.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginLayoutComponent } from './pages/_layout/login-layout/login-layout.component';
import { PageLayoutComponent } from './pages/_layout/page-layout/page-layout.component';
import { NetworkComponent } from './pages/network/network.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TrendComponent } from './pages/trend/trend.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AuthGuard } from './services/auth.guard';
import { BillgenerationComponent } from './pages/bill/billgeneration/billgeneration.component';
import { ManagemonitoringsComponent } from './pages/settings/managemonitorings/managemonitorings.component';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import { MonitoringdetailsComponent } from './pages/monitoring/monitoringdetails/monitoringdetails.component';
import { PwlessauthComponent } from './pages/pwlessauth/pwlessauth.component';
import { MonitoringhistoryComponent } from './pages/monitoring/monitoringhistory/monitoringhistory.component';
import { AuthkeyComponent } from '@root/app/pages/settings/authkey/authkey.component';
import { WorldearthhourComponent } from '@root/app/pages/other/worldearthhour/worldearthhour.component';
import { SolarComponent } from '@root/app/pages/solar/solar.component';
import { AiChatComponent } from '@root/app/components/aichat/aichat.component';

const routes: Routes = [
  // SiteLayout
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {path: '', redirectTo: '/uebersicht', pathMatch: 'full' },
      {path: 'uebersicht?id=2220', redirectTo: '/uebersicht?id=220', pathMatch: 'full' },
      {path: 'uebersicht', component: OverviewComponent, canActivate: [AuthGuard]},
      {path: 'uebersicht/:id', component: OverviewComponent, canActivate: [AuthGuard]},
      {path: 'verlauf', component: TrendComponent, canActivate: [AuthGuard]},
      {path: 'verlauf/:id', component: TrendComponent, canActivate: [AuthGuard]},
      {path: 'waermenetz', component: NetworkComponent, canActivate: [AuthGuard]},
      {path: 'monitoring', component: MonitoringComponent, canActivate: [AuthGuard]},
      {path: 'monitoringdetails', component: MonitoringdetailsComponent, canActivate: [AuthGuard]},
      {path: 'monitoringhistory', component: MonitoringhistoryComponent, canActivate: [AuthGuard]},
      {path: 'einstellungen', component: SettingsComponent, canActivate: [AuthGuard]},
      {path: 'einstellungen/benutzer', component: UsersComponent, canActivate: [AuthGuard]},
      {path: 'einstellungen/anschluesse', component: NodesComponent, canActivate: [AuthGuard]},
      {path: 'einstellungen/berechtigungen', component: PermissionsComponent, canActivate: [AuthGuard]},
      {path: 'einstellungen/berechtigungen/verwaltung', component: ManageComponent, canActivate: [AuthGuard]},
      {path: 'einstellungen/monitorings-verwalten', component: ManagemonitoringsComponent, canActivate: [AuthGuard]},
      {path: 'einstellungen/keyanmeldung', component: AuthkeyComponent, canActivate: [AuthGuard]},
      {path: 'monatsuebersicht', component: MonthlystatsComponent, canActivate: [AuthGuard]},
      {path: 'rechnung', component: BillComponent, canActivate: [AuthGuard]},
      {path: 'rechnung/preise', component: PricesettingsComponent, canActivate: [AuthGuard]},
      {path: 'rechnung/generieren', component: BillgenerationComponent, canActivate: [AuthGuard]},
      {path: 'rechnung/leistung', component: HeatAmountComponent, canActivate: [AuthGuard]},
      {path: 'info', component: InfoComponent, canActivate: [AuthGuard]},
      {path: 'jahresuebersicht', component: YearstatsComponent, canActivate: [AuthGuard]},
      {path: 'passwortlos', component: PwlessauthComponent, canActivate: [AuthGuard]},
      {path: 'solar', component: SolarComponent, canActivate: [AuthGuard]}
    ]
  },

  // LoginLayout
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {path: 'error', component: ErrorComponent}
    ]
  },

    // NoLayout
    {path: 'earthhour', component: WorldearthhourComponent},
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
