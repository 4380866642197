<h5>Benutzerkonten</h5>
<span class="subtitle">Pflege der Nutzer. Nur hier gepflegte und als "aktiv" markierte Nutzer können sich im Dashboard
  mit ihren Nutzerdaten von nahwaerme-amerbach.de anmelden.</span>
<nb-card class="mt-3">
  <nb-card-body [nbSpinner]="loading">
    <!-- LOADING -->
    <ng-container *ngIf="loading then: loadList"></ng-container>
    <!-- CONTENT -->

    <table class="table" *ngIf="!loading">
      <thead>
        <tr>
          <th>Anschlüsse</th>
          <th>Benutzername</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Benutzergruppe</th>
          <th>Aktiv</th>
          <th></th>
          <th class="text-center">
            <nb-icon icon="eye-outline" nbTooltip="Letzer Login" nbTooltipStatus="primary"></nb-icon>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users; let i = index">
          <ng-container *ngIf="!user.editing">
            <td>{{user.nodes | arrayjoin}}</td>
            <td>{{user.username}}</td>
            <td>{{user.firstname}}</td>
            <td>{{user.lastname}}</td>
            <td>{{getUserGroupName(user.userGroupId)}}</td>
            <td>
              <nb-checkbox #nbCheckbox [checked]="user.activated" [attr.data-userid]="user.userId"
                (change)="toggleUserActivation($event, nbCheckbox)" [disabled]="currentUserId === user.userId">
              </nb-checkbox>
            </td>
            <td>
              <div class="row">
                <div class="col">
                  <button nbButton status="control"  (click)="toggleEditMode(user)">
                    <nb-icon icon="edit-outline"></nb-icon>
                  </button>
                  <button nbButton status="control" class="action-button" (click)="deleteUser(user)" [disabled]="currentUserId === user.userId">
                    <nb-icon icon="trash-outline"></nb-icon>
                  </button>
                </div>
              </div>
            </td>
            <td>
              {{user.lastLoginTime | date: 'dd.MM.YY'}}
            </td>
          </ng-container>
          <!-- User Edit -->
          <ng-container *ngIf="user.editing">
            <td>
              <nb-select multiple placeholder="Anschlüsse" [(selected)]="user.nodes" fullWidth size="small">
                <nb-option *ngFor="let node of nodes" [value]="node.nodeId">{{node.nodeId}}</nb-option>
              </nb-select>
            </td>
            <td>
              <input nbInput [(ngModel)]="user.username" placeholder="Benutzername" fieldSize="small">
            </td>
            <td>
              <input nbInput [(ngModel)]="user.firstname" placeholder="Vorname" fieldSize="small">
            </td>
            <td>
              <input nbInput [(ngModel)]="user.lastname" placeholder="Nachname" fieldSize="small">
            </td>
            <td>
              <nb-select placeholder="Benutzergruppe" [(selected)]="user.userGroupId" [fullWidth]="true" size="small">
                <nb-option *ngFor="let userGroup of userGroups" [value]="userGroup.groupId">{{userGroup.name}}
                </nb-option>
              </nb-select>
            </td>
            <td>
              <nb-checkbox #nbCheckbox [(checked)]="user.activated" [attr.data-userid]="user.userId"
                (change)="toggleUserActivation($event, nbCheckbox)" [disabled]="currentUserId === user.userId">
              </nb-checkbox>
            </td>
            <td>
              <div class="row">
                <div class="col">
                  <button nbButton (click)="updateUser(user)"><i class="fas fa-chevron-right fa-xs"></i></button>
                </div>
              </div>
            </td>
          </ng-container>
        </tr>

        <!-- Add User -->
        <tr>
          <td class="col-2 align-self-center">
            <nb-select multiple placeholder="Anschlüsse" [(selected)]="userToAdd.nodes" size="small">
              <nb-option *ngFor="let node of nodes" [value]="node.nodeId">{{node.nodeId}}</nb-option>
            </nb-select>
          </td>
          <td>
            <input nbInput [(ngModel)]="userToAdd.username" placeholder="Benutzername" fieldSize="small">
          </td>
          <td>
            <input nbInput [(ngModel)]="userToAdd.firstname" placeholder="Vorname" fieldSize="small">
          </td>
          <td>
            <input nbInput [(ngModel)]="userToAdd.lastname" placeholder="Nachname" fieldSize="small">
          </td>
          <td>
            <nb-select placeholder="Benutzergruppe" [(selected)]="userToAdd.userGroupId" [fullWidth]="true" size="small">
              <nb-option *ngFor="let userGroup of userGroups" [value]="userGroup.groupId">{{userGroup.name}}</nb-option>
            </nb-select>
          </td>
          <td>
            <nb-checkbox #nbCheckbox [(checked)]="userToAdd.activated"></nb-checkbox>
          </td>
          <td>
            <div class="row">
              <div class="col">
                <button nbButton (click)="addUser()"><i class="fas fa-chevron-right fa-xs"></i></button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </nb-card-body>
</nb-card>

<ng-template #dialogDeleteUser let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Benutzer "{{userToDelete.username}}" löschen</nb-card-header>
    <nb-card-body>
      <span>Soll der Benutzer wirklich gelöscht werden?</span>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton outline (click)="ref.close()" class="float-start">Abbrechen</button>
      <button nbButton status="danger" (click)="deleteUserConfirmed()" class="float-end">Löschen</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #loadList>
  <div class="row">
    <div class="col-2">
      <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    </div>
    <div class="col-2">
      <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    </div>
    <div class="col-2">
      <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    </div>
    <div class="col-3">
      <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    </div>
    <div class="col-1">
      <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    </div>
    <div class="col-2">
      <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    </div>
  </div>
  <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
  <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
  <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
  <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
</ng-template>
