import { TimeRange } from '../../models/TimeRange';
import { ProtColumn } from '../../models/ProtColumn';
import { StatsService } from '../../services/stats.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatNumber } from '@angular/common';
import { ProtocolChartConfig } from '../../models/ProtocolChartConfig';
// import * as german from 'apexcharts/dist/locales/de.json'; 

@Component({
  selector: 'nwa-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.less']
})
export class TrendComponent implements OnInit {
  @ViewChild('chart') chart;
  @ViewChild('dateinput') dateinput;
  columns: ProtColumn[];

  chartConfig: ProtocolChartConfig = new ProtocolChartConfig();
  loading: boolean = true;
  statsInfo: string;
  showMobileConfig: boolean = false;
  startDate: Date;
  endDate: Date;
  rangePressed: boolean;
  firstRangePressed: boolean = true;

  constructor(private statsService: StatsService,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    // Parameter auslesen
    this.activatedRoute.queryParams.subscribe(params => {
      this.initData(params);

      this.chartConfig = new ProtocolChartConfig();
      this.chartConfig.nodeId = params.id;
      this.chartConfig.range = TimeRange.TwoDays;
    });
  }

  initData(params) {
    this.statsService.getStatsInformation(params.id).subscribe(x =>{
      this.statsInfo = x;
    });

    this.statsService.getStatColumns(params.id).subscribe(x => {
      this.columns = x;
      var localConfig = JSON.parse(localStorage.getItem('chartConfig'));

      if (localConfig) {
        localConfig.forEach(element => {
          var column = this.columns.find(col => col.propertyName == element.propertyName);
          if (column) {
            column.checked = element.checked;
          }
        });
      }
      this.showData();
    });
  }

  showData() {
    this.loading = true;
    this.updateConfiguration();
    this.chart.loadData(this.chartConfig).subscribe(x => this.loading = false);
  }

  dateChanged(event) {
    if (event.start && event.end) {
      this.rangePressed = false;
      this.firstRangePressed = false;

      this.chartConfig.startDate = new Date(event.start);
      this.chartConfig.endDate = new Date(event.end);
      this.chartConfig.range = TimeRange.Custom;
      this.showData();
    }
  }

  dateRangeBorder() {
    if (this.chartConfig.range == TimeRange.Custom) {
      return '1px solid #0d6efd';
    }
  }

  resetDateRange() {
    this.dateinput.nativeElement.value = '';
    this.chartConfig.range == TimeRange.TwoDays;
    this.showData();

    this.firstRangePressed = true;
  }

  changedConfiguration(toggle: boolean) {
    localStorage.setItem('chartConfig', JSON.stringify(this.columns));
    this.updateConfiguration();
    this.chart.updateConfiguration(this.chartConfig);
  }

  updateConfiguration() {
    this.chartConfig.activeColumns = this.columns.filter(x => x.checked);
  }

  changedTimeRange(range: TimeRange) {
    this.chartConfig.range = range;
    this.showData();
  }

  public openMobileConfig() {
    this.showMobileConfig = true;
  }

  public hideMobileConfig() {
    this.showMobileConfig = false;
  }

  public unCheckAllColumns() {
    this.columns.forEach(x => x.checked = false);
    this.changedConfiguration(true);
  }
}
