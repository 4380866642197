import { ContextMenuItem } from 'src/app/models/ContextMenuItem';
import { Router } from '@angular/router';
import { ContextmenuService } from './../../services/contextmenu.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.less']
})
export class ContextmenuComponent implements OnInit {
  @Input() items;
  @Output() clicked: EventEmitter<ContextMenuItem> = new EventEmitter();
  @ViewChild('menu') menuElement;

  constructor(private contextmenuService: ContextmenuService,
    private router: Router) { }

  ngOnInit(): void {
  }

  emit() {
    this.contextmenuService.emitCloseEvent(null);
  }

  public doClickAction(item) {
    this.clicked.emit(item);

    if (item.link) {
      this.router.navigate([item.link], { queryParams: item.queryParams} );
    }
  }
}
