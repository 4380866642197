import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'nwa-fastnavigation',
  templateUrl: './fastnavigation.component.html',
  styleUrls: ['./fastnavigation.component.less']
})
export class FastnavigationComponent implements OnInit {
  @ViewChild('fastNavigation', { read: ElementRef }) navigation: ElementRef;
  nodeId: number;
  _permissionService: PermissionsService;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionsService) {
    this._permissionService = permissionService;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.nodeId = params.id;
    });
  }

  public clickNobble() {
    const nav = this.navigation.nativeElement;
    nav.classList.toggle("open");
  }

  public routeTo(page: string) {
    this.router.navigate(["/" + page], { queryParams: { id: this.nodeId } });
  }

  /**
   * Navigation nur darstellen, wenn mindestens Zugriff auf 2 Seiten erlaubt
   * @returns
   */
  public showFastNavigation() {
    var count = 0;

    if (this.permissionService.hasPermission('page.uebersicht')) {
      count++;
    }

    if (this.permissionService.hasPermission('page.diagram')) {
      count++;
    }

    if (this.permissionService.hasPermission('page.monatsuebersicht')) {
      count++;
    }

    if (this.permissionService.hasPermission('page.jahresuebersicht')) {
      count++;
    }

    if (count > 1 && this.permissionService.hasPermission('function.viewForeignDetails')) {
      return true;
    }
    return false;
  }
}
