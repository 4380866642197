import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'nwa-svgzoom',
  templateUrl: './svgzoom.component.html',
  styleUrls: ['./svgzoom.component.less']
})
export class SvgzoomComponent implements OnInit  {
   @ViewChild('svgZoom') svgZoom;
   @ViewChild('overlayRight') overlayRight;
   @ViewChild('overlayLeft') overlayLeft;

  constructor() { }

  ngOnInit(): void {

  }

  onScroll(event) {
    let target = event.currentTarget;

    var max = target.scrollWidth - target.clientWidth;
    let tolerance = 20;

    // RIGHT
    if (target.scrollLeft >= max - tolerance) {
      this.overlayRight.nativeElement.style.display = 'none';
    } else {
      this.overlayRight.nativeElement.style.display = 'block';
    }

    // LEFT
    if (target.scrollLeft <= 0 + tolerance) {
      this.overlayLeft.nativeElement.style.display = 'none';
    } else {
      this.overlayLeft.nativeElement.style.display = 'block';
    }
  }
}
