import { NodeService } from './../../services/node.service';
import { StatsService } from './../../services/stats.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Node } from './../../models/Node';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less']
})
export class OverviewComponent implements OnInit {
  params;
  node: Node;
  isZentrale;
  statsInfo;
  loaded = false;
  interval;
  hasFailure: boolean = false;
  updateEvent = new EventEmitter<any>();

  constructor(
    private nodeService: NodeService,
    private activatedRoute: ActivatedRoute,
    private statsService: StatsService
  ) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
      this.nodeService.getNode(params.id).subscribe(x => {
        this.node = x;
        this.isZentrale = this.node.isZentrale;
        this.loaded = true;
        this.updateEvent.emit();
        this.checkFailure(this.node);
      });

      this.statsService.getStatsInformation(params.id).subscribe(x => this.statsInfo = x);

      this.initData();
    });
  }

  private checkFailure(node: Node) {
    this.nodeService.hasFailure(node).subscribe(failureResponse => {
      this.hasFailure = failureResponse ? true : false;
    });
  }

  private initData() {
    // this.updateOverview();
    var me = this;
    // Alle 5 min neuladen
    setTimeout(function () {
      me.updateEvent.emit();
      me.interval = setInterval(() => {
        me.updateEvent.emit();
        this.checkFailure(this.node);
      }, 5000 * 60);
    }, this.getNextUpdateTimeDifference())
  }

  private getNextUpdateTimeDifference() {
    let date = new Date();

    while (date.getMinutes() % 5 != 0) {
      date = new Date(date.getTime() + 60000);
    }

    date = new Date(date.getTime() + 120000);
    date = new Date(date.getTime() - date.getSeconds() * 1000);

    return date.getTime() - new Date().getTime();
  }
}
