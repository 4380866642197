import { PermissionsService } from '../../../services/permissions.service';
import { ToastrService } from 'ngx-toastr';
import { DownloadServiceService as DownloadService } from '../../../services/download-service.service';
import { NbDialogService } from '@nebular/theme';
import { StatsService } from 'src/app/services/stats.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'nwa-yearstats',
  templateUrl: './yearstats.component.html',
  styleUrls: ['./yearstats.component.less']
})
export class YearstatsComponent implements OnInit {
  nodeId: number = 0;
  year: number = -1;
  statsInfo: string;
  stats;
  seriesEvent = new EventEmitter<any>();
  @ViewChild('dialogOpenMonth') dialogTemplateOpenMonth;
  dialogRefOpenMonth;
  selectedDataset;
  hasTemperature: boolean = false;
  showPdfDownload: boolean = false;
  loading = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private statsService: StatsService,
    private dialogService: NbDialogService,
    private downloadService: DownloadService,
    private toastr: ToastrService,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
     // Parameter auslesen
     this.activatedRoute.queryParams.subscribe(params => {
      this.loading = true;
      this.nodeId = params.id;
      if (params.year) {
        this.year = params.year;
      }


      this.statsService.getStatsInformation(this.nodeId).subscribe(x =>{
        this.statsInfo = x;
      });

      this.statsService.getYearStats(this.nodeId, this.year).subscribe(x => {
        this.stats = x;
        this.nodeId = this.stats.yearStats.nodeId;
        this.year = this.stats.year;
        this.hasTemperature = this.stats.hasTemperature;
        this.seriesEvent.emit(this.stats);
        this.loading = false;
      });
    });

    this.showPdfDownload = this.permissionsService.hasPermission("function.pdfyearstatsdownload");
  }

  public routeTo(year: number) {
    this.router.navigate(["/jahresuebersicht"], { queryParams: {id: this.nodeId, year: year}});
  }

  public openSelectedMonthStats() {
    this.dialogRefOpenMonth.close();
    this.router.navigate(["/monatsuebersicht"], { queryParams: {id: this.nodeId, year: this.year, month: this.selectedDataset.unit}});
  }

  public onClickedDataSet(dataset) {
    this.selectedDataset = dataset;

    this.dialogRefOpenMonth = this.dialogService.open(this.dialogTemplateOpenMonth);
  }

  public downloadYearStats() {
    let toastRef = this.toastr.info('Jahresübersicht wird generiert...', null, {
      disableTimeOut: true,
    });

    this.downloadService.getYearStatsPdf(this.nodeId, this.year).subscribe((data) => {

      let downloadURL = window.URL.createObjectURL(data as Blob);
      window.open(downloadURL, "_blank");
      // direkter Download #270 deaktiviert
      // let link = document.createElement('a');
      // link.href = downloadURL;
      // link.download = `Jahresübersicht ${this.year} Anschluss ${this.nodeId}.pdf`;
      // link.click();

      this.toastr.remove(toastRef.toastId);
      // this.toastr.success("Download gestartet!");
    })
  }
}

