import { ChartAnnotation } from "./ChartAnnotation";
import { ProtColumn } from "./ProtColumn";
import { TimeRange } from "./TimeRange";

export class ProtocolChartConfig {
    nodeId: number;
    range: TimeRange = TimeRange.TwoDays;
    activeColumns: ProtColumn[];
    startDate: Date;
    endDate: Date;
    xAxisAnnotations: ChartAnnotation[];
    height: string;
}