import { ChartCardConfig } from '../../models/ChartCardConfig';
import { logging } from 'protractor';
import { formatNumber } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  ViewChild,
  Output,
} from '@angular/core';
import { NbTabsetComponent } from '@nebular/theme';
import { UnitPipe } from '@root/app/helpers/unit.pipe';

@Component({
  selector: 'nwa-chartcard',
  templateUrl: './chartcard.component.html',
  styleUrls: ['./chartcard.component.less'],
})
export class ChartcardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() series: EventEmitter<any>;
  @Input() average: number;
  chartConfig: ChartCardConfig = new ChartCardConfig();
  @Output() datasetClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('chart') chart;
  @ViewChild('dataTabset') dataTabset: NbTabsetComponent;
  @Input() reduceLabels: boolean = true;
 
  chartData;
  dataCount: number = 1;
  selectedSeries: string = 'Verbrauch';
  hasTemperature: boolean = true;
  hasEfficiency: boolean = true;

  constructor(private unitPipe : UnitPipe) {}

  ngOnInit(): void {
    this.series.subscribe((x) => this.updateSeries(x));

    // get selected series from local storage
    let selectedSeries = localStorage.getItem('selectedSeries');
    if (selectedSeries != null) {
      this.selectedSeries = selectedSeries;
    }
  }

  private updateSeries(data) {
    this.chartData = data.averageConsumption;
    var config = data.chartConfig;
    this.chartConfig = config;

    this.hasTemperature = data.hasTemperature;
    this.hasEfficiency = data.hasEfficiency;

    this.setAxisTitle();

    if (this.chartData) {
      var values = this.chartData.map((x) => [x.unit, x.powerConsumption]);
      var temperature = this.chartData.map((x) => [x.unit, x.temperature]);
      let efficiency = this.chartData.map((x) => [x.unit, x.efficiency]);
      this.dataCount = values.length;

      this.title = config.consumptionTitle;

      this.chart.updateSeries([
        {
          name: 'Verbrauch',
          data: values,
        },
        {
          name: 'Temperatur',
          data: temperature,
        },
        {
          name: 'Effizienz',
          data: efficiency,
        },
      ]);

      this.showData(this.selectedSeries);
      this.setTabsetToCurrentSelection();
    } else {
      // // Chart leeren
      this.chart.updateSeries([
        {
          name: 'Verbrauch',
          data: [],
        },
        {
          name: 'Temperatur',
          data: [],
        },
        {
          name: 'Effizienz',
          data: [],
        },
      ]);
    }
  }

  private getDivisors(n): number[] {
    var divisors: number[] = [];
    for (var i = 1; i <= n; i++) {
      if (n % i == 0 && i != n && i != 1) {
        divisors.push(i);
      }
    }

    return divisors;
  }

  private getStepSize(n: number): number {
    var divisors: number[];

    if (n <= 1 || this.dataCount <= 10) return 1;

    while (divisors == undefined || divisors.length == 0) {
      divisors = this.getDivisors(n);

      n--;
    }

    // SORT DESC
    divisors = divisors.sort((n1, n2) => n2 - n1);

    return divisors[0];
  }

  public switchData(event) {
    this.showData(event.tabTitle);
  }

  private showData(seriesName) {
    if (this.chart && this.chartData) {
      // Abfangen, wenn ein Datensatz nicht vorhanden sein sollte => Fallback Verbrauch setzen
      if (
        (!this.hasTemperature && seriesName == 'Temperatur') ||
        (!this.hasEfficiency && seriesName == 'Effizienz')
      ) {
        this.selectedSeries = 'Verbrauch';
        seriesName = 'Verbrauch';
      }

      switch (seriesName) {
        case 'Temperatur':
          this.title = this.chartConfig?.temperatureTitle;
          this.chart.showSeries('Temperatur');
          this.chart.hideSeries('Verbrauch');
          this.chart.hideSeries('Effizienz');
          break;
        case 'Verbrauch':
          this.title = this.chartConfig?.consumptionTitle;
          this.chart.hideSeries('Temperatur');
          this.chart.showSeries('Verbrauch');
          this.chart.hideSeries('Effizienz');
          break;
        case 'Effizienz':
          this.title = this.chartConfig?.efficiencyTitle;
          this.chart.hideSeries('Temperatur');
          this.chart.hideSeries('Verbrauch');
          this.chart.showSeries('Effizienz');
          break;
      }
      this.selectedSeries = seriesName;
      localStorage.setItem('selectedSeries', seriesName);
    }
  }

  /**
   * Wählt den Tab entsprechend der Wahl des aktuellen Datensatzes aus
   * => Nötig nach Seitenreload, wenn Wahl aus Local-Storage entnommen wird
   */
  private setTabsetToCurrentSelection() {
    let tabToActivate = this.dataTabset.tabs.find(
      (x) => x.tabTitle == this.selectedSeries
    );
    this.dataTabset.selectTab(tabToActivate);
  }

  public getUnit(index: number) {
    return this.chartData[index].label;
  }

  private setAxisTitle() {
    // this.chartOptions.xaxis.title.text = this.chartConfig.xAxisTitle;
    // this.chartOptions.yaxis[0].title.text = this.chartConfig.yAxisConsumptionTitle;
    // this.chartOptions.yaxis[1].title.text = this.chartConfig.yAxisTemperatureTitle;

    this.chartOptions.yaxis[0].labels.show = true;
    this.chartOptions.yaxis[1].labels.show = true;
    this.chartOptions.yaxis[2].labels.show = true;

    this.chart.updateOptions({
      xaxis: this.chartOptions.xaxis,
      yaxis: this.chartOptions.yaxis,
    });
  }

  chartOptions = {
    series: [],
    chart: {
      height: '320',
      width: '98%',
      type: 'bar',
      redrawOnParentResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      events: {
        click: (event, chartContext, config) => {
          if (config.dataPointIndex > -1) {
            this.datasetClicked.emit(this.chartData[config.dataPointIndex]);
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      show: false,
    },
    xaxis: {
      title: {
        text: '',
      },
      type: 'category',
      // tickAmount: 5,
      tickPlacement: 'on',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: (value, timestamp, opts) => {
          if (!this.reduceLabels) {
            return value;
          }

          var stepSize = this.getStepSize(this.dataCount);

          if (this.dataCount <= 10) {
            return value;
          }

          return value % stepSize == 0 || value == 1 ? value : '';
        },
      },
    },
    yaxis: [
      {
        title: {
          text: '',
        },
        seriesName: 'Tagesverbrauch',
        labels: {
          show: false,
          formatter: (value) => {
            return this.unitPipe.transform(value, 0, 'kWh');
          },
        },
      },
      {
        title: {
          text: '',
        },
        seriesName: 'Temperatur',
        labels: {
          show: false,
          formatter: (value) => {
            return this.unitPipe.transform(value, 0, '°C');
          },
        },
      },
      {
        title: {
          text: '',
        },
        seriesName: 'Effizienz',
        labels: {
          show: false,
          formatter: (value) => {
            return this.unitPipe.transform(value, 0, '%');
          },
        },
      },
    ],
    tooltip: {
      x: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          return this.getUnit(dataPointIndex);
        },
      },
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          if (seriesIndex == 0) {
            return this.unitPipe.transform(value, 0, 'kWh');
          } else if (seriesIndex == 1) {
            return this.unitPipe.transform(value, 1, '°C');
          } else if (seriesIndex == 2) {
            return this.unitPipe.transform(value, 1, '%');
          }
        },
      },
      fixed: {
        position: 'topLeft',
        offsetX: 100,
      },
    },
    legend: {
      show: false,
    },
  };
}
