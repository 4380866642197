import { UsergroupService } from './../../../services/usergroup.service';
import { UserGroup } from './../../../models/UserGroup';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserActivationRequestModel } from './../../../models/UserActivationRequestModel';
import { ToastrService } from 'ngx-toastr';
import { NodeService } from './../../../services/node.service';
import { UserModel } from './../../../models/UserModel';
import { UserService } from './../../../services/user.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models/User';
import { Node } from 'src/app/models/Node';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit {
  @ViewChild('dialogDeleteUser') dialogTemplate;
  users: UserModel[];
  userGroups: UserGroup[];
  userToDelete: UserModel;
  nodes: Node[];
  currentUserId;
  dialogRef;
  userToAdd: UserModel = new UserModel();
  firstLoad: boolean = true;
  loading: boolean;

  constructor(private userService: UserService,
    private nodeService: NodeService,
    private authenticationService: AuthenticationService,
    private dialogService: NbDialogService,
    private userGroupService: UsergroupService) { }

  ngOnInit(): void {
    this.reloadUsers();
    this.nodeService.getNodes().subscribe(nodes => this.nodes = nodes.filter(x => x.nodeId != 2220));
    this.userGroupService.getAllUserGroups().subscribe(userGroups => this.userGroups = userGroups);
    var user = this.authenticationService.getUserInformation();
    this.currentUserId = user.userId;
  }

  toggleEditMode(user: UserModel) {
    var index = this.users.findIndex(x => x.userId == user.userId);
    var index = this.users.findIndex(x => x.userId == user.userId);
    if (!user.editing) {
      this.users[index].editing = true;
    } else {
      this.users[index].editing = false;
    }
  }

  updateUser(user) {
    this.userService.updateUser(user).subscribe(
      data => {
        this.reloadUsers();
      }
    )
  }

  addUser() {
    this.userService.addUser(this.userToAdd).subscribe(
      data => {
        this.reloadUsers();
        this.userToAdd = new UserModel();
      })
  }

  deleteUser(user) {
    this.userToDelete = user;
    this.dialogRef = this.dialogService.open(this.dialogTemplate);
  }

  deleteUserConfirmed() {
    this.userService.deleteUser(this.userToDelete).subscribe(
      data => {
        this.reloadUsers();
        this.dialogRef.close();
      })
  }

  toggleUserActivation(event, checkbox) {
    var element = checkbox.hostElement.nativeElement;
    var changedUser = element.dataset.userid;

    var request: UserActivationRequestModel = {
      userId: changedUser,
      activated: checkbox.checked
    };

    this.userService.changeUserActivation(request).subscribe(
      data => {
        this.reloadUsers();
      }
    )
  }

  reloadUsers() {
    if (this.firstLoad) {
      this.firstLoad = false;
      this.loading = true;
    }
    this.userService.getUsers().subscribe(users => {
      this.loading = false;
      this.users = users
    });
  }

  getUserGroupName(groupId) {
    if (this.userGroups) {
      return this.userGroups.
      filter(x => x.groupId === groupId)[0]?.name;
    }
    return "";
  }
}
