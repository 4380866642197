<div class="solar">
    <div class="row mb-3">
        <div class="col">
            <h5>Live Solar-Status</h5>
            <!-- <span class="subtitle">Stand {{data.time | date: 'dd.MM.YY HH:mm:ss'}} - aktualisiert alle 10s</span> -->
        </div>
    </div>
    @if(data) {
    <div class="cards">

        <!-- Aktuelle PV-Leistung -->
        <div class="solar-info">
            <nwa-statscard title="PV-Leistung" [subtitle]="getUpdateTime(8000)" [value]="getData(8000) | unit: 2"
                unit="kWh" useMatIcon="true" icon="solar_power" backgroundColor="#B3D4FF"></nwa-statscard>
        </div>

        <!-- Batteriezustand -->
        <div class="solar-info">
            <nwa-statscard title="Batterie" [subtitle]="getUpdateTime(4)" [value]="getData(4) | unit: 0" unit="%"
                useMatIcon="true" circular="true" min="0" max="100" icon="battery_charging_full" backgroundColor="#B3D4FF"></nwa-statscard>
        </div>

        <!-- Netzeinspeisung -->
        <div class="solar-info">
            <nwa-statscard title="Netzeinspeisung" [subtitle]="getUpdateTime(3)" [value]="getData(3) | unit: 2"
                unit="kW" useMatIcon="true" icon="public" backgroundColor="#FFFCC0"></nwa-statscard>
        </div>

        <!-- Hausverbrauch -->
        <div class="solar-info">
            <nwa-statscard title="Hausverbrauch" [subtitle]="getUpdateTime(8000)" [value]="getData(8001) | unit: 2" unit="kW"
                icon="home-outline" backgroundColor="#FFFCC0"></nwa-statscard>
        </div>
    </div>
    }
</div>