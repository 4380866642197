<ng-template #dialogUserPicker let-data let-ref="dialogRefUserPicker">
   <nb-card>
        <nb-card-header>Benutzer wählen</nb-card-header>
        <nb-card-body>
            @for(user of users; track users.userId) {
                <div class="user" (click)="onUserPicked(user)">
                    {{user.userId}} {{user.firstname}} {{user.lastname}}
                </div>
            }
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="chooseAll()" class="float-end" status="primary">Alle wählen</button>
        </nb-card-footer>
   </nb-card>
</ng-template>