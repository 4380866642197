<nb-layout>
  <nb-layout-header fixed>
    <div class="d-flex">
      <a (click)="toggleSidebar()">
        <nb-icon icon="menu-2" class="menu-icon"></nb-icon>
      </a>
    </div>
    <div class="d-flex align-items-center">
      <nwa-logo></nwa-logo>
    </div>
    <div class="d-none d-sm-flex ms-auto d-flex align-items-center ghost" *ngIf="anonymousModeActive" nbTooltip="Personenbezogene Daten werden ausgeblendet und Funktionen eventuell eingeschränkt." nbTooltipPlacement="bottom">
      <i class="fas fa-ghost me-2 fa-2x"></i>
      <span>Anonymer Modus</span>
    </div>
    <div class="d-flex ms-auto align-items-center profile" [nbContextMenu]="conItems"
      nbContextMenuTag="profile-context-menu">
      <div class="profile-icon me-2">
        {{user.firstname[0]}}{{user.lastname[0]}}
      </div>
      {{user.firstname}} {{user.lastname}}
    </div>
  </nb-layout-header>
  <nb-sidebar tag="sidebar" [responsive]="true">
    <div class="d-flex flex-column h-100">
      <nb-menu [tag]="navigationTag" [items]="pageMenuItems" class="mb-auto"></nb-menu>
      <!-- Dashbot -->
       @if(showAiButton) {
        <div class="dashbot" (click)="openAiSidebar(true)">
          <img src="/assets/images/ai/small-aibot.svg"/>
          <span>Dashbot</span>
        </div>
       }
      <!-- ------- -->
      <nb-menu tag="bottom-menu" [items]="bottomMenuItems"></nb-menu>
    </div>
  </nb-sidebar>

  <nb-layout-column>
    @if(aiSideBarOpened) {
      <nwa-aibotslider></nwa-aibotslider>
    }
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
