import { AuthGuard } from './services/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { NbSidebarModule, NbLayoutModule, NbButtonModule, NbThemeService, NbStatusService, NbThemeModule, NbFlipCardComponent, NbCardModule, NbMenuModule, NbContextMenuModule, NbIconModule, NbTabsetModule, NbInputModule, NbSpinnerModule, NbCheckboxModule, NbSelectModule, NbButtonGroupModule, NbDialogModule, NbTooltipModule, NbAlertModule, NbPopoverModule, NbProgressBarModule, NbBadgeModule, NbDatepickerModule, NbFormFieldModule, NbToggleModule, NbAutocompleteModule, NbTagModule } from '@nebular/theme';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NetworkComponent } from './pages/network/network.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { LoginComponent } from './pages/login/login.component';
import { PageLayoutComponent } from './pages/_layout/page-layout/page-layout.component';
import { LoginLayoutComponent } from './pages/_layout/login-layout/login-layout.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { JwtModule } from '@auth0/angular-jwt'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { UsersComponent } from './pages/settings/users/users.component';
import { ArrayjoinPipe } from './helpers/arrayjoin.pipe';
import { NodesComponent } from './pages/settings/nodes/nodes.component';
import { TruncatePipe } from './helpers/truncate.pipe';
import { UnitPipe } from './helpers/unit.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OverviewComponent } from './pages/overview/overview.component';
import { ContextmenuDirective } from './directives/contextmenu.directive';
import {PortalModule} from '@angular/cdk/portal';
import { ContextmenuComponent } from './components/contextmenu/contextmenu.component';
import { PermissionsComponent } from './pages/settings/permissions/permissions.component';
import { ManageComponent } from './pages/settings/permissions/manage/manage.component';
import { ErrorComponent } from './pages/error/error.component';
import { NodeschemeComponent } from './pages/overview/nodescheme/nodescheme.component';
import { HeatstationschemeComponent } from './pages/overview/heatstationscheme/heatstationscheme.component';
import { TrendComponent } from './pages/trend/trend.component';
import { MonthlystatsComponent } from './pages/stats/monthlystats/monthlystats.component';
import { StatscardComponent } from './components/statscard/statscard.component';
import { ChartcardComponent } from './components/chartcard/chartcard.component';
import { BillComponent } from './pages/bill/bill.component';
import { PricesettingsComponent } from './pages/bill/pricesettings/pricesettings.component';
import { FastnavigationComponent } from './components/fastnavigation/fastnavigation.component';
import { PricelistComponent } from './pages/bill/pricesettings/pricelist/pricelist.component';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { de } from 'date-fns/locale';
import { PricefilterComponent } from './pages/bill/pricesettings/pricefilter/pricefilter.component';
import { BillgenerationComponent } from './pages/bill/billgeneration/billgeneration.component';
import { HeatAmountComponent } from './pages/bill/heatamount/heatamount.component';
import { InfoComponent } from './pages/info/info.component';
import { YearstatsComponent } from './pages/stats/yearstats/yearstats.component';
import { EarthComponent } from './components/earth/earth.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ManagemonitoringsComponent } from './pages/settings/managemonitorings/managemonitorings.component';
import { MonitoringformComponent } from './pages/settings/managemonitorings/monitoringform/monitoringform.component';
import { InvalidatorDirective } from './directives/invalidator.directive';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import { MonitoringdetailsComponent } from './pages/monitoring/monitoringdetails/monitoringdetails.component';
import { MonitoringtresholdsComponent } from './components/monitoringtresholds/monitoringtresholds.component';
import { ProtocolchartComponent } from './components/protocolchart/protocolchart.component';
import { SvgzoomComponent } from './components/svgzoom/svgzoom.component';
import { LogoComponent } from './components/logo/logo.component';
import { PwlessauthComponent } from './pages/pwlessauth/pwlessauth.component';
import { MonitoringhistoryComponent } from '@root/app/pages/monitoring/monitoringhistory/monitoringhistory.component';
import { HistorychartComponent } from '@root/app/components/historychart/historychart.component';
import { AuthkeyComponent } from '@root/app/pages/settings/authkey/authkey.component';
import { UserpickerComponent } from '@root/app/components/userpicker/userpicker.component';
import { QRCodeModule } from 'angularx-qrcode';
import { WorldearthhourComponent } from '@root/app/pages/other/worldearthhour/worldearthhour.component';
import { SolarComponent } from '@root/app/pages/solar/solar.component';
import { AiChatComponent } from '@root/app/components/aichat/aichat.component';
import { ChatMessageComponent } from '@root/app/components/aichat/message/chatmessage.component';
import { AibotsliderComponent } from '@root/app/components/aibotslider/aibotslider.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {DragDropModule} from '@angular/cdk/drag-drop';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({ declarations: [
        AppComponent,
        HistorychartComponent,
        MonitoringhistoryComponent,
        TrendComponent,
        NetworkComponent,
        SettingsComponent,
        LoginComponent,
        PageLayoutComponent,
        LoginLayoutComponent,
        UsersComponent,
        ArrayjoinPipe,
        NodesComponent,
        TruncatePipe,
        UnitPipe,
        OverviewComponent,
        ContextmenuDirective,
        ContextmenuComponent,
        PermissionsComponent,
        ManageComponent,
        ErrorComponent,
        NodeschemeComponent,
        HeatstationschemeComponent,
        MonthlystatsComponent,
        StatscardComponent,
        ChartcardComponent,
        BillComponent,
        PricesettingsComponent,
        FastnavigationComponent,
        PricelistComponent,
        PricefilterComponent,
        BillgenerationComponent,
        HeatAmountComponent,
        InfoComponent,
        YearstatsComponent,
        EarthComponent,
        ManagemonitoringsComponent,
        MonitoringformComponent,
        InvalidatorDirective,
        MonitoringComponent,
        MonitoringdetailsComponent,
        MonitoringtresholdsComponent,
        ProtocolchartComponent,
        SvgzoomComponent,
        LogoComponent,
        PwlessauthComponent,
        AuthkeyComponent,
        UserpickerComponent,
        WorldearthhourComponent,
        SolarComponent,
        AiChatComponent,
        ChatMessageComponent,
        AibotsliderComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        RouterModule,
        JwtModule.forRoot({ config: {
                tokenGetter: () => {
                    return localStorage.getItem('token');
                }
            } }),
        ToastrModule.forRoot(),
        NgxSkeletonLoaderModule,
        NbSidebarModule,
        NbLayoutModule,
        NbButtonModule,
        BrowserAnimationsModule,
        NbThemeModule.forRoot({ name: 'nwa' }),
        NbEvaIconsModule,
        NbCardModule,
        FontAwesomeModule,
        DragDropModule,
        NbSidebarModule.forRoot(),
        NgApexchartsModule,
        NbMenuModule.forRoot(),
        NbContextMenuModule,
        NbIconModule,
        NbTabsetModule,
        NbInputModule,
        NbSpinnerModule,
        NbCheckboxModule,
        NbSelectModule,
        FormsModule,
        NbButtonGroupModule,
        NbDialogModule.forRoot(),
        NbTooltipModule,
        NbAlertModule,
        NbPopoverModule,
        NbProgressBarModule,
        NbAutocompleteModule,
        OverlayModule,
        PortalModule,
        NbTabsetModule,
        NbTagModule,
        NbDatepickerModule.forRoot(),
        NbFormFieldModule,
        NbToggleModule,
        NbBadgeModule,
        QRCodeModule,
        NgScrollbarModule, 
        NbDateFnsDateModule.forRoot({
            parseOptions: { locale: de },
            formatOptions: { locale: de },
        })], providers: [
        NbThemeService,
        NbStatusService,
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        UnitPipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
