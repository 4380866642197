import { VatService } from './../../../services/vat.service';
import { HeatpriceService } from './../../../services/heatprice.service';
import { BasepriceService } from './../../../services/baseprice.service';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { NbTabComponent } from '@nebular/theme';

@Component({
  selector: 'nwa-pricesettings',
  templateUrl: './pricesettings.component.html',
  styleUrls: ['./pricesettings.component.less']
})
export class PricesettingsComponent implements OnInit {
  _basePriceService: BasepriceService;
  _heatPriceService: HeatpriceService;
  _vatService: VatService;
  loadPriceEvent : EventEmitter<string> = new EventEmitter();

  constructor(
    private basePriceService: BasepriceService,
    private heatPriceService: HeatpriceService,
    private vatPriceService: VatService
  ) { }

  ngOnInit(): void {
    this._basePriceService = this.basePriceService;
    this._heatPriceService = this.heatPriceService;
    this._vatService = this.vatPriceService;
  }

  public onChangeTab(tab: NbTabComponent) {
    this.loadPriceEvent.emit(tab.tabTitle);
  }
}
