import { DownloadServiceService } from '../../../services/download-service.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/services/permissions.service';
import { StatsCardViewModel } from '../../../models/StatsCardViewModel';
import { StatsService } from 'src/app/services/stats.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nwa-monthlystats',
  templateUrl: './monthlystats.component.html',
  styleUrls: ['./monthlystats.component.less']
})
export class MonthlystatsComponent implements OnInit {
  statsInfo: string;
  stats;
  seriesEvent = new EventEmitter<any>();
  nodeId: number = 0;
  hasTemperature: boolean = false;
  dayConsumptions;
  showPdfDownload: boolean = false;
  monthString;
  month: number;
  year;
  loading = true;

  modelTwo: StatsCardViewModel = {
    title: "Volumenstand",
    subtitle: "30.11.2021",
    value: 276.9,
    icon: "bar-chart-2-outline",
    unit: "m³",
    comparison: true
  };

  constructor(
    private statsService: StatsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private permissionsService: PermissionsService,
    private toastr: ToastrService,
    private downloadService: DownloadServiceService
  ) { }

  ngOnInit(): void {
        // Parameter auslesen
        this.activatedRoute.queryParams.subscribe(params => {
          this.loading = true;
          var month = -1;
          var year = -1;

          this.nodeId = params.id;
          if (params.month) {
            this.month = month = params.month;
          }

          if (params.year) {
            year = params.year;
          }

          this.statsService.getStatsInformation(this.nodeId).subscribe(x =>{
            this.statsInfo = x;
          });

          this.statsService.getMonthStats(this.nodeId, -1, month, year).subscribe(x => {
            this.stats = x;
            this.hasTemperature = this.stats.hasTemperature;

            this.monthString = this.stats.month;
            this.year = this.stats.year;
            this.nodeId = this.stats.nodeId;

            this.seriesEvent.emit(this.stats);
            this.loading = false;
          });

          this.statsService.getDayConsumptions(this.nodeId, -1, month, year).subscribe(x => {
            this.dayConsumptions = x;
          })
        });

        this.showPdfDownload = this.permissionsService.hasPermission("function.pdfyearstatsdownload");
  }

  public routeTo(month: number, year: number) {
    this.router.navigate(["/monatsuebersicht"], { queryParams: {id: this.nodeId, month: month, year: year}});
  }

  public downloadMonthStats() {
    let toastRef = this.toastr.info('Monatsübersicht wird generiert...', null, {
      disableTimeOut: true,
    });


    this.downloadService.getMonthStatsPdf(this.nodeId, -1, this.month, this.year).subscribe((data) => {

      let downloadURL = window.URL.createObjectURL(data as Blob);
      window.open(downloadURL, "_blank");
      // direkter Download #270 deaktiviert
      // let link = document.createElement('a');
      // link.href = downloadURL;
      // link.download = `Monatsübersicht ${this.monthString} ${this.year} Anschluss ${this.nodeId}.pdf`;
      // link.click();

      this.toastr.remove(toastRef.toastId);
      // this.toastr.success("Download gestartet!");
    })
  }
}
