import { StatsCardViewModel } from '../../models/StatsCardViewModel';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as circularChart from '../../definitions/chartoptions/statscard-circular-chart';

@Component({
  selector: 'nwa-statscard',
  templateUrl: './statscard.component.html',
  styleUrls: ['./statscard.component.less'],
})
export class StatscardComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() value: number;
  @Input() unit: string;
  @Input() icon: string;
  @Input() comparison: boolean;
  @Input() backgroundColor: string;
  @Input() useMatIcon: boolean = false;
  /**
   * Defines if circular graph is used
   */
  @Input() circular: boolean = false;
  /**
   * Min number for circular chart
   */
  @Input() min: number;
  /**
   * max number for circular chart
   */
  @Input() max: number;

  chartOptions = circularChart.chartOptions;
  dataSeries = [];

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.dataSeries = [this.value];
    this.updateChartColor();
  }

  updateChartColor() {
    if (!this.circular) return;

    if (this.value <= 15) {
      this.chartOptions.fill.colors = ['#ff1100'];
    } else if (this.value > 15 && this.value <= 50) {
      this.chartOptions.fill.colors = ['#ffae00'];
    } else {
      this.chartOptions.fill.colors = ['#33ff00'];
    }
  }

  ngOnInit(): void {}
}
