<div #menu class="context-menu" (click)="emit()">
    <div class="item" *ngFor="let item of items; let i = index" (click)="doClickAction(item)">
        <div class="menupoint">
            <div *ngIf="item.icon && !item.icon.pack">
                <div class="icon" style="margin-top: 3px;">
                    <nb-icon [icon]="item.icon.icon"></nb-icon>
                </div>
            </div>
            <div *ngIf="item.icon && item.icon.pack == 'fa'">
                <div class="icon" style="text-align: center;">
                    <i [className]="'fa ' + item.icon.icon"></i>
                </div>
            </div>
            <div *ngIf="!item.icon" class="float-start">
                <div style="width: 30px;"></div>
            </div>
            <div class="title">
                {{item.title}}
            </div>
        </div>
        <ng-container *ngIf="i != items.length - 1">
            <hr>
        </ng-container>
    </div>
</div>
