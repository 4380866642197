<div class="chat">
  <!-- <img src="/assets/images/ai/ai-arriving.webp"/> -->


  <ng-scrollbar #aiscroll id="ai-chat">
    <div class="messages">
      <!-- <nwa-chatmessage messageType="1" message="hi"></nwa-chatmessage>
      <nwa-chatmessage messageType="5" ></nwa-chatmessage>
      <nwa-chatmessage messageType="2" message="hi"></nwa-chatmessage> -->
    
    
      @for(message of chatMessages; track message.id) {
        <nwa-chatmessage [messageType]="message.role" [message]="message.content" [toolCalls]="message.toolCalls"></nwa-chatmessage>
      }
    
      @if(loading) {
        <nwa-chatmessage messageType="4"></nwa-chatmessage>
      }
    </div>
  </ng-scrollbar>


  <div class="chat-input">
    <nb-form-field class="input">
        <input #messageInput type="text" shape="round" nbInput placeholder="Stelle eine Frage" fullWidth [(ngModel)]="addedMessage" (keyup.enter)="addMessage()">
        <button nbSuffix nbButton ghost shape="round" size="medium" (click)="addMessage()" [disabled]="!messageInput.value.length" >
          <nb-icon [class.active-icon]="messageInput.value.length" icon="paper-plane-outline"></nb-icon>
        </button>
      </nb-form-field>
  </div>
</div>
