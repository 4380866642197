import { NodeService } from './../../../../services/node.service';
import { FilterService } from 'src/app/services/pricefilter.service';
import { NbDialogService } from '@nebular/theme';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PriceFilter } from 'src/app/models/PriceFilter';
import { Node } from 'src/app/models/Node';


@Component({
  selector: 'nwa-pricefilter',
  templateUrl: './pricefilter.component.html',
  styleUrls: ['./pricefilter.component.less']
})
export class PricefilterComponent implements OnInit {
  @Input() priceFilter: PriceFilter;
  @Output() priceFilterChange = new EventEmitter<PriceFilter>();
  @ViewChild('dialogFilter') dialogTemplateFilter;
  dialogRefFilter;
  years;
  nodes: Node[];

  constructor(
    private dialogService: NbDialogService,
    private filterService: FilterService,
    private nodeService: NodeService
  ) { }

  ngOnInit(): void {
  }

  public openFilterDialog() {
    this.filterService.getYearSelection().subscribe(x => {
      this.years = x;
    });
    this.nodeService.getDropDownNodes().subscribe(x => this.nodes = x);

    this.dialogRefFilter = this.dialogService.open(this.dialogTemplateFilter);
  }

  public savePriceFilter() {
    this.filterService.savePriceFilter(this.priceFilter);
    if (this.dialogRefFilter) {
      this.dialogRefFilter.close();
    }
    this.priceFilterChange.emit(this.priceFilter);
  }

  public removeFilter(type: string) {
    switch (type) {
      case "node":
        this.priceFilter.nodeId = null;
        break;
      case "year":
        this.priceFilter.year = null;
        break;
    }
    this.savePriceFilter();
  }
}
