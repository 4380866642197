<div>
    <h5 style="display: inline;">Berechtigungen</h5>
    <!-- <img src="assets/images/betabadge.svg" height="25px" class="ms-2" style="vertical-align: middle;; display: inline;"><br> -->
</div>
<span class="subtitle mt-2" style="display: block;">Anlage Benutzergruppen und Zuweisung der Berechtigungen</span>
<div class="row mt-4">
    <div class="col-md-4">

        <nb-card>
            <nb-card-body>
                <div class="row" style="height: 50px;">
                    <div class="col">
                        <h6 class="align-middle">Benutzergruppen</h6>
                    </div>
                    <div class="col">
                        <button nbButton class="float-end action-button" shape="round" (click)="openUserGroupCreationDialog()"><i
                  class="fas fa-plus"></i></button>
                    </div>
                </div>
                <table class="mt-2 usergroup-table">
                    <tbody>
                        <!--   -->
                        <tr *ngFor="let group of userGroups" appContextmenu [items]="getUserGroupContextItems(group)" (clicked)="userGroupContextMenuItemClicked($event)">
                            <td (click)="selectUserGroup(group)" [class.selected]="group.selected">{{group.name}}</td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>

    </div>
    <div class="col-md-8">
        <nb-card>
            <nb-card-body>
                <div class="row" style="height: 50px;">
                    <div class="col">
                        <h6 class="align-middle">Berechtigungen für <strong>{{selectedUserGroup.name}}</strong></h6>
                    </div>
                    <div class="col">
                        <button nbButton (click)="openManagePermissionsDialog()" class="float-end action-button" shape="round"><i class="fas fa-cog"></i></button>
                    </div>
                </div>
                <table class="mt-2 table" [nbSpinner]="permissionsLoading">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Typ</th>
                            <th>Aktiv</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let permission of groupPermissions">
                            <td>{{permission.name}}</td>
                            <td>{{permission.type}}</td>
                            <td>
                                <nb-checkbox (checkedChange)="updateUserGroupRights(permission, $event)" [(checked)]="permission.active"></nb-checkbox>
                            </td>
                            <td>
                                <button nbButton shape="round" class="action-button" (click)="showPermissionInfo(permission)"><i class="fas fa-info fa-xs"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<ng-template #dialog let-data let-ref="dialogRefPermissionInfo">
    <nb-card class="dialog">
        <nb-card-header><i class="fas fa-info-circle fa-lg me-2" style="color: #3366ff;"></i>Berechtigungsgruppe <span class="permission-title">{{permissionInfo.name}}</span></nb-card-header>
        <nb-card-body>
            <table>
                <tr>
                    <td><span class="permission-title">Typ</span></td>
                    <td>{{permissionInfo.type}}</td>
                </tr>
                <tr>
                    <td><span class="permission-title">Beschreibung</span></td>
                    <td>{{permissionInfo.description}}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <span class="permission-title">Berechtigungen</span>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <th>Schlüssel</th>
                    <th>Name</th>
                    <th>Beschreibung</th>
                </tr>
                <tr *ngFor="let permission of permissionInfo.permissions">
                    <td>{{permission.key}}</td>
                    <td>{{permission.name}}</td>
                    <td>{{permission.description}}</td>
                </tr>
            </table>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="closePermissionInfoDialog()" class="float-start" class="float-end">Schließen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogCreateUserGroup let-data let-ref="dialogRefCreateUserGroup">
    <nb-card style="min-width: 300px;">
        <nb-card-header>Benutzergruppe erstellen</nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col">
                    <span>Gib einen Namen für die neue Benutzergruppe an:</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <input nbInput #userGroupNameInput placeholder="Name" [fullWidth]="true" />
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="closeCreateUserGroupDialog()" class="float-start">Abbrechen</button>
            <button nbButton status="primary" (click)="createUserGroup(userGroupNameInput.value)" class="float-end">Speichern</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogDeleteUserGroup let-data let-ref="dialogRefDeleteUserGroup">
    <nb-card>
        <nb-card-header>Benutzergruppe "{{userGroupToDelete.name}}" löschen</nb-card-header>
        <nb-card-body>
            <p>Soll die Benutzergruppe wirklich gelöscht werden?</p>
            <p>
                <span>Alle Nutzer, die dieser Gruppe zugeordnet sind,<br> werden zur Standardgruppe zugewiesen.</span>
            </p>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="dialogRefDeleteUserGroup.close()" class="float-start">Abbrechen</button>
            <button nbButton status="danger" (click)="deleteUserGroupConfirmed()" class="float-end">Löschen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogEditUserGroup let-data let-ref="dialogRefEditUserGroup">
    <nb-card>
        <nb-card-header>Benutzergruppe "{{userGroupToEdit.name}}" bearbeiten</nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col">
                    <span>Gib einen neuen Namen für die neue Benutzergruppe an:</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <input nbInput #userGroupNameInput placeholder="Name" [fullWidth]="true" [(ngModel)]="userGroupToEdit.name" />
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="dialogRefEditUserGroup.close()" class="float-start">Abbrechen</button>
            <button nbButton status="primary" (click)="editUserGroupConfirmed()" class="float-end">Speichern</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogManagePermissions let-data let-ref="dialogRefManagePermissions">
    <nwa-manage (close)="dialogRefManagePermissions.close()"></nwa-manage>
</ng-template>