import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'nwa-nodescheme',
  templateUrl: './nodescheme.component.html',
  styleUrls: ['./nodescheme.component.less']
})
export class NodeschemeComponent implements OnInit {
  @Input() params;
  @Input() node;
  @Input() onUpdate : EventEmitter<any>;
  statsInfo;
  loading = true;
  stats;
  interval;

  constructor(
    private statsService: StatsService
  ) { }

  ngOnInit(): void {
    this.onUpdate.subscribe(x => this.updateOverview());

    this.updateOverview();
  }

  private updateOverview() {
    this.statsService.getLastStats(this.params.id).subscribe(x => {
      this.stats = x;
      this.loading = false;
    })
  }

  public getPumpStateColor(pumpState) {
    if (pumpState > 0) {
      return 'rgb(77,207,2)'
    } else {
      return 'rgb(200,203,193)';
    }
  }

  public getVentilStateColor(ventilState) {
    if (ventilState == 1) {
      return 'rgb(77,207,2)'
    } else {
      return 'rgb(237,241,247)';
    }
  }

  public getMischerState(mischerState) {
    if (mischerState == 0) {
      return '';
    } else if (mischerState == 1) {
      return 'AUF';
    } else if (mischerState == 2) {
      return 'ZU';
    }
  }

  public hasUebergabeStation() {
    return this.node?.configuration.startsWith('105');
  }

  public hasUebergabePuffer() {
    return !this.node?.configuration.startsWith('105');
  }

  public showMiddleBufferTemperature() {
    return this.node?.hasMiddleBufferTemperature;
  }
}
