import { Component } from '@angular/core';
import { SidebarService } from '@root/app/services/sidebar.service';

@Component({
  selector: 'nwa-aibotslider',
  templateUrl: './aibotslider.component.html',
  styleUrl: './aibotslider.component.less'
})
export class AibotsliderComponent {
  constructor(
    private sidebarService: SidebarService
  ) {}

  public close() {
    this.sidebarService.setAiSiteBarOpened(false);
  }
}
