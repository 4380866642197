import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Node } from '../models/Node';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(private http: HttpClient) { }

  public getNode(nodeId: number = 0) {
    if (nodeId == 0) {
      return this.http.get<Node>('/api/node/singlenode');
    } else {
      return this.http.get<Node>('/api/node/singlenode?nodeId=' + nodeId);
    }
  }

  public getDropDownNodes() {
    return this.http.get<Node[]>('/api/node/dropdown');
  }

  public getNodes() {
    return this.http.get<Node[]>('/api/node');
  }

  public updateNode(node: Node) {
    return this.http.put('/api/node', node);
  }

  public deleteNode(node: Node) {
    return this.http.delete('/api/node/' + node.nodeId);
  }

  public addNode(node: Node) {
    return this.http.post('/api/node', node);
  }

  public hasFailure(node: Node) {
    return this.http.get('api/node/failure?nodeId=' + node.nodeId);
  }
}
