import { Injectable, EventEmitter, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContextmenuService {
  contextOpened : EventEmitter<ElementRef> = new EventEmitter();

  constructor() { }

  public emitCloseEvent(target) {
    this.contextOpened.emit(target);
  }

  public getCloseEventEmitter() {
    return this.contextOpened;
  }
}
