<div class="message">
    <!-- Loading -->
    @if(messageType == 4) {
        <div class="circle">
            <img src="/assets/images/ai/small-aibot.svg" />
        </div>
        <div class="message-text bot-message">
            <i>{{message}}</i>
        </div>
    }
    @else if(messageType == 2) {
        <!-- AI Bot -->
        <div class="circle">
            <img src="/assets/images/ai/small-aibot.svg" />
        </div>
        <div class="message-text" [class.user-message]="messageType!=2" [class.bot-message]="messageType==1" [innerHtml]="message">
        </div>
    } 
    @else if (messageType == 5) {
        <!-- Tool Call -->
        <nb-card class="tool-call-message">
            <nb-card-body>
                <!-- <img src="/assets/images/ai/small-aibot.svg" width="50px"/> -->
                <div class="title d-flex flex-row align-items-center">
                    <nb-icon icon="search-outline"></nb-icon>
                    <span>Datenabfrage</span>
                </div>
                @for(toolCall of toolCalls; track toolCall.Id) {
                    <div class="tool-call-row">
                        <nb-icon icon="bar-chart-2-outline"></nb-icon>
                        <div class="tool-call" [innerHtml]="toolCall.Text"></div>
                    </div>
                }
            </nb-card-body>
          </nb-card>
    }
    @else {
        <!-- User -->
        <div class="message-text" [class.user-message]="messageType!=2" [class.bot-message]="messageType==1" [innerHtml]="message">
        </div>
    }
</div>