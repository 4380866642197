import { Injectable } from '@angular/core';
import { OverviewRecord } from './../models/OverviewRecord';
import { HttpClient } from '@angular/common/http';
import { Overview } from '../models/Overview';

@Injectable({
  providedIn: 'root'
})
export class NetService {

  constructor(private http: HttpClient) { }

  public getOverview() {
    return this.http.get<Overview>('/api/net/overview');
  }
}
