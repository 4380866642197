import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChatMessage } from '@root/app/models/ChatMessage';
import { Role } from '@root/app/models/Role';
import { ToolCall } from '@root/app/models/ToolCall';
import { ChatbotService } from '@root/app/services/chatbot.service';
import { th } from 'date-fns/locale';
import * as uuid from 'uuid';

@Component({
  selector: 'nwa-aichat',
  templateUrl: './aichat.component.html',
  styleUrl: './aichat.component.less',
})
export class AiChatComponent implements OnInit, OnDestroy {
  @ViewChild('messageInput') messageInput;
  @ViewChild('aiscroll') aiScroll;
  chatMessages: ChatMessage[] = [];
  chatThreadId: string;
  addedMessage: string = "";
  loading: boolean = true;
  toolCallIndex: number = 0;

  constructor(private dashbotService: ChatbotService) {}

  ngOnDestroy(): void {
    this.dashbotService.close();
  }

  ngOnInit(): void {
    this.dashbotService.connect(this, this.onMessageReceive, this.onThreadIdReceive, this.onToolCallReceive);

    setTimeout(() => {
      this.dashbotService.createThread().subscribe();
    }, 50);
  }

  public scrollToBottom() {
    const chatBox = document.getElementById('ai-chat');
    chatBox.scrollTop = chatBox.scrollHeight;
  }

  private initMessage() {
    let newMessage = new ChatMessage();
    newMessage.id = uuid.v4();
    newMessage.index = this.chatMessages.length ?? 1;
    newMessage.role = Role.Assistant;
    newMessage.content = "Hallo! Ich bin der Dashbot, dein Assistent für das Nahwärmenetz Amerbach. Ich helfe dir gerne bei Fragen zu Live-Daten oder Statistiken. Was möchtest du wissen?";
    newMessage.threadId = this.chatThreadId;

    this.chatMessages.push(newMessage);
  }

  public addMessage() {
    if (!this.addedMessage.length)
      return;

    this.loading = true;

    let newMessage = new ChatMessage();
    newMessage.id = uuid.v4();
    newMessage.index = this.chatMessages.length ?? 1;
    newMessage.role = Role.User;
    newMessage.content = this.addedMessage;
    newMessage.threadId = this.chatThreadId;

    this.chatMessages.push(newMessage);
    this.dashbotService.sendMessage(newMessage).subscribe(() => {
      this.messageInput.nativeElement.value = '';
    });    
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  private onMessageReceive(context, message: any) {
    if (message.object != 'thread.message.delta')
      return;

    context.loading = false;

    let existingMessage =
    context.chatMessages.length > 0
        ? context.chatMessages.filter((x) => x.id == message.id)[0]
        : null;

    if (existingMessage) {
      existingMessage.content += message.delta.content[0].text.value;
    } else {
      let newMessage = new ChatMessage();
      newMessage.id = message.id;
      newMessage.index = context.chatMessages.length;
      newMessage.role = message.role;
      newMessage.content = message.delta.content[0].text.value;
      newMessage.threadId = context.chatThreadId;

      context.chatMessages.push(newMessage);
    }
    context.scrollToBottom();
  }

  private onThreadIdReceive(context, threadId: string) {
    context.chatThreadId = threadId;
  }

  private onToolCallReceive(context, toolCalls: ToolCall[]) {
    let newMessage = new ChatMessage();
    newMessage.id = uuid.v4();
    newMessage.index = context.toolCallIndex++;
    newMessage.role = Role.ToolCall;
    newMessage.toolCalls = toolCalls;

    context.chatMessages.push(newMessage);
    setTimeout(() => {
      context.scrollToBottom();
    }, 100);
  }
}
