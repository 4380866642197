<div class="yearStats?" style="position: relative;">
    <nwa-fastnavigation></nwa-fastnavigation>
    <div class="row">
        <div class="col-xl-6">
            <h5>Jahresübersicht {{stats?.year}}</h5>
            <span class="subtitle">{{statsInfo ?? "-"}}</span>
        </div>
        <div class="col-xl-6 d-flex align-items-center mt-3 mt-xl-0" *ngIf="stats" style="overflow: auto;">
            <nb-button-group>
                <button *ngFor="let year of stats?.yearButtons" nbButtonToggle [pressed]="year == stats.year" (click)="routeTo(year)">{{year}}</button>
            </nb-button-group>
        </div>
    </div>

    <!-- <div [hidden]="!stats?.yearStats"> -->
    <div class="cards" [nbSpinner]="loading">
        <div class="row mt-3">
            <div class="col-xl-3">
                <nwa-statscard title="Zählerstand" subtitle="Ende des Jahres" [value]="stats?.yearStats?.zaehlerstand | unit: 0" unit="kWh" icon="droplet-outline" backgroundColor="#B3D4FF"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Volumenstand" subtitle="Ende des Jahres" [value]="stats?.yearStats?.volumenstand | unit: 0" unit="m³" icon="arrowhead-up-outline" backgroundColor="#B3D4FF"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Wärmeverbrauch" [subtitle]="stats?.year" [value]="stats?.yearStats?.waermeverbrauch | unit: 0" unit="kWh" icon="thermometer-outline" backgroundColor="#FFFCC0"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Volumen" [subtitle]="stats?.year" [value]="stats?.yearStats?.volumen | unit: 1" unit="m³" icon="cube-outline" backgroundColor="#FFFCC0"></nwa-statscard>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-3">
                <nwa-statscard *ngIf="hasTemperature" title="Ø Außentemperatur" [subtitle]="stats?.year" [value]="stats?.yearStats?.durchschnittstemperatur | unit : 1" unit="°C" icon="thermometer-outline" backgroundColor="#95E2FF"></nwa-statscard>
                <nwa-statscard title="Höchster Tagesverbrauch" [subtitle]="stats?.yearStats?.hoechsterTagesverbrauchDatum | date: 'dd.MM.yyyy'" [value]="stats?.yearStats?.hoechsterTagesverbrauch | unit : 2" unit="kWh" icon="diagonal-arrow-right-up-outline" backgroundColor="#A4E5D1"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Ø Tagesverbrauch" [subtitle]="stats?.year" [value]="stats?.yearStats?.durchschnittsTagesverbrauch | unit : 2" unit="kWh" icon="flash-outline" backgroundColor="#95E2FF"></nwa-statscard>
                <!-- DownloadCard -->
                <nb-card style="overflow: hidden;" class="download-card" *ngIf="showPdfDownload">
                    <nb-card-body class="d-flex flex-column">
                        <div class="mb-auto title-header">
                            <span class="title">PDF-Download</span>
                        </div>
                        <span class="subtitle mb-1">Deine Jahresübersicht ist zum <br> Download verfügbar.</span>
                        <button nbButton status="info" (click)="downloadYearStats()">Download</button>
                        <div class="icon">
                            <nb-icon icon="cloud-download-outline">
                            </nb-icon>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
            <div class="col-xl-6">
                <nwa-chartcard [average]="stats?.averageConsumption" [series]="seriesEvent" (datasetClicked)="onClickedDataSet($event)" [reduceLabels]="false"></nwa-chartcard>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <nb-card [nbSpinner]="loading">
                <nb-card-body>
                    <span class="title">Monatliche Auflistung</span>
                    <span class="table-subtitle mb-2">Datenstand jeweils Ende des Monats</span>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Monat</th>
                                <th>Zählerstand</th>
                                <th>Volumenstand</th>
                                <th>Verbrauch</th>
                                <th>Volumenbedarf</th>
                                <th>Volumen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let monthStats of stats?.monthlyStats">
                                <td>{{monthStats.month}}</td>
                                <td class="d-flex align-items-center">{{monthStats.meterReading | unit : 0 : ' kWh'}}
                                    <!-- <ng-container *ngIf="monthStats.meterReadingChangement">
                                        <nb-icon icon="arrowhead-up-outline" class="positive-changement ms-2"></nb-icon><span class="change-text">+ {{monthStats.meterReadingChangement | unit : 0 : ' kWh'}}</span>
                                    </ng-container> -->
                                </td>
                                <td>{{monthStats.meterVolume | unit : 0 : ' m³'}}</td>
                                <td>{{monthStats.powerConsumption | unit : 0 : ' kWh'}}</td>
                                <td>{{monthStats.volumeRequirement | unit : 0 : ' m³/MWh'}}</td>
                                <td>{{monthStats.volume| unit : 1 : ' m³'}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="icon">
                        <nb-icon icon="bar-chart-2-outline"></nb-icon>
                    </div> -->
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>
<!-- </div> -->

<ng-template #dialogOpenMonth let-data let-ref="dialogOpenMonth">
    <nb-card style="min-width: 400px;">
        <nb-card-header>Monatsstatistiken öffnen</nb-card-header>
        <nb-card-body>
            <span>Möchtest Du die Statistiken für <br><strong>{{selectedDataset?.label}} {{year}}</strong> öffnen?</span>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="dialogRefOpenMonth.close()" class="float-start">Abbrechen</button>
            <button nbButton status="success" (click)="openSelectedMonthStats()" class="float-end">Öffnen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>