<div style="position: relative;">
    <nwa-fastnavigation></nwa-fastnavigation>
    <div class="row">
        <div class="col">
            <h5>Übersicht</h5>
            <span class="subtitle"> - {{statsInfo}}</span>
        </div>
        <div class="col text-end" *ngIf="hasFailure">
            <nb-icon icon="alert-triangle-outline" style="color: red;"></nb-icon> <b style="color: red" class="ms-1">Störungsmeldung vorhanden</b>
        </div>
    </div>
    <ng-container *ngIf="loaded">
        <ng-container *ngIf="isZentrale">
            <nwa-heatstationscheme [node]="node" [params]="params" [onUpdate]="updateEvent"></nwa-heatstationscheme>
        </ng-container>
        <ng-container *ngIf="!isZentrale">
            <nwa-nodescheme [node]="node" [params]="params" [onUpdate]="updateEvent"></nwa-nodescheme>
        </ng-container>
    </ng-container>
</div>
