import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CBOR from '../utils/cbor.js';
import { Attestation } from '../models/Webauthn/Attestation.js';
import { ClientData } from '../models/Webauthn/ClientData.js';
@Injectable({
  providedIn: 'root',
})
export class WebauthnService {
  constructor(private http: HttpClient) {}

  public removeWebauthn() {
    return this.http.delete('api/authentication/webauthn');
  }

  public usesWebauthn() {
    return this.http.get('api/authentication/webauthn/status');
  }

  public async getAssertionData(credential) {
    const test = await this.hashSHA256("hallo");
    const hash = await this.hashSHA256(credential.response.clientDataJSON);
    const signedData = this.encodeBase64(credential.response.authenticatorData + hash);

    return {
      // attestation: this.decodeAttestationObj(credential),
      clientData: this.decodeClientData(credential),
      signature: this.encodeBase64(new Uint8Array(credential.response.signature)),
      userHandle: this.convertToString(new Uint8Array(credential.response.userHandle)),
      sessionId: this.getSession(),
      authenticatorDataBytes: this.encodeBase64(new Uint8Array(credential.response.authenticatorData)),
      clientDataJsonBytes: this.encodeBase64(new Uint8Array(credential.response.clientDataJSON))
    }
  }

  private async hashSHA256(data) {
    const buffer = new Uint8Array(data).buffer;
    let hash = await crypto.subtle.digest('SHA-256', buffer);

// SHA-256-Hash berechnen
    return new Uint8Array(hash);
  }

  public decodeAttestationObj(credential): Attestation {
    const decodedAttestationObj = CBOR.decode(
      credential.response.attestationObject
    );

    const { authData } = decodedAttestationObj;

    // get the length of the credential ID
    const dataView = new DataView(new ArrayBuffer(2));
    const idLenBytes = authData.slice(53, 55);
    idLenBytes.forEach((value, index) => dataView.setUint8(index, value));
    const credentialIdLength = dataView.getUint16(0);

    // get the credential ID
    const credentialId = authData.slice(55, 55 + credentialIdLength);

    // get the public key object
    const publicKeyBytes = authData.slice(55 + credentialIdLength);

    const publicKeyObject = CBOR.decode(
      publicKeyBytes.buffer);

    return {
      credentialId: this.encodeBase64(credentialId),
      publicKey: this.encodeBase64(publicKeyBytes),
    };
  }

  public decodeClientData(credential): ClientData {
    const utf8Decoder = new TextDecoder('utf-8');
    // AuthData decodieren
    const decodedClientData = utf8Decoder.decode(
      credential.response.clientDataJSON
    );

    // parse the string as an object
    const clientDataObj = JSON.parse(decodedClientData);

    return {
      challenge: this.base64urlDecode(clientDataObj.challenge),
      origin: clientDataObj.origin,
      type: clientDataObj.type,
    };
  }

  public async getRegistrationRequestModel() {
    let creationOptions = (await this.http
      .get('api/authentication/webauthn/register')
      .toPromise()) as any;
    creationOptions.creationOptions.challenge = new TextEncoder().encode(
      creationOptions.creationOptions.challenge
    );

    creationOptions.creationOptions.user.id = new TextEncoder().encode(
      creationOptions.creationOptions.user.id
    );
    this.setSession(creationOptions.sessionId);

    return creationOptions;
  }

  public async getLoginRequestModel() {
    let creationOptions = (await this.http
      .get('api/authentication/webauthn/login')
      .toPromise()) as any;
    creationOptions.creationOptions.challenge = new TextEncoder().encode(
      creationOptions.creationOptions.challenge
    );
    this.setSession(creationOptions.sessionId);

    return creationOptions;
  }

  private encodeBase64(uint8Array: Uint8Array): string {
    return btoa(this.convertToString(uint8Array));
  }

  private convertToString(uint8Array: Uint8Array): string {
    let binary = '';
    const length = uint8Array.length;

    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }

    return binary;
  }

  private base64urlDecode(input): string {
    // Convert URL-safe encoding to standard Base64 encoding
    input = input.replace(/-/g, '+').replace(/_/g, '/');
  
    // Add padding if necessary
    const paddingLength = input.length % 4;
    if (paddingLength === 2) {
      input += '==';
    } else if (paddingLength === 3) {
      input += '=';
    }
  
    // Decode the Base64 string
    return atob(input);
  }

  public setSession(sessionId): void {
    sessionStorage.setItem("session", sessionId);
  }

  public getSession(): string {
    return sessionStorage.getItem("session");
  }
}
