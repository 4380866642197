import { User } from 'src/app/models/User';
import { ToastrService } from 'ngx-toastr';
import { Permission } from './../../../models/Permission';
import { NbDialogService } from '@nebular/theme';
import { PermissionsService } from './../../../services/permissions.service';
import { UserGroup } from './../../../models/UserGroup';
import { UsergroupService } from './../../../services/usergroup.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { group } from 'console';
import { ContextMenuItem } from 'src/app/models/ContextMenuItem';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.less']
})
export class PermissionsComponent implements OnInit {
  @ViewChild('dialog') dialogTemplate;
  @ViewChild('dialogCreateUserGroup') dialogTemplateCreateUserGroup;
  @ViewChild('dialogDeleteUserGroup') dialogTemplateDeleteUserGroup;
  @ViewChild('dialogEditUserGroup') dialogTemplateEditUserGroup;
  @ViewChild('dialogManagePermissions') dialogTemplateManagePermissions;

  userGroups: UserGroup[];
  permissionsLoading: boolean;
  groupPermissions: Permission[];
  dialogRefPermissionInfo;
  dialogRefCreateUserGroup;
  dialogRefDeleteUserGroup;
  dialogRefEditUserGroup;
  dialogRefManagePermissions;
  permissionInfo: Permission;
  selectedUserGroup: UserGroup;
  userGroupToDelete: UserGroup;
  userGroupToEdit: UserGroup;

  constructor(private userGroupService: UsergroupService,
    private permissionService: PermissionsService,
    private dialogService: NbDialogService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadUserGroups();
  }

  private loadUserGroups() {
    this.userGroupService.getUserGroups().subscribe(x => {
      this.userGroups = x;
      this.selectUserGroup(this.userGroups[0]);
    });
  }

  public selectUserGroup(group: UserGroup) {
    this.selectedUserGroup = group;
    this.permissionsLoading = true;
    this.unSelectAllGroups();
    group.selected = true;

    this.permissionService.getPermissionsForGroup(group.groupId)
      .subscribe(result => {
        this.groupPermissions = result;
        this.permissionsLoading = false;
      })
  }

  public showPermissionInfo(permission: Permission) {
    this.permissionInfo = permission;
    this.dialogRefPermissionInfo = this.dialogService.open(this.dialogTemplate);
  }


  private unSelectAllGroups() {
    this.userGroups.forEach(group => {
      group.selected = false;
    });
  }

  public updateUserGroupRights(permission, event) {
    this.permissionService.updateUserGroupRights(this.selectedUserGroup.groupId, permission.permissionGroupId, event)
      .subscribe();
  }

  public openUserGroupCreationDialog() {
    this.dialogRefCreateUserGroup = this.dialogService.open(this.dialogTemplateCreateUserGroup);
  }

  public createUserGroup(name: string) {
    if (name.length >= 5) {
      this.dialogRefCreateUserGroup.close();
      this.userGroupService.createUserGroup(name).subscribe(x => {
        this.loadUserGroups();
      });
    } else {
      this.toastr.info("Eine Benutzergruppe muss mindestens 5 Buchstaben haben")
    }
  }

  public getUserGroupContextItems(userGroup: UserGroup): ContextMenuItem[] {
    return [
      {
        title: 'Bearbeiten',
        icon: { icon: 'fa-pen', pack: 'fa' },
        queryParams: { groupId: userGroup.groupId }
      },
      {
        title: 'Löschen',
        icon: { icon: 'fa-fire', pack: 'fa' },
        queryParams: { groupId: userGroup.groupId }
      }
    ];
  }

  public userGroupContextMenuItemClicked(item: ContextMenuItem) {
    switch (item.title) {
      case 'Löschen':
        this.deleteUserGroup(item.queryParams.groupId);
        break;
      case 'Bearbeiten':
        this.editUserGroup(item.queryParams.groupId);
        break;
    }
  }

  public editUserGroup(userGroupId: number) {
    this.userGroupToEdit = this.userGroups.filter(x => x.groupId == userGroupId)[0];

    this.dialogRefEditUserGroup = this.dialogService.open(this.dialogTemplateEditUserGroup);
  }

  public editUserGroupConfirmed() {
    this.userGroupService.editUserGroup(this.userGroupToEdit.groupId, this.userGroupToEdit.name).subscribe(x => this.loadUserGroups());

    this.dialogRefEditUserGroup.close();
  }

  public deleteUserGroup(userGroupId: number) {
    this.userGroupToDelete = this.userGroups.filter(x => x.groupId == userGroupId)[0];

    this.dialogRefDeleteUserGroup = this.dialogService.open(this.dialogTemplateDeleteUserGroup);
  }

  public deleteUserGroupConfirmed() {
    this.dialogRefDeleteUserGroup.close();

    this.userGroupService.deleteUserGroup(this.userGroupToDelete.groupId)
      .subscribe(x => this.loadUserGroups());
  }

  public closePermissionInfoDialog() {
    this.dialogRefPermissionInfo.close();
  }

  public closeCreateUserGroupDialog() {
    this.dialogRefCreateUserGroup.close();
  }

  public openManagePermissionsDialog() {
    this.dialogRefManagePermissions = this.dialogService.open(this.dialogTemplateManagePermissions);
  }

}
