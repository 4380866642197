import { ContextMenuItem } from 'src/app/models/ContextMenuItem';
import { ContextmenuService } from '../services/contextmenu.service';
import { ComponentRef, Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ConnectedPosition, FlexibleConnectedPositionStrategyOrigin, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ContextmenuComponent } from 'src/app/components/contextmenu/contextmenu.component';

@Directive({
  selector: '[appContextmenu]'
})
export class ContextmenuDirective {
  @Input() items;
  @Input() useLeftClick: boolean = false;
  @Input() active: boolean = true;
  @Output() clicked: EventEmitter<ContextMenuItem> = new EventEmitter();

  element: ElementRef;
  overlayRef: OverlayRef;
  contextEventSubcription: any;
  ignoreNextEvent: boolean;
  test;

  constructor(el: ElementRef,
    private overlay: Overlay,
    private contextmenuService: ContextmenuService) {
    this.element = el;
  }

  ngOnInit(): any {
    this.bindEvent();
  }

  /**
   * Bindet das Event
   */
  bindEvent() {
    var me = this;
    let event = 'contextmenu';

    if (this.useLeftClick) {
      event = 'click'
    }

    this.element.nativeElement.addEventListener(event, function (event) {
      me.onClick(event);
    });
  }

  onClick(event) {
    if (!this.active) {
      return;
    }

    var me = this;
    event.preventDefault();

    // Schließe andere noch offene Kontextmenüs
    this.contextmenuService.emitCloseEvent('');

    var menuHeight: number = this.items.length * 30;

    // Setze Position
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({x: event.clientX, y: event.clientY})
      .withPositions([{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top'
      } as ConnectedPosition]);

    const overlayConfig = new OverlayConfig({
      positionStrategy
    });

    // Flag, damit beim Öffnen des Menüs es nicht sofort wieder geschlossen wird
    this.ignoreNextEvent = true;

    this.overlayRef = this.overlay.create(overlayConfig);

    // Definiere Komponente des Overlays
    const userProfilePortal = new ComponentPortal(ContextmenuComponent);

    const componentRef = this.overlayRef.attach(userProfilePortal);

    this.setOverlayData(componentRef);

    var height = componentRef.location.nativeElement.offsetHeight;

    this.contextEventSubcription = this.contextmenuService.getCloseEventEmitter().subscribe(
      target => {
        // Erstes Close-Event wird ignoriert (nach dem Öffnen dieses Menüs)
        if (!me.ignoreNextEvent) {
          me.closeContextMenu(target);
        }
        me.ignoreNextEvent = false;
      }
    );
  }

  /**
   * Setze Daten, die an das OverlayComponent übergeben werden
   * @param componentRef
   */
  setOverlayData(componentRef: ComponentRef<ContextmenuComponent>) {
    componentRef.instance.items = this.items;
    componentRef.instance.clicked.subscribe(
      x => this.clicked.emit(x)
    );
  }

  closeContextMenu(target) {
    this.contextEventSubcription.unsubscribe();
    this.overlayRef.dispose();
  }
}
