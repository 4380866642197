import { PermissionTypeDescriptor } from './../models/PermissionTypeDescriptor';
import { PermissionGroup } from './../models/PermissionGroup';
import { PermissionUpdateModel } from './../models/PermissionUpdateModel';
import { UsergroupService } from './usergroup.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from '../models/Permission';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private http: HttpClient) { }

  public getPermissionsForGroup(groupId: number) {
    return this.http.get<Permission[]>('/api/permissions/forgroup?groupId=' + groupId);
  }

  public updateUserGroupRights(userGroupId: number, permissionGroupId: number, active: boolean) {
    return this.http.post('/api/permissions/update', {
      userGroupId: userGroupId,
      permissionGroupId: permissionGroupId,
      active: active
    });
  }

  public getPermissionTypes() {
    return this.http.get<PermissionTypeDescriptor[]>('/api/permissions/types');
  }

  // Berechtigungen
  public getPermissions() {
    return this.http.get<Permission[]>('/api/permissions');
  }

  public savePermission(permission: Permission) {
    return this.http.post('/api/permissions', permission);
  }

  public deletePermission(permission: Permission) {
    return this.http.delete('/api/permissions?permissionId=' + permission.permissionId);
  }

  // Berechtigungsgruppen
  public getPermissionsGroups() {
    return this.http.get<PermissionGroup[]>('/api/permissions/group');
  }

  public savePermissionGroup(permissionGroup: PermissionGroup) {
    return this.http.post('/api/permissions/group/update', permissionGroup);
  }

  public deletePermissionGroup(permissionGroup: PermissionGroup) {
    return this.http.delete('/api/permissions/group/delete?permissionGroupId=' + permissionGroup.permissionGroupId);
  }

  public loadPermissions() {
    return this.http.get('/api/permissions/keys')
    .pipe(
      map((keys) => {
        localStorage.setItem("permissionsKeys", JSON.stringify(keys));
        return keys;
      })
    )
  }

  public hasPermission(key: string) {
    var permissionKeys = JSON.parse(localStorage.getItem('permissionsKeys'));

    if (permissionKeys.includes("function.superuser")) {
      return true;
    }

    return permissionKeys.includes(key);
  }
}
