import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayjoin'
})
export class ArrayjoinPipe implements PipeTransform {

  transform(value: any): string {
    return value.join(', ');
  }

}
