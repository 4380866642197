import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Monitoring } from '../models/Monitoring';
import { MonitoringDetails } from '../models/MonitoringDetails';
import { MonitoringFailure } from '../models/MonitoringFailure';
import { MonitoringFailureDetails } from '../models/MonitoringFailureDetails';
import { MonitoringTreshold } from '../models/MonitoringTreshold';
import { MonitoringformComponent } from '../pages/settings/managemonitorings/monitoringform/monitoringform.component';
import { MonitoringFailureHistory } from '@root/app/models/MonitoringFailureHistory';
import { MonitoringHistoryDto } from '@root/app/models/MonitoringHistoryDto';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor(private http: HttpClient) { }

  public getMonitorings() {
    return this.http.get<Monitoring[]>('/api/monitoring');
  }

  public createNewTreshold(): MonitoringTreshold {
    let treshold = new MonitoringTreshold();
    treshold.monitoringTresholdId = 0;
    treshold.monitoringId = 0;
    return treshold;
  }

  public updateMonitoring(monitoring: Monitoring) {
    return this.http.post('/api/monitoring', monitoring);
  }

  public deleteMonitoring(monitoringId: number) {
    return this.http.delete('/api/monitoring?monitoringId=' + monitoringId);
  }

  public getMonitoringDetails() {
    return this.http.get<MonitoringDetails>('/api/monitoringfailure');
  }

  public getMonitoringFailureDetailsById(monitoringFailureId: number) {
    return this.http.get<MonitoringDetails>('/api/monitoringfailure?monitoringFailureId=' + monitoringFailureId);
  }

  public deleteMonitoringFailureDetails(monitoringFailureDetails: MonitoringFailureDetails) {
    return this.http.delete('/api/monitoringfailure?monitoringFailureId=' + monitoringFailureDetails.monitoringFailure.monitoringFailureId);
  }

  public updateMailNotification(setMailNotification: boolean) {
    return this.http.post("/api/monitoring/notification", {receiveNotifications: setMailNotification});
  }

  public inspectFailure(monitoringFailure: MonitoringFailureDetails) {
    return this.http.post("/api/monitoringfailure/inspect", {monitoringFailureId: monitoringFailure.monitoringFailure.monitoringFailureId});
  }

  public toggleMonitoringActivation(monitoringId: number, active: boolean) {
    return this.http.patch("/api/monitoring", { monitoringId: monitoringId, active: active});
  }

  /**
   * Ermittelt die MonitoringFailures eines spezifischen Jahres
   * @param year Jahre
   * @returns 
   */
  public getMonitoringFailuresHistory(year: number, recent: boolean) {
    return this.http.get<MonitoringHistoryDto[]>('/api/monitoringfailure/history?year=' + year + '&recent=' + recent);
  }

  public getYearButtons() {
    return this.http.get<string[]>('/api/monitoringfailure/yearbuttons');
  }
}
