<div class="monthlystats" style="position: relative;">
    <nwa-fastnavigation></nwa-fastnavigation>
    <div class="row">
        <div class="col-xl-6">
            <h5>Monatsübersicht {{stats?.month}} {{stats?.year}}</h5>
            <span class="subtitle">{{statsInfo ?? "-"}}</span>
            <!-- <nb-alert status="info" class="mt-2">
          Diese Seite befindet sich noch in Entwicklung!<br> Vorschläge bitte melden!
      </nb-alert> -->
        </div>
        <div class="col-xl-6 d-flex align-items-center mt-3 mt-xl-0" *ngIf="stats" style="overflow: auto;">
            <nb-button-group *ngFor="let month of stats?.monthButtons">
                <button nbButtonToggle [pressed]="stats?.month == month.title" (click)="routeTo(month.month, month.year)">{{month.title}}</button>
            </nb-button-group>
        </div>
    </div>

    <div class="cards" [nbSpinner]="loading">
        <div class="row mt-3">
            <div class="col-xl-3">
                <nwa-statscard title="Zählerstand" subtitle="Ende des Monats" [value]="stats?.monthStats.zaehlerstand | unit: 0" unit="kWh" icon="droplet-outline" backgroundColor="#B3D4FF"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Volumenstand" subtitle="Ende des Monats" [value]="stats?.monthStats.volumenstand | unit: 0" unit="m³" icon="arrowhead-up-outline" backgroundColor="#B3D4FF"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Wärmeverbrauch" [subtitle]="stats?.month + ' ' + stats?.year" [value]="stats?.monthStats.waermeverbrauch | unit: 0" unit="kWh" icon="thermometer-outline" backgroundColor="#FFFCC0"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Volumen" [subtitle]="stats?.month + ' ' + stats?.year" [value]="stats?.monthStats.volumen | unit: 1" unit="m³" icon="cube-outline" backgroundColor="#FFFCC0"></nwa-statscard>
            </div>

        </div>
        <!-- <div class="row">
    <div class="col">
        <h5>Überblick {{stats?.month}} {{stats?.year}}</h5>
    </div>
</div> -->
        <div class="row">
            <div class="col-xl-3">
                <nwa-statscard *ngIf="hasTemperature" title="Ø Außentemperatur" [subtitle]="stats?.month + ' ' + stats?.year" [value]="stats?.monthStats.durchschnittstemperatur | unit : 1" unit="°C" icon="thermometer-outline" backgroundColor="#95E2FF"></nwa-statscard>
                <nwa-statscard title="Höchster Tagesverbrauch" [subtitle]="stats?.month + ' ' + stats?.year" [value]="stats?.averageMonthlyConsumption.hoechsterTagsverbrauch | unit : 2" unit="kWh" icon="diagonal-arrow-right-up-outline" backgroundColor="#A4E5D1"></nwa-statscard>
            </div>
            <div class="col-xl-3">
                <nwa-statscard title="Ø Tagesverbrauch" [subtitle]="stats?.month + ' ' + stats?.year" [value]="stats?.averageMonthlyConsumption.durchTaeglicherWaermeverbrauch | unit : 2" unit="kWh" icon="flash-outline" backgroundColor="#95E2FF"></nwa-statscard>
                <!-- DownloadCard -->
                <nb-card style="overflow: hidden;" class="download-card" *ngIf="showPdfDownload">
                    <nb-card-body class="d-flex flex-column">
                        <div class="mb-auto title-header">
                            <span class="title">PDF-Download</span>
                        </div>
                        <span class="subtitle mb-1">Deine Monatsübersicht ist zum <br> Download verfügbar.</span>
                        <button nbButton status="info" (click)="downloadMonthStats()">Download</button>
                        <div class="icon">
                            <nb-icon icon="cloud-download-outline">
                            </nb-icon>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
            <div class="col-xl-6">
                <nwa-chartcard [subtitle]="stats?.month" [series]="seriesEvent" [average]="stats?.averageMonthlyConsumption.durchTaeglicherWaermeverbrauch"></nwa-chartcard>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <nb-card [nbSpinner]="loading">
                <nb-card-body>
                    <span class="title">Tägliche Auflistung</span>
                    <span class="table-subtitle mb-2">Datenstand jeweils Ende des Tages</span>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Tag</th>
                                <th>Zählerstand</th>
                                <th>Verbrauch</th>
                                <th>Volumenstand</th>
                                <th>Volumenbedarf</th>
                                <th>Volumen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dayConsumption of dayConsumptions">
                                <td>{{dayConsumption.day}}</td>
                                <td class="d-flex align-items-center">{{dayConsumption.meterReading | unit : 0 : ' kWh'}}
                                    <!-- <ng-container *ngIf="dayConsumption.meterReadingChangement">
                                        <nb-icon icon="arrowhead-up-outline" class="positive-changement ms-2"></nb-icon><span class="change-text">+ {{dayConsumption.meterReadingChangement | unit : 0 : ' kWh'}}</span>
                                    </ng-container> -->
                                </td>
                                <td>{{dayConsumption.powerConsumption | unit : 0 : ' kWh'}}</td>
                                <td>{{dayConsumption.meterVolume | unit : 0 : ' m³'}}</td>
                                <td>{{dayConsumption.volumeRequirement | unit : 0 : ' m³/MWh'}}</td>
                                <td>{{dayConsumption.volume| unit : 1 : ' m³'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>