<!-- <div class="logo">
    <img src="../../../assets/images/logobeta.svg">
</div> -->
<nb-card class="mt-2">
    <nb-card-body>
        <video #videoPlayer autoplay muted loop width="60%" oncanplay="this.muted=true;this.play()" src="assets/FireIcon.webm" style="margin-left: auto; margin-right: auto;" type="video/webm"></video>
        <!-- <nb-icon icon="alert-circle-outline"></nb-icon><br> -->
        <!-- <img src="assets/images/logocenternoicon.png" width="70%"><br><br> -->
        <h3 #test>Wartungsarbeiten</h3>
        <!-- <span>Im Moment werden <strong>Wartungsarbeiten</strong> durchgeführt!<br><br></span> -->
        <span>Diese Seite lädt automatisch neu, sobald das Dashboard wieder verfügbar ist.</span>
    </nb-card-body>
</nb-card>