import { Injectable } from '@angular/core';
import { ChartAnnotation } from '../models/ChartAnnotation';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() { }

  public getNewMonitoringAnnotation(startDate: Date, endDate: Date): ChartAnnotation {
    return {
      x: startDate.getTime(),
      x2: endDate.getTime(),
      fillColor: '#f57a7a',
      // label: {
      //   text: 'Fehler',
      //   style: {
      //     color: 'white',
      //     background: '#f57a7a'
      //   },
      // }
    } as ChartAnnotation;
  }
}
