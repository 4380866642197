import { HttpClient } from '@angular/common/http';
import { PriceFilter } from './../models/PriceFilter';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  readonly filterKey: string = "priceFilter";

  constructor(
    private http: HttpClient
  ) { }

  public getPriceFilter(): PriceFilter {
    if (localStorage.getItem(this.filterKey)) {
      var filter = JSON.parse(localStorage.getItem(this.filterKey)) as PriceFilter;
      return filter;
    }
    return {
      year: new Date().getFullYear()
    };
  }

  public savePriceFilter(filter: PriceFilter) {
    localStorage.setItem(this.filterKey, JSON.stringify(filter));
  }

  public getYearSelection() {
    return this.http.get<number[]>('/api/bill/filter/years');
  }
}
