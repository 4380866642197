import { Role } from "@root/app/models/Role";
import { ToolCall } from "@root/app/models/ToolCall";

export class ChatMessage {
    id: string;
    index: number;
    role: Role;
    content: string;
    threadId: string;
    toolCalls: ToolCall[];
}