import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AuthenticationResult } from '../../models/AuthenticationResult';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  error = '';
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private activedRoute: ActivatedRoute
  ) {
    this.loginForm = this.formBuilder.group({
      username: '',
      password: '',
    });
  }

  ngOnInit(): void {
    this.activedRoute.fragment.subscribe((params) => {
      let qrKey = new URLSearchParams(params).get('qrKey');

      if (qrKey) {
        this.authenticationService.loginQrKey(qrKey).subscribe(
          (data) => {
            this.router.navigate(['/']);
            this.error = '';
          },
          (error) => {
            this.toastr.error(error.error.message, 'Login fehlschlagen!');
            this.toggleLoading();
          }
        );
      }
    })

    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  onSubmit(loginForm) {
    this.authenticationService
      .login(new User(loginForm.username, loginForm.password))
      .pipe(first())
      .subscribe(
        (data) => {
          this.router.navigate(['/']);
          this.error = '';
        },
        (error) => {
          this.toastr.error(error.error.message, 'Login fehlschlagen!');
          this.toggleLoading();
        }
      );
  }

  /**
   * Anmeldung mit WebAuthn
   */
  public async loginWithWebAuthn() {
    this.toggleLoading();
    (await this.authenticationService.loginWebAuthn()).subscribe(
      (data: AuthenticationResult) => {
        if (data.isSuccessful) {
          this.router.navigate(['/']);
          this.error = '';
        } else {
          this.toastr.error(data.errorMessage);
          this.toggleLoading();
        }
      },
      (error) => {
        this.toastr.error(error.error.message, 'Login fehlschlagen!');
        this.toggleLoading();
      }
    );
  }

  closeError() {
    this.error = '';
  }

  toggleLoading() {
    this.loading = !this.loading;
  }
}
