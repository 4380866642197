import { PermissionsService } from './../../services/permissions.service';
import { ContextMenuItem } from './../../models/ContextMenuItem';
import { OverviewRecord } from './../../models/OverviewRecord';
import { NetService } from './../../services/net.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Overview } from 'src/app/models/Overview';
import { NbContextMenuDirective } from '@nebular/theme';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.less']
})
export class NetworkComponent implements OnInit {
  hasDetailsPermission = false;
  overviewModel: Overview;
  interval;
  firstLoading: boolean = true;
  loading: boolean = false;

  constructor(private netService: NetService,
    private permissionService: PermissionsService) { }

  ngOnInit(): void {
    var me = this;
    this.updateOverview();

    // Alle 5 min neuladen
    setTimeout(function() {
      me.updateOverview();
      me.interval = setInterval(() => {
        me.updateOverview();
      }, 5000 * 60);
    }, this.getNextUpdateTimeDifference());

    this.hasDetailsPermission = this.permissionService.hasPermission("function.viewForeignDetails");
  }

  private updateOverview() {
    this.loading = true;
    this.netService.getOverview().subscribe(data => {
      this.overviewModel = data;
      this.firstLoading = false;
      this.loading = false;
    });
  }

  status(value) {
    if (value > 95) {
      return 'warning';
    }
    return 'info';
  }

  statusPower(value, maxValue) {
    if (value >= maxValue) {
      return 'warning';
    }
    return 'info';
  }

  private getNextUpdateTimeDifference() {
    let date = new Date();

    while (date.getMinutes() % 5 != 0) {
      date = new Date(date.getTime() + 60000);
    }

    date = new Date(date.getTime() + 120000);
    date = new Date(date.getTime() - date.getSeconds() * 1000);

    // console.log((date.toString()));
    return date.getTime() - new Date().getTime();
  }

  public getMenuItems(record): ContextMenuItem[] {
    var items : ContextMenuItem[] = [{
      title: 'Übersicht',
      icon: { icon: 'grid-outline', pack: '' },
      link: '/uebersicht',
      queryParams: {id: record.nodeId}
    }];

    if (this.permissionService.hasPermission('page.diagram')) {
      items.push({
        title: 'Verlauf',
        icon: { icon: 'bar-chart-outline', pack: '' },
        link: '/verlauf',
        queryParams: {id: record.nodeId}
      });
    }

    if (this.permissionService.hasPermission('page.monatsuebersicht')) {
      items.push(      {
        title: 'Monatsübersicht',
        icon: { icon: 'calendar-outline', pack: '' },
        link: '/monatsuebersicht',
        queryParams: {id: record.nodeId}
      });
    }

    if (this.permissionService.hasPermission('page.jahresuebersicht')) {
      items.push(      {
        title: 'Jahresübersicht',
        icon: { icon: 'pie-chart-outline', pack: '' },
        link: '/jahresuebersicht',
        queryParams: {id: record.nodeId}
      });
    }

    return items;
  }
}
