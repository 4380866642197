<div class="monitoring-failures">
    <div class="mb-3 d-flex align-items-center">
        <!-- Back-Button -->
        <button nbButton shape="round" status="control" size="small" (click)="goBack()" class="round-button"><nb-icon icon="arrow-ios-back-outline"></nb-icon></button>
        <!-- Title -->
        <h5 class="ms-2 mt-2"><nb-icon icon="shield-outline" class="me-2" style="font-size: 1.375rem;"></nb-icon>{{monitoringFailure?.monitoring.title}}</h5>
        <!-- Inspection Buttons -->
        <button nbButton shape="round" status="basic" size="small" (click)="setMonitoringFailureInspected()" class="ms-auto"
            [disabled]="monitoringFailure && monitoringFailure.monitoringFailure.inspected">Bestätigen <nb-icon icon="checkmark-circle-outline"></nb-icon></button>
        <button nbButton shape="round" status="success" size="small" (click)="setMonitoringFailureInspected(true)" class="ms-2"
            [disabled]="monitoringFailure && monitoringFailure.monitoringFailure.inspected">Bestätigen & Schließen<nb-icon icon="checkmark-circle-outline"></nb-icon></button>
    </div>
    <div class="monitoring-detail">
          <nb-card [nbSpinner]="loadingChart" *ngIf="showChart">
              <nb-card-body>
                  <div #chartWrapper style="height: 50vh">
                      <nwa-protocolchart #chart></nwa-protocolchart>
                  </div>
              </nb-card-body>
          </nb-card>
          @if(monitoringFailure && monitoringFailure.monitoringFailure.inspected) {
            <nb-alert status="success">
                <nb-icon icon="checkmark-circle-outline" class="me-2"></nb-icon><span>Dieser Eintrag wurde bereits inspiziert</span>
            </nb-alert>
          }
        <nb-card>
            <nb-card-body *ngIf="monitoringFailure">
                <div class="row">
                    <div class="col-8">
                        <div class="d-flex flex-row title">
                            <nb-icon icon="book-open-outline" class="me-2"></nb-icon>
                            <b>Fehlerbeschreibung</b>
                        </div>
                        <div class="description">{{monitoringFailure.monitoring.description}}</div>
                        <!-- Fehlerinformation Treshold -->
                        <div class="failure-info mt-5" *ngIf="monitoringFailure.monitoring.monitoringType == 0">
                            <div class="title"><nb-icon icon="info-outline" class="me-2"></nb-icon><b>Fehlerinformation</b><br></div>
                            Fehler wird gemeldet wenn,
                            <nwa-monitoringtresholds [monitoringTresholds]="monitoringFailure.monitoring.monitoringTresholds"></nwa-monitoringtresholds>
                            länger als <b>{{monitoringFailure.monitoring.timeRange}}</b> Minuten.
                        </div>
                        <!-- Fehlerinformation ProtocolTime -->
                        <div class="failure-info mt-5" *ngIf="monitoringFailure.monitoring.monitoringType == 1">
                            <div class="title"><nb-icon icon="info-outline" class="me-2"></nb-icon><b>Fehlerinformation</b><br></div>
                            Fehler wird gemeldet wenn, länger als <b>{{monitoringFailure.monitoring.timeRange}}</b> Minuten keine Protokolldaten des Anschlusses erhalten werden.
                        </div>
                    </div>
                    <div class="col-4">
                        <nb-icon icon="hash-outline" class="me-2"></nb-icon>Anschluss {{monitoringFailure.node.nodeId}}<br>
                        <nb-icon icon="clock-outline" class="me-2 mt-2"></nb-icon>{{monitoringFailure.monitoringFailure.startTime | date: 'dd.MM.YY HH:mm'}} - {{monitoringFailure.monitoringFailure.endTime | date: 'dd.MM.YY HH:mm'}}<br>
                        <nb-icon icon="home-outline" class="me-2 mt-2"></nb-icon>{{monitoringFailure.node.ownerFirstName}} {{monitoringFailure.node.ownerLastName}}, {{monitoringFailure.node.street}} {{monitoringFailure.node.housenumber}}<br>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>