export enum SpecialDay {
    None = 'None',
    Earthday = 'Earthday',
    Worldbeeday = 'Worldbeeday'
}

export class SpecialDayProvider {
    private static specialDays : Map<string, SpecialDay> = 
        new Map([
            ["22.04", SpecialDay.Earthday],
            ["20.05", SpecialDay.Worldbeeday]
        ])

    public static getTodaysSpecialDay() {
        return this.specialDays.get(this.getTodaysKey()) ?? SpecialDay.None;
    }

    private static getTodaysKey() {
        let now = new Date(); 
        return `${now.getDate().toString().padStart(2, '0')}.${(now.getMonth() + 1).toString().padStart(2,'0')}`;
      }
    
}