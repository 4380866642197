import { Component, OnInit } from '@angular/core';
import { SpecialDay, SpecialDayProvider } from '../../models/SpecialDays';

@Component({
  selector: 'nwa-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.less']
})
export class LogoComponent implements OnInit {
  todaysSpecialDay: SpecialDay = SpecialDay.None;
  SpecialDay = SpecialDay;

  constructor() { }

  ngOnInit(): void {
    this.todaysSpecialDay = SpecialDayProvider.getTodaysSpecialDay();
  }

}
