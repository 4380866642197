<h5>Information</h5>
<span class="subtitle"
  >Hier findest du grundlegende Informationen über das Dashboard.</span
>

<div *ngIf="anonymousModeActive" class="card ghost-card mt-2">
  <div class="card-body">
    <div class="row">
      <div class="col-auto">
        <i class="fas fa-ghost me-2 fa-2x"></i>
      </div>
      <div class="col">
        <h5>Anonymer Modus aktiv</h5>
        <p>
          Dieser Modus kann zu Demonstrationszwecken herangezogen werden.
          Jegliche personenbezogenen Informationen werden ausgeblendet oder
          durch randomisierte Daten ausgetauscht. Einige Seiten und Funktionen
          sind in diesem Modus womöglich deaktiviert.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="project">
  <h6>Ein Dashboard für die Nahwärme Amerbach eG</h6>
  Dieses Dashboard wurde eigens für die Nahwärme Amerbach eG über den Zeitraum
  mehrerer Monate entwickelt und befindet sich stets in Weiterentwicklung.<br />
  Es soll gemäß der neuen
  <em
    >Verordnung über die Verbrauchserfassung und Abrechnung bei der Versorgung
    mit Fernwärme</em
  >
  (FFVAV siehe
  <a href="https://www.gesetze-im-internet.de/ffvav/BJNR459110021.html">hier</a
  >) eine Möglichkeit schaffen, die Verbrauchsdaten sicher online abzurufen zu
  können.<br />
  <h6>Projektbeteiligte:</h6>
  <b>Programmierung, Design:</b> Jeremias Stenzenberger<br />
  <b>Konzeption:</b> Jonas Stenzenberger
</div>
<div class="contact">
  <h6>Fragen, Ideen, Wünsche?</h6>
  Gerne können Sie uns über folgende Email-Adresse kontaktieren:<br />
  <b>Email: </b
  ><a href="mailto:dashboard@nahwaerme-amerbach.de"
    >dashboard&#64;nahwaerme-amerbach.de</a
  >
</div>
<div class="change-password">
  <h6>Passwort ändern?</h6>
  Das Passwort kann über die Homepage der
  <a
    href="https://nahwaerme-amerbach.de/index.php/kontoeinstellungen"
    target="_blank"
    >www.nahwaerme-amerbach.de</a
  >
  abgeändert werden.
</div>
<div class="imprint">
  <h6>Impressum</h6>
  Das Impressum befindet sich auf der Homepage der Nahwärme Amerbach:<br />
  <a href="https://nahwaerme-amerbach.de/index.php/impressum" target="_blank"
    >www.nahwaerme-amerbach.de</a
  >
</div>
<div class="logo mt-4 d-flex flex-row align-items-center">
  <div class="row ms-2">
    <div class="col-auto">
      <img
      src="assets/images/logo.svg"
      style="min-height: 50px; max-height: 50px"
    />
    </div>
    <div class="col-auto">
      <nwa-earth style="width: 80px"></nwa-earth>
    </div>
    <div class="col d-flex align-items-center">
      <div>
        <!-- <span style="font-size: 25pt;">Nachhaltigkeit</span><br>ist wichtig -->
        Nachhaltige<br /><span style="font-size: 25pt">Wärme</span>
      </div>
    </div>
  </div>
</div>
<div class="copyright mt-4">
  <span>© 2023 Nahwärme Amerbach eG</span>
</div>
