import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NbOptionComponent, NbSelectComponent } from '@nebular/theme';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Monitoring } from '../../../../models/Monitoring';
import { MonitoringTreshold } from '../../../../models/MonitoringTreshold';
import { Node } from '../../../../models/Node';
import { ProtColumn } from '../../../../models/ProtColumn';
import { MonitoringService } from '../../../../services/monitoring.service';
import { NodeService } from '../../../../services/node.service';
import { ProtocolService } from '../../../../services/protocol.service';

@Component({
  selector: 'nwa-monitoringform',
  templateUrl: './monitoringform.component.html',
  styleUrls: ['./monitoringform.component.less']
})
export class MonitoringformComponent implements OnInit, OnChanges {
  @Output() formSubmitted = new EventEmitter<Monitoring>();
  @Output() formClosed = new EventEmitter<boolean>();
  @Input() monitoring: Monitoring = new Monitoring();
  @ViewChild('monitoringNodes') monitoringNodesInput;

  nodes: Node[];
  monitoringNodesLabel: string;
  protColumns : ProtColumn[];
  filteredProtColumnOptions: Observable<ProtColumn[]>;

  constructor(
    private nodeService: NodeService,
    private protocolService: ProtocolService,
    private monitoringService: MonitoringService
  ) { }

  ngOnInit(): void {
    this.nodeService.getNodes().subscribe(nodes => {
      this.nodes = nodes;
    })

    this.protocolService.getProtColumns()
      .subscribe(protColumns => this.protColumns = protColumns);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMonitoringNodesLabel();
  }

  onSubmit() {
    this.formSubmitted.emit(this.monitoring);
  }

  closeForm() {
    this.formClosed.emit(true);
  }

  selectAll(element) {
    this.monitoring.monitoringNodes = this.nodes.map(x => x.nodeId);
    this.updateMonitoringNodesLabel();
  }

  updateMonitoringNodesLabel() {
    this.monitoringNodesLabel = this.monitoring.monitoringNodes.join(', ');
  }

  createNewTreshold() {
    let newTreshold = this.monitoringService.createNewTreshold();
    this.monitoring.monitoringTresholds.push(newTreshold);
  }

  // -- Treshold

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  private filter(value: string): ProtColumn[] {
    if (!this.protColumns) {
      return null;
    }
    const filterValue = value.toLowerCase();
    return this.protColumns.filter(optionValue => optionValue.description.toLowerCase().includes(filterValue)
      || optionValue.propertyName.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string) {
    return of(value).pipe(
      map(filterString => this.filter(filterString))
    );
  }

  onProtColumnSelectionChange($event) {
    if($event) {
      this.filteredProtColumnOptions = this.getFilteredOptions($event);
    }
  }

  onProtColumnTypeChange($event) {
    this.filteredProtColumnOptions = this.getFilteredOptions($event.currentTarget.value);
  }

  onFocusProtColumnInput() {
    this.filteredProtColumnOptions = of(this.protColumns);
  }

  removeTreshold(index) {
    this.monitoring.monitoringTresholds.splice(index, 1);
  }
  
  getValidatorList() {
    return this.protColumns?.map(x => x.name);
  }

  public toogleCompareWithColumn(treshold: MonitoringTreshold) {
    treshold.compareWithColumn = !treshold.compareWithColumn;
  }

  public toggleOffset(index: number) {
    if (this.monitoring.monitoringTresholds[index].offset != null) {
      this.monitoring.monitoringTresholds[index].offset = null;
    } else {
      this.monitoring.monitoringTresholds[index].offset = 0;
    }
  }
}
