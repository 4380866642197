import { ContextmenuService } from './services/contextmenu.service';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent {
  title = 'nwamerbach-dashboard';

  constructor(private contextMenuService: ContextmenuService) {

  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    this.contextMenuService.emitCloseEvent(target);
  }

  @HostListener('document:contextmenu', ['$event.target'])
  public onContext(target) {
    this.contextMenuService.emitCloseEvent(target);
  }
}
