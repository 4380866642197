import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { formatNumber } from '@angular/common';
import { ProtColumn } from '../../models/ProtColumn';
import { StatsService } from '../../services/stats.service';
import { ProtocolChartConfig } from '../../models/ProtocolChartConfig';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nwa-protocolchart',
  templateUrl: './protocolchart.component.html',
  styleUrls: ['./protocolchart.component.less']
})
export class ProtocolchartComponent implements OnInit {
  @ViewChild('chart') chart;
  
  activeSeries: ProtColumn[];
  items;

  constructor(
    private statsService: StatsService
  ) { }

  ngOnInit(): void {
  }

  public loadData(config: ProtocolChartConfig) {
    if (config.height) {
      this.chart.updateOptions({
        chart: {
          height: config.height
        }
      })
    }
    
    if (config.xAxisAnnotations) {
      config.xAxisAnnotations.forEach((annotation) => {
        this.chart.addXaxisAnnotation(annotation);
      })
    }

    return this.statsService.getStats(config.range, config.nodeId, config.startDate, config.endDate)
    .pipe(
      map((res) => {
        this.items = res;
        this.updateConfiguration(config);
        return res;
      }
    ));
  }

  private updateConfiguration(config: ProtocolChartConfig) {
    this.chartOptions.series = [];
    this.activeSeries = [];

    // Spalten ermitteln
    config.activeColumns.forEach(col => {
      this.activeSeries.push(col);
      var values = this.items.map(x => [new Date(x.time).getTime(), x[col.propertyName]]);

      this.chartOptions.series.push(
        {
          name: col.description,
          data: values
        }
      );
    });
  }

  private getUnit(seriesIndex: number) {
    return this.activeSeries[seriesIndex].unit ?? '';
  }

  chartOptions = {
    series: [],
    chart: {
      height: '625',
      width: '98%',
      type: "line",
      redrawOnParentResize: true,
      zoom: {
        enabled: true
      },
      toolbar: {
        show: true
      },
      animations: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth"
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }
    },
    yaxis: {
      labels: {
        formatter: (value) => { return formatNumber(value, 'de-DE'); } 
      }
    },
    tooltip: {
      x: {
        format: 'dd.MM.yy HH:mm'
      },
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          return formatNumber(value, 'de-DE') + this.getUnit(seriesIndex);
        }
      },
      "fixed": {
        position: 'topLeft',
        offsetX: 100
      }
    },
    annotations: {
      xaxis: []
    }
  };
}
