import { NbDialogService } from '@nebular/theme';
import { NodeService } from './../../../services/node.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Node } from 'src/app/models/Node';
import { AuthenticationService } from '../../../services/authentication.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nodes',
  templateUrl: './nodes.component.html',
  styleUrls: ['./nodes.component.less']
})
export class NodesComponent implements OnInit {
  @ViewChild('dialog') dialogTemplate;
  nodes: Node[];
  nodeToDelete: Node;
  nodeToAdd: Node = new Node();
  dialogRef;
  firstLoad: boolean = true;
  loading: boolean;

  constructor(
    private nodeService: NodeService,
    private dialogService: NbDialogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.reloadNodes();
  }

  reloadNodes() {
    if (this.firstLoad) {
      this.firstLoad = false;
      this.loading = true;
    }
    this.nodeService.getNodes().subscribe(nodes => {
      this.nodes = nodes;
      this.loading = false;
    });
  }

  toggleEditMode(node: Node) {
    var index = this.nodes.findIndex(x => x.nodeId == node.nodeId);
    if (!node.editing) {
      this.nodes[index].editing = true;
    } else {
      this.nodes[index].editing = false;
    }
  }

  deleteNode(node: Node) {
    this.nodeToDelete = node;
    this.dialogRef = this.dialogService.open(this.dialogTemplate);
  }

  deleteNodeConfirmed() {
    this.nodeService.deleteNode(this.nodeToDelete).subscribe(
      data => {
        this.reloadNodes();
        this.dialogRef.close();
      })
  }

  updateNode(node: Node) {
    this.nodeService.updateNode(node).subscribe(
      data => {
        this.reloadNodes();
        this.dialogRef.close();
      }
    )
  }

  addNode() {
    this.nodeService.addNode(this.nodeToAdd).subscribe(
      data => {
        this.reloadNodes();
        this.nodeToAdd = new Node();
      })
  }
}
