import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'nwa-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less']
})
export class InfoComponent implements OnInit {
  anonymousModeActive = false;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.anonymousModeActive = this.userService.isAnonymousMode();
  }

}
