import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { AuthenticationService } from './../../services/authentication.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit, AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer: any;

  interval;

  constructor(private authenticationService: AuthenticationService,
    private router: Router) { }

  ngAfterViewInit(): void {
    // this.videoPlayer.nativeElement.mute();
    // this.videoPlayer.nativeElement.play();
  }

  ngOnInit(): void {
    var me = this;;
    this.interval = setInterval(() => {
      this.authenticationService.ping()
        .subscribe(response => {
          clearInterval(me.interval);
          this.router.navigate(["/login"]);
        });
    }, 1000);
  }

}
