<div class="ai-bot-slider" cdkDrag>
    <div class="inner-el" cdkDragHandle>
        <img src="/assets/images/ai/small-aibot.svg" style="width: 40px; height: 40px;"/>
        <h4 class="ms-3">Dashbot</h4>
        <button nbButton shape="round" ghost (click)="close()"><nb-icon icon="close-outline"></nb-icon></button>
    </div>
    <!-- <hr style="margin: 0;"> -->
    <div class="chat-box">
        <nwa-aichat></nwa-aichat>
    </div>
</div>