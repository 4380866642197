import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { WebauthnRegistration } from '../../models/Webauthn/WebauthnRegistration';
import { WebauthnService } from '../../services/webauthn.service';

@Component({
  selector: 'nwa-pwlessauth',
  templateUrl: './pwlessauth.component.html',
  styleUrls: ['./pwlessauth.component.less']
})
export class PwlessauthComponent implements OnInit {
  usesWebAuthn: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private webAuthnService: WebauthnService
  ) { }

  ngOnInit(): void {
    this.webAuthnService.usesWebauthn().subscribe(usesWebauthn => {
      this.usesWebAuthn = usesWebauthn as boolean;
    })
  }

  public register() {
    this.authenticationService.registerWebAuthn().then(res => {
      this.ngOnInit();
      this.toastr.success("Passwortlose Anmeldung eingerichtet!")
    });
  }

  public remove() {
    this.webAuthnService.removeWebauthn().subscribe(x => {
      this.ngOnInit();
    });
  }
}
