import { UserGroup } from './../../../../models/UserGroup';
import { Subject } from 'rxjs';
import { PermissionTypeDescriptor } from './../../../../models/PermissionTypeDescriptor';
import { PermissionGroup } from './../../../../models/PermissionGroup';
import { PermissionsService } from './../../../../services/permissions.service';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Permission } from 'src/app/models/Permission';
import { faXRay } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'rxjs/operators';
import { NbSelectComponent } from '@nebular/theme';

@Component({
  selector: 'nwa-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.less']
})
export class ManageComponent implements OnInit {
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('permissionSelect') permissionSelect: NbSelectComponent;
  @ViewChild('permissionGroupSelect') permissionGroupSelect: NbSelectComponent;

  permissionGroups: PermissionGroup[];
  permissions: Permission[];
  selectedPermissionGroup: PermissionGroup = new PermissionGroup();
  selectedPermission: Permission = new Permission();
  permissionTypes: PermissionTypeDescriptor[];
  selectedObject: number;

  constructor(
    private permissionService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.loadPermissionGroups();
    this.loadPermissions();
    this.permissionService.getPermissionTypes()
      .subscribe(x => this.permissionTypes = x);
      this.prepareNewPermissionGroup();
  }

  // -------------- Berechtigungen

  public onSelectPermission(event) {
    this.selectedPermission = this.permissions.filter(x => x.permissionId == event)[0];
  }

  public savePermission() {
    this.permissionService.savePermission(this.selectedPermission)
      .subscribe(x => {
        this.loadPermissions();
      })
  }

  public prepareNewPermission() {
    this.selectedPermission = new Permission();
    this.selectedPermission.assignedPermissionsGroups = [];
    this.selectedPermission.permissionId = -1;
  }

  public deletePermission() {
    this.permissionService.deletePermission(this.selectedPermission)
      .subscribe(x => {
        this.loadPermissions();
        this.prepareNewPermission();
      });
  }

  public async selectCurrentPermission() {
    var permissionToSelect = this.permissions
      .filter(x => x.key == this.selectedPermission.key)[0];

      // little sleep needed until select has been initialized with new values
      await this.sleep(1);

      if (!permissionToSelect) {
        this.permissionSelect.selected = 0;
        this.prepareNewPermission();
      } else {
        this.permissionSelect.selected = permissionToSelect.permissionId;
        this.onSelectPermission(permissionToSelect.permissionId);
      }
  }

  // -------------- Berechtigungsgruppen

  public getPermissionGroupsSelectItems() {
    var list = this.permissionGroups;
  }

  public onSelectPermissionGroup(event) {
    this.selectedPermissionGroup = this.permissionGroups.filter(x => x.permissionGroupId == event)[0];

  }

  public prepareNewPermissionGroup() {
    this.selectedPermissionGroup = new PermissionGroup();
    this.selectedPermissionGroup.permissionGroupId = -1;
  }

  public savePermissionGroup() {
    this.permissionService.savePermissionGroup(this.selectedPermissionGroup)
    .subscribe(x => {
      this.loadPermissionGroups();
    });
  }

  public deletePermissionGroup() {
    this.permissionService.deletePermissionGroup(this.selectedPermissionGroup)
    .subscribe(x => {
      this.loadPermissionGroups();
      this.prepareNewPermissionGroup();
    });
  }

  public async selectCurrentPermissionGroup() {
    var permissionGroupToSelect = this.permissionGroups
      .filter(x => x.name == this.selectedPermissionGroup.name)[0];

      // little sleep needed until select has been initialized with new values
      await this.sleep(1);

      if (!permissionGroupToSelect) {
        this.permissionGroupSelect.selected = 0;
        this.prepareNewPermissionGroup();
      } else {
        this.permissionGroupSelect.selected = permissionGroupToSelect.permissionGroupId;
        this.onSelectPermissionGroup(permissionGroupToSelect.permissionGroupId);
      }
  }


  // -----------------------------------

  private loadPermissionGroups() {

    this.permissionService.getPermissionsGroups()
      .subscribe(groups => {
        this.permissionGroups = groups;
        this.selectCurrentPermissionGroup();
      });
  }

  private loadPermissions() {
    this.permissionService.getPermissions()
      .subscribe(permissions => {
        this.permissions = permissions;
        this.selectCurrentPermission();
      });
      this.loadPermissionGroups();
  }

  // ---------- Helper -------------------

  public changeTab(event) {
    if (event.tabTitle == 'Berechtigungsgruppen') {
      this.loadPermissionGroups();
    } else {
      this.loadPermissions();
    }
  }

  public closeComponent() {
    if (this.close) {
      this.close.emit(true);
    }
  }

  public getPermissionTypeName(id: number): string {
    return this.permissionTypes.filter(x => x.id == id)[0]?.name;
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
