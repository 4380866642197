<nb-card>
  <nb-card-body>
    <apx-chart
      nbTooltipPlacement="left"
      nbTooltipStatus="info"
      #chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [legend]="chartOptions.legend"
      [tooltip]="chartOptions.tooltip"
      [markers]="chartOptions.markers"
    ></apx-chart>
  </nb-card-body>
</nb-card>
