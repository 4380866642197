import { group } from 'console';
import { UserGroup } from './../models/UserGroup';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsergroupService {

  constructor(private http: HttpClient) { }

  public getAllUserGroups () {
    return this.http.get<UserGroup[]>('/api/usergroup/all');
  }

  public getUserGroups () {
    return this.http.get<UserGroup[]>('/api/usergroup');
  }

  public createUserGroup(nameString: string) {
    return this.http.put('/api/usergroup', {name: nameString});
  }

  public editUserGroup(groupId: number, nameString: string) {
    return this.http.post('/api/usergroup', {userGroupId: groupId ,name: nameString});
  }

  public deleteUserGroup(groupId) {
    return this.http.delete('/api/usergroup/' + groupId);
  }
}
