import { PriceFilter } from './../../../../models/PriceFilter';
import { NodeService } from './../../../../services/node.service';
import { NbDialogService } from '@nebular/theme';
import { PriceService } from './../../../../helpers/priceservice';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ContextMenuItem } from 'src/app/models/ContextMenuItem';
import { Price } from 'src/app/models/Price';
import { FilterService } from 'src/app/services/pricefilter.service';

@Component({
  selector: 'nwa-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.less']
})
export class PricelistComponent implements OnInit {
  @Input() priceService: PriceService
  @Input() loadPrices: EventEmitter<string>;
  @Input() id: string;
  @Input() unitString: string;
  @ViewChild('dialogPrice') dialogTemplatePrice;
  @ViewChild('dialogDeletePrice') dialogTemplateDeletePrice;

  dialogRefPrice;
  dialogRefDeletePrice;
  prices: Price[];
  formPrice: Price = new Price();
  nodes: any;
  dialogPriceTitle = "Preis erstellen";
  filter: PriceFilter;
  priceToDelete: Price;

  constructor(
    private dialogService: NbDialogService,
    private nodeService: NodeService,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.loadPrices.subscribe(x => {
      if (x == this.id) {
        this.load();
      }
    });

    this.getNodeDropdownPoints();
  }

  private getNodeDropdownPoints() {
    this.nodeService.getDropDownNodes().subscribe(nodes => {
      this.nodes = nodes;
      this.nodes.unshift({
        nodeId: -1,
        ownerFirstName: 'Stammpreis',
        ownerLastName: ''
      });
    })
  }

  public load() {
    this.filter = this.filterService.getPriceFilter();
    this.priceService.getPrices(this.filter).subscribe(x => this.prices = x);
  }

  public getMenuItems(id): ContextMenuItem[] {
    var items: ContextMenuItem[] = [
      {
        title: 'Bearbeiten',
        icon: { icon: 'edit-outline', pack: '' },
        queryParams: id
      },
      {
        title: 'Löschen',
        icon: { icon: 'trash-outline', pack: '' },
        queryParams: id
      },
      {
        title: 'Neu',
        icon: { icon: 'plus-outline', pack: '' },
        queryParams: id
      }
    ];

    return items;
  }

  public onContextClicked(item: ContextMenuItem) {
    var selectedPrice = this.prices.filter(x => x.id == item.queryParams)[0];

    switch (item.title) {
      case 'Bearbeiten':
        this.editPrice(selectedPrice)
        break;
      case 'Neu':
        this.createNewPrice(selectedPrice.nodeId);
        break;
      case 'Löschen':
        this.priceToDelete = selectedPrice;
        this.dialogRefDeletePrice = this.dialogService.open(this.dialogTemplateDeletePrice);
        break;
    }
  }

  public deleteSelectedPriceConfirmed() {
    this.priceService.deletePrice(this.priceToDelete).subscribe(x => {
      this.load();
      this.dialogRefDeletePrice.close();
    });
  }

  public createNewPrice(nodeId?: number) {
    this.dialogPriceTitle = "Preis erstellen";
    this.formPrice = new Price();
    if (nodeId) {
      this.formPrice.nodeId = nodeId;
    } else {
      this.formPrice.nodeId = -1;
    }
    this.openPriceDialog();
  }

  public editPrice(price: Price) {
    this.dialogPriceTitle = "Preis bearbeiten";
    this.formPrice = price;
    this.openPriceDialog();
  }

  public openPriceDialog() {
    this.dialogRefPrice = this.dialogService.open(this.dialogTemplatePrice);
  }

  public closePriceDialog() {
    this.dialogRefPrice.close();
  }

  public savePrice() {
    this.priceService.updatePrice(this.formPrice).subscribe(x => {
      this.closePriceDialog();
      this.load();
    });
  }

  public formatDate(date: Date): string {
    return date.toLocaleDateString("de-DE");
  }
}
