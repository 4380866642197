export class UserModel {
  userId?: number;
  nodes: number[] = [];
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  activated: boolean;
  refreshtoken?: string;
  editing?: boolean = false;
}
