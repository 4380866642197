<nb-card>
    <nb-card-header>Berechtigungen verwalten <span class="float-end" style="color: red">Bearbeitung nur durch einen Entwickler!</span></nb-card-header>
    <nb-card-body>
        <nb-tabset (changeTab)="changeTab($event)">
            <nb-tab tabTitle="Berechtigungen">
                <div class="row">
                    <div class="col-11">
                        <nb-select #permissionSelect placeholder="Berechtigung wählen" (selectedChange)="onSelectPermission($event)" fullWidth>
                            <nb-option *ngFor="let permission of permissions" [value]="permission.permissionId">
                                {{permission.key}}
                            </nb-option>
                        </nb-select>
                    </div>
                    <div class="col-1">
                        <button nbButton outline (click)="prepareNewPermission()" status="primary" class="float-end" fullWidth>Neu</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Key</label>
                        <input type="text" nbInput placeholder="Key" [(ngModel)]="selectedPermission.key" fullWidth>
                    </div>
                    <div class="col">
                        <label>Name</label>
                        <input type="text" nbInput placeholder="Name" [(ngModel)]="selectedPermission.name" fullWidth>
                    </div>
                    <div class="col">
                        <label>Typ</label>
                        <nb-select placeholder="Typ wählen" [(ngModel)]="selectedPermission.typeEnum" fullWidth [(selected)]="selectedPermission.typeEnum">
                            <nb-option *ngFor="let permissionType of permissionTypes" [value]="permissionType.id">
                                {{getPermissionTypeName(permissionType.id)}}
                            </nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Beschreibung</label>
                        <textarea type="text" nbInput placeholder="Beschreibung" [(ngModel)]="selectedPermission.description" fullWidth></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Controller</label>
                        <input type="text" nbInput placeholder="Controller" [(ngModel)]="selectedPermission.controller" fullWidth>
                    </div>
                    <div class="col">
                        <label>Action</label>
                        <input type="text" nbInput placeholder="Action" [(ngModel)]="selectedPermission.action" fullWidth>
                    </div>
                    <div class="col">
                        <label>PageId</label>
                        <input type="text" nbInput placeholder="PageId" [(ngModel)]="selectedPermission.pageId" fullWidth>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Zuweisung zu Berechtigungsgruppen</label>
                        <nb-select multiple placeholder="Berechtigungsgruppen wählen" [(selected)]="selectedPermission.assignedPermissionsGroups" fullWidth>
                            <nb-option *ngFor="let permissionGroup of permissionGroups" [value]="permissionGroup.permissionGroupId">
                                {{permissionGroup.name}}
                            </nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <button nbButton outline (click)="deletePermission()" class="float-start" status="danger">Löschen</button>
                        <button nbButton outline (click)="savePermission()" class="float-end" status="primary">Speichern</button>
                    </div>
                </div>
            </nb-tab>
            <nb-tab tabTitle="Berechtigungsgruppen">
                <div class="row">
                    <div class="col-11">
                        <nb-select #permissionGroupSelect placeholder="Gruppe wählen" (selectedChange)="onSelectPermissionGroup($event)" fullWidth>
                            <nb-option *ngFor="let permissionGroup of permissionGroups" [value]="permissionGroup.permissionGroupId">
                                {{permissionGroup.name}}
                            </nb-option>
                        </nb-select>
                    </div>
                    <div class="col-1">
                        <button nbButton outline (click)="prepareNewPermissionGroup()" status="primary" class="float-end" fullWidth>Neu</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Name</label>
                        <input type="text" nbInput placeholder="Name" [(ngModel)]="selectedPermissionGroup.name" fullWidth>
                    </div>
                    <div class="col">
                        <label>Typ</label>
                        <nb-select placeholder="Typ wählen" [(ngModel)]="selectedPermissionGroup.type" fullWidth [(selected)]="selectedPermissionGroup.type">
                            <nb-option *ngFor="let permissionType of permissionTypes" [value]="permissionType.id">
                                {{getPermissionTypeName(permissionType.id)}}
                            </nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Beschreibung</label>
                        <textarea type="text" nbInput placeholder="Beschreibung" [(ngModel)]="selectedPermissionGroup.description" fullWidth></textarea>
                    </div>
                </div>
                <!-- <div class="row mt-2">
                    <div class="col">
                        <span class="float-end">Klicke hier um die neue Gruppe zu erstellen!</span>
                    </div>
                </div> -->
                <div class="row mt-2">
                    <div class="col">
                        <button nbButton outline (click)="deletePermissionGroup()" class="float-start" status="danger">Löschen</button>
                        <button nbButton outline (click)="savePermissionGroup()" class="float-end" status="primary">Speichern</button>
                    </div>
                </div>
            </nb-tab>
        </nb-tabset>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton outline (click)="closeComponent()" class="float-end">Schließen</button>
        <!-- <button nbButton status="primary" (click)="editUserGroupConfirmed()" class="float-end">Speichern</button> -->
    </nb-card-footer>
</nb-card>