import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadServiceService {

  constructor(private http: HttpClient) { }

  public getYearStatsPdf(nodeId: number, year: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(`/api/stats/yearpdf?nodeId=${nodeId}&year=${year}`, httpOptions);
  }

  public getMonthStatsPdf(nodeId: number = 0, offSet: number = -1, month: number = -1, year: number = -1) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(`/api/stats/monthpdf?nodeId=${nodeId}&monthOffset=${offSet}&month=${month}&year=${year}`, httpOptions);
  }
}
