import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthenticationService, private router: Router) { }

  canActivate(): boolean {
    if (this.isEarthHour()) {
      this.router.navigate(['earthhour']);
    }

    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }

  isEarthHour(): boolean {
    let now = new Date();
    const stunden = now.getHours();
    const minuten = now.getMinutes();

    if (now.getDate() == 23 && now.getMonth() == 2 && (stunden == 20 && minuten >= 30 || stunden == 21 && minuten <= 30)) {
      return true;
    }

    return false;
  }

}
