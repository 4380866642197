import { ProtColumn } from './../models/ProtColumn';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimeRange } from '../models/TimeRange';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(private http: HttpClient) { }

  public getLastStats(nodeId: number = 0) {
    if (nodeId == 0) {
      return this.http.get('/api/stats/last');
    } else {
      return this.http.get('/api/stats/last?nodeId=' + nodeId);
    }
  }

  public getYearStats(nodeId: number = 0, year: number) {
    return this.http.get('/api/stats/year?nodeId=' + nodeId + '&year=' + year);
  }

  public getMonthStats(nodeId: number = 0, offSet: number = -1, month: number = -1, year: number = -1) {
    return this.http.get('/api/stats/month?nodeId=' + nodeId + "&monthOffset=" + offSet + "&month=" + month + "&year=" + year);
  }

  public getDayConsumptions(nodeId: number = 0, offSet: number = -1, month: number = -1, year: number = -1) {
    return this.http.get('/api/stats/month/days?nodeId=' + nodeId + "&monthOffset=" + offSet + "&month=" + month + "&year=" + year);
  }

  public getHeizomatOverviewStats() {
    return this.http.get('/api/stats/last/heatstation');
  }

  public getEfficiency() {
    return this.http.get('/api/stats/efficiency');
  }

  public getNodeSumStats() {
    return this.http.get('/api/stats/nodesumstats');
  }

  public getStats(range: TimeRange, nodeId: number = 0, startDate: Date = null, endDate: Date = null) {
    if (range == TimeRange.Custom) {
      return this.http.get('/api/stats?timeRange=' + range + '&nodeId=' + nodeId + '&startdate=' + this.formatDate(startDate) + '&endDate=' + this.formatDate(endDate));
    }

    if (nodeId == 0) {
      return this.http.get('/api/stats?timeRange=' + range);
    } else {
      return this.http.get('/api/stats?timeRange=' + range + '&nodeId=' + nodeId);
    }
  }

  private formatDate(date: Date): string {
    return date.toLocaleDateString("de-DE") + " " + date.toLocaleTimeString("de-DE");
  }

  public getStatsById(range: TimeRange, id: number) {
    return this.http.get<Node[]>('/api/stats?timeRange=' + range + "&`?id=" + id);
  }

  public getStatColumns(id: number) {
    if (!id) {
      return this.http.get<ProtColumn[]>('/api/stats/columns');
    } else {
      return this.http.get<ProtColumn[]>('/api/stats/columns?nodeId=' + id);
    }
  }

  public getStatsInformation(nodeId: number = 0) {
    if (nodeId == 0) {
      return this.http.get('/api/stats/info', {responseType: 'text'});
    } else {
      return this.http.get('/api/stats/info?nodeId=' + nodeId, {responseType: 'text'});
    }
  }
}
