<nb-card class="mt-2">
    <nb-card-body>
        <div class="row">
            <div class="col-md accent">
                <div class="h-100 d-flex align-items-center justify-content-center">
                    <img src="../../../assets/images/logocenterwhite.svg">
                </div>
            </div>
            <div class="col-md login" [nbSpinner]="loading">
                <h3 class="text-center">Anmelden</h3>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)" class="mt-4">
                    <div class="form-group mb-3">
                        <nb-form-field>
                            <nb-icon nbPrefix icon="person" pack="eva"></nb-icon>
                            <input nbInput id="username" formControlName="username" placeholder="Benutzername" fullWidth>
                        </nb-form-field>
                    </div>
                    <div class="form-group mb-1">
                        <nb-form-field>
                            <nb-icon nbPrefix icon="unlock-outline" pack="eva"></nb-icon>
                            <input nbInput id="password" type="password" formControlName="password" placeholder="Passwort" fullWidth>
                        </nb-form-field>
                    </div>
                    <div class="text-end mb-2">
                        <a href="https://nahwaerme-amerbach.de/index.php/component/users/reset" style="color: rgb(90, 83, 83); text-decoration: none;">Passwort vergessen?</a>
                    </div>
                    <div>
                        <button nbButton type="submit" (click)="toggleLoading()" class="login-button mt-4" status="primary" fullWidth>Login</button>
                    </div>
                </form>
                <div class="separation">
                    <div class="hr"></div>
                    <span>ODER</span>
                </div>
                <button nbButton (click)="loginWithWebAuthn()" fullWidth><i class="fas fa-fingerprint me-2"></i> PassKey</button>
            </div>
        </div>
    </nb-card-body>
</nb-card>