import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  aiSidebarOpened : EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  public setAiSiteBarOpened(opened: boolean) {
    this.aiSidebarOpened.emit(opened);
  }

  public getAiSiteBarStateEmitter() {
    return this.aiSidebarOpened;
  }
}
