import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'nwa-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.less']
})
export class BillComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

}
