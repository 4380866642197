import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitoringDetails } from '../../../models/MonitoringDetails';
import { MonitoringFailureDetails } from '../../../models/MonitoringFailureDetails';
import { ProtocolChartConfig } from '../../../models/ProtocolChartConfig';
import { TimeRange } from '../../../models/TimeRange';
import { ChartService } from '../../../services/chart.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { MonitoringType } from 'src/app/models/Monitoring';
import {Location} from '@angular/common';

@Component({
  selector: 'nwa-monitoringdetails',
  templateUrl: './monitoringdetails.component.html',
  styleUrls: ['./monitoringdetails.component.less']
})
export class MonitoringdetailsComponent implements OnInit {
  @ViewChild('chart') chart;
  @ViewChild('chartWrapper') chartWrapper;
  monitoringFailureId: number;
  monitoringDetails: MonitoringDetails;
  monitoringFailure: MonitoringFailureDetails;
  loadingChart: boolean = true;
  showChart: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private monitoringService: MonitoringService,
    private chartService: ChartService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
        // Parameter auslesen
        this.activatedRoute.queryParams.subscribe(params => {
          this.monitoringFailureId = params.id;
          this.loadData(this.monitoringFailureId);
        });
  }

  goBack() : void {
    this.location.back();
    console.log('back');
  }

  loadData(monitoringFailureId: number) {
    this.monitoringService.getMonitoringFailureDetailsById(monitoringFailureId).subscribe(res => {
      if (res) {
        this.monitoringDetails = res;
        this.monitoringFailure = res.monitoringFailureDetails[0];
        if (this.monitoringFailure.monitoring.monitoringType == MonitoringType.Treshold) {
          this.showChart = true;
          this.initChart();
        } else {
          this.showChart = false;
        }
      }
    })
  }

  initChart() {
    let chartConfig = new ProtocolChartConfig();
    chartConfig.nodeId = this.monitoringFailure.node.nodeId;
    chartConfig.range = TimeRange.Custom;
    chartConfig.startDate = this.addHours(new Date(this.monitoringFailure.monitoringFailure.startTime), -5);
    chartConfig.endDate = this.addHours(new Date(this.monitoringFailure.monitoringFailure.endTime), 5);
    chartConfig.activeColumns = this.monitoringFailure.protColumns;
    chartConfig.xAxisAnnotations = [this.chartService.getNewMonitoringAnnotation(new Date(this.monitoringFailure.monitoringFailure.startTime), new Date(this.monitoringFailure.monitoringFailure.endTime))];
    
    chartConfig.height = (this.chartWrapper.nativeElement.offsetHeight - 5).toString();

    this.chart.loadData(chartConfig).subscribe(x => this.loadingChart = false);
  }

  public setMonitoringFailureInspected(close: boolean = false) {
    this.monitoringService.inspectFailure(this.monitoringFailure).subscribe(x => {
      this.monitoringFailure.monitoringFailure.inspected = true;
      if (close) {
        this.router.navigate(["monitoring"]);
      }
    })
  }


  private addHours(date, hours) {
    date.setHours(date.getHours() + hours);
  
    return date;
  }
}
