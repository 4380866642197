import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unit'
})
export class UnitPipe implements PipeTransform {
  transform(value: number, length: number, unit?: string): unknown {
    let displayValue = (Math.round(value * Math.pow(10, length)) / Math.pow(10, length));

    let formattedNumber = formatNumber(displayValue, 'de-DE', '1.0-' + length);

    if (unit) {
      return formattedNumber + unit;
    }

    return formattedNumber;
  }

}
