<h5>Heizleistung</h5>
<span class="subtitle">Pflege der vertraglichen Heizleistung</span>
<nb-card class="mt-3">
    <nb-card-body>
        <nwa-pricefilter [(priceFilter)]="filter" (priceFilterChange)="load()"></nwa-pricefilter>
        <table class="table">
            <thead>
                <th>Anschluss</th>
                <th>Jahr</th>
                <th>Wärmemenge</th>
                <th>Wärmeleistung</th>
            </thead>
            <tbody>
                <tr *ngIf="!records?.length" style="text-align: center;">
                    <td style="border: none" colspan="4">
                        <span style="font-weight: 600;">Für den aktuellen Filter sind keine Datensätze vorhanden...</span>
                    </td>
                </tr>
                <tr *ngFor="let record of records" appContextmenu [items]="getMenuItems(record.id)" (clicked)="onContextClicked($event)" (dblclick)="editPrice(price)">
                    <td>
                        <span class="align-middle">{{record.nodeOwner}}</span>
                    </td>
                    <td>{{record.year}}</td>
                    <td>{{record.amountOfHeat + ' kWh'}}</td>
                    <td>{{record.heatPower + ' kW'}}</td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: none;" colspan="4" (click)="createAmountOfHeat()">
                        <nb-icon icon="plus-outline"></nb-icon>
                    </td>
                </tr>
            </tbody>
        </table>
    </nb-card-body>
</nb-card>

<ng-template #dialogAmountOfHeat let-data let-ref="dialogRefAmountOfHeat">
    <nb-card style="min-width: 300px;">
        <nb-card-header>{{dialogAmountOfHeatTitle}}</nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col">
                    <label>Anschluss</label>
                    <nb-select placeholder="Anschluss wählen" [(selected)]="formAmountOfHeat.nodeId" fullWidth>
                        <nb-option *ngFor="let node of nodes" [value]="node.nodeId">
                            <ng-container *ngIf="node.nodeId != -1">
                                {{node.nodeId + " " + node.ownerFirstName + " " + node.ownerLastName}}
                            </ng-container>
                            <ng-container *ngIf="node.nodeId == -1">
                                <span class="strong">{{node.ownerFirstName}}</span>
                            </ng-container>
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label>Jahr</label>
                    <input type="number" nbInput placeholder="Jahr" [fullWidth]="true" [ngModel]="formAmountOfHeat.year" (change)="formAmountOfHeat.year = $event.target.value" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label>Wärmemenge</label>
                    <input type="number" nbInput placeholder="Wärmemenge" [fullWidth]="true" [ngModel]="formAmountOfHeat.amountOfHeat | unit: 2" (change)="formAmountOfHeat.amountOfHeat = $event.target.value" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label>Wärmeleistung</label>
                    <input type="number" nbInput placeholder="Wärmeleistung" [fullWidth]="true" [ngModel]="formAmountOfHeat.heatPower | unit: 2" (change)="formAmountOfHeat.heatPower = $event.target.value" />
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="dialogRefAmountOfHeat.close()" class="float-start">Abbrechen</button>
            <button nbButton status="primary" (click)="saveAmountOfHeat()" class="float-end">Speichern</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogDeleteAmountOfHeat let-data let-ref="dialogDeleteAmountOfHeat">
    <nb-card style="max-width: 350px;">
        <nb-card-header>Datensatz löschen</nb-card-header>
        <nb-card-body>
            <span>Ein Löschvorgang wirkt sich unmittelbar auf zukünftige Rechnungen aus.</span>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="dialogRefDeleteAmountOfHeat.close()" class="float-start">Abbrechen</button>
            <button nbButton status="danger" (click)="deleteSelectedPriceConfirmed()" class="float-end">Löschen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>