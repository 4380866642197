import { Component, Input, OnInit } from '@angular/core';
import { MonitoringTreshold } from '../../models/MonitoringTreshold';

@Component({
  selector: 'nwa-monitoringtresholds',
  templateUrl: './monitoringtresholds.component.html',
  styleUrls: ['./monitoringtresholds.component.less']
})
export class MonitoringtresholdsComponent implements OnInit {
  @Input() monitoringTresholds: MonitoringTreshold[];
  myMath = Math;

  constructor() { }

  ngOnInit(): void {
  }

}
