import { Component } from '@angular/core';

@Component({
  selector: 'nwa-worldearthhour',
  templateUrl: './worldearthhour.component.html',
  styleUrl: './worldearthhour.component.less'
})
export class WorldearthhourComponent {

}
