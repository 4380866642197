<h5>Anschlüsse</h5>
<span class="subtitle">Pflege der Hausanschlüsse.</span>
<nb-card class="mt-3">
    <nb-card-body [nbSpinner]="loading">
        <!-- LOADING -->
        <ng-container *ngIf="loading then: loadList"></ng-container>
        <!-- CONTENT -->
        <div class="list" *ngIf="!loading">
            <div class="row header-row">
                <div class="col-2">Anschluss Nummer</div>
                <div class="col-4">Straße</div>
                <div class="col-4">Hausnummer</div>
                <div class="col-2"></div>
            </div>
            <hr style="height: 2px;">
            <ng-container *ngFor="let node of nodes; let i = index">
                <ng-container *ngIf="i != 0">
                    <hr>
                </ng-container>
                <div class="row user-row">
                    <ng-container *ngIf="!node.editing">
                        <div class="col-2 align-self-center">{{node.nodeId}}</div>
                        <div class="col-4 align-self-center">{{node.street}}</div>
                        <div class="col-4 align-self-center">{{node.housenumber}}</div>
                        <div class="col-2 align-self-center">
                            <div class="row">
                                <div class="col">
                                    <button nbButton status="control" class="action-button" (click)="toggleEditMode(node)"><nb-icon icon="edit-outline"></nb-icon></button>
                                    <button nbButton status="control" class="action-button" (click)="deleteNode(node)"><nb-icon icon="trash-outline"></nb-icon></button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Node Edit -->
                    <ng-container *ngIf="node.editing">
                        <div class="col-2 align-self-center">
                            <input nbInput [(ngModel)]="node.nodeId" placeholder="AnschlussNr" type="number" fieldSize="small">
                        </div>
                        <div class="col-4 align-self-center">
                            <input nbInput [(ngModel)]="node.street" placeholder="Straße" fieldSize="small">
                        </div>
                        <div class="col-4 align-self-center">
                            <input nbInput [(ngModel)]="node.housenumber" placeholder="Hausnummer" fieldSize="small">
                        </div>
                        <div class="col-2 align-self-center">
                            <div class="row">
                                <div class="col">
                                    <button nbButton (click)="updateNode(node)"><i class="fas fa-chevron-right fa-xs"></i></button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <!-- Add Node -->
            <hr>
            <div class="row user-row">
                <div class="col-2 align-self-center">
                    <input nbInput [(ngModel)]="nodeToAdd.nodeId" placeholder="AnschlussNr" type="number" fieldSize="small">
                </div>
                <div class="col-4 align-self-center">
                    <input nbInput [(ngModel)]="nodeToAdd.street" placeholder="Straße" fieldSize="small">
                </div>
                <div class="col-4 align-self-center">
                    <input nbInput [(ngModel)]="nodeToAdd.housenumber" placeholder="Hausnummer" fieldSize="small">
                </div>
                <div class="col-2 align-self-center">
                    <div class="row">
                        <div class="col">
                            <button nbButton (click)="addNode()"><i class="fas fa-chevron-right fa-xs"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>Anschluss "{{nodeToDelete.nodeId}}" löschen</nb-card-header>
        <nb-card-body>Soll der Anschluss wirklich gelöscht werden?</nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="ref.close()" class="float-start">Abbrechen</button>
            <button nbButton status="danger" (click)="deleteNodeConfirmed()" class="float-end">Löschen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #loadList>
    <div class="row">
        <div class="col-2">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </div>
        <div class="col-2">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </div>
    </div>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
</ng-template>