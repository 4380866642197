<div class="manage-monitorings">
    <div class="row mb-3">
        <div class="col">
            <h5 style="display: inline;">Monitorings verwalten</h5>
        </div>
        <div class="col d-flex justify-content-end">
            <button nbButton status="primary" shape="round" (click)="openEditMonitoring()">
                <nb-icon icon="plus"></nb-icon>
            </button>
        </div>
    </div>
    <div id="monitoring-accordion">
        <nb-card *ngFor="let monitoring of monitorings" class="monitoring">
            <nb-card-body>       
                <div class="row">
                    <div class="col d-flex align-items-center">
                        <button status="control" nbButton shape="round" (click)="toggleMonitoringActivation(monitoring)">
                            <nb-icon *ngIf="monitoring.isActive" icon="play-circle-outline" style="color: green;"></nb-icon>
                            <nb-icon *ngIf="!monitoring.isActive" icon="pause-circle-outline" style="color: gray;"></nb-icon>
                        </button>
                        <b class="ms-2">{{monitoring.title}}</b>
                    </div>
                    <div class="col d-flex justify-content-end">
                        <div class="edit-monitoring">
                            <button nbButton status="control" class="action-button" (click)="openEditMonitoring(monitoring)">
                                <nb-icon icon="edit-outline"></nb-icon>
                            </button>
                            <button nbButton status="control" class="action-button" (click)="requestMonitoringDelete(monitoring)" *ngIf="monitoring.monitoringType == 0">
                                <nb-icon icon="trash-outline"></nb-icon>
                            </button>
                            <button nbButton status="control" class="action-button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + monitoring.monitoringId">
                                <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="collapse-{{monitoring.monitoringId}}" class="accordion-collapse collapse" data-bs-parent="#monitoring-accordion">
                    <br><div class="description">{{monitoring.description}}</div><br><br>
                    <div class="row mb-2">
                        <div class="col">
                            Aktive Anschlüsse: {{monitoring.monitoringNodes | arrayjoin}}
                        </div>
                        <div class="col">
                            Mindestdauer: {{monitoring.timeRange}}min
                        </div>
                        <div class="col">
                            Trennzeit: {{monitoring.failureSeparationTime}}min
                        </div>
                    </div>
                    <ng-container *ngIf="monitoring.monitoringType == 0">
                        <b>Bedingungen</b>
                        <nwa-monitoringtresholds [monitoringTresholds]="monitoring.monitoringTresholds"></nwa-monitoringtresholds>
                    </ng-container>
                </div>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="no-monitorings d-flex flex-column align-items-center text-center" *ngIf="!loading && !hasMonitorings()">
        <nb-icon icon="shield-outline" style="font-size: 25vh" class="mt-5"></nb-icon>
        <h1>Keine Monitorings vorhanden</h1>
    </div>
</div>

<ng-template #dialogRefEditMonitoring let-data let-ref="dialogRef">
    <nwa-monitoringform (formClosed)="abortMonitoringEdit()" [monitoring]="editingMonitoring" (formSubmitted)="updateMonitoring($event)"></nwa-monitoringform>
</ng-template>

<ng-template #dialogDeleteMonitoring let-data let-refDelete="dialogRef">
    <nb-card style="max-width: 350px;">
        <nb-card-header>Monitoring löschen</nb-card-header>
        <nb-card-body>
            <span>Beim Löschvorgang werden alle zum Monitoring gehörenden Daten gelöscht.</span>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="refDelete.close()" class="float-start">Abbrechen</button>
            <button nbButton status="danger" (click)="deleteMonitoring(monitoringToDelete)" class="float-end">Löschen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>