<div class="monitoring-failures">
    <div class="row mb-3">
        <div class="col">
            <h5 style="display: inline;">Monitoring</h5>
        </div>
        <div class="col d-flex justify-content-end">
            <button nbButton [status]="monitoringDetails.mailNotificationActivated ? 'basic' : 'primary'" *ngIf="!loading" size="small" shape="round" (click)="toggleMailNotification()">
                <nb-icon icon="email-outline"></nb-icon> {{mailButtonText}}
            </button>
        </div>
    </div>
    <div class="monitoring-failure-details">
        <nb-card *ngFor="let monitoringDetail of monitoringFailures">
            <nb-card-body>
                <div class="row">
                    <div class="col d-flex align-items-center">
                        <nb-icon icon="alert-triangle-outline" style="color: red;"></nb-icon>
                        <b class="ms-2">{{monitoringDetail.monitoring.title}}</b>
                    </div>
                    <div class="col d-flex align-items-center">
                        <nb-icon icon="home-outline"></nb-icon>
                        <b class="ms-2">{{monitoringDetail.node.ownerFirstName}} {{monitoringDetail.node.ownerLastName}}, {{monitoringDetail.node.street}} {{monitoringDetail.node.housenumber}}</b>
                    </div>
                    <div class="col d-flex align-items-center">
                        <nb-icon icon="clock-outline"></nb-icon>
                        <b class="ms-2">{{monitoringDetail.monitoringFailure.startTime | date: 'dd.MM.YY HH:mm'}} - {{monitoringDetail.monitoringFailure.endTime | date: 'dd.MM.YY HH:mm'}}</b>
                    </div>
                    <div class="col d-flex justify-content-end">
                        <div class="details-monitoring">
                            <!-- 337: Removed Monitoring deletion button -->
                            <!-- <button nbButton status="control" (click)="requestMonitoringFailureDelete(monitoringDetail)">
                                <nb-icon icon="trash-outline" status="danger"></nb-icon>
                            </button> -->
                            <button nbButton status="control" (click)="setMonitoringFailureInspected(monitoringDetail)">
                                <nb-icon icon="checkmark-circle-outline" status="success"></nb-icon>
                            </button>
                            <button nbButton status="control" (click)="routeTo(monitoringDetail)">
                                <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="no-monitorings d-flex flex-column align-items-center text-center" *ngIf="!loading && !hasFailures()">
        <nb-icon icon="checkmark-circle-outline" style="font-size: 20vh" class="mt-5"></nb-icon>
        <h1>Aktuell sind keine Fehlermeldungen vorhanden</h1>
    </div>
</div>

<ng-template #dialogDeleteMonitoringFailure let-data let-ref="dialogRef">
    <nb-card style="max-width: 350px;">
        <nb-card-header>Fehlermeldung löschen</nb-card-header>
        <nb-card-body>
            <span>Bei der Löschung der Fehlermeldung werden die hinterlegten Fehlerzeiträume gelöscht.</span>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="ref.close()" class="float-start">Abbrechen</button>
            <button nbButton status="danger" (click)="deleteMonitoringFailure(monitoringFailureToDelete)" class="float-end">Löschen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>