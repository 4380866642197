<div class="header">
    <h5>Wärmenetz</h5>
    <ng-container *ngIf="overviewModel != null">
        <span class="subtitle float-end">Stand: {{overviewModel?.fetchTime | date: 'HH:mm:ss'}} Uhr</span>
    </ng-container>
</div>
<nb-card class="mt-3" style="min-height: 60vh;" [nbSpinner]="loading || firstLoading">
    <nb-card-body>
        <!-- LOADING -->
        <ng-container *ngIf="firstLoading then: loadList"></ng-container>
        <!-- CONTENT -->
        <table class="table" *ngIf="!firstLoading">
            <thead>
                <th></th>
                <th>Nr</th>
                <th>Abnehmer</th>
                <!-- <th>Adresse</th> -->
                <th>WZ-VL</th>
                <th>WZ-RL</th>
                <!-- <th>WZ-Spreizung</th> -->
                <th>Ventil</th>
                <th>Leistung</th>
                <!-- <th>Volumenbedarf</th> -->
                <th>Lädt</th>
                <th>HZ</th>
                <th>Volumenfluss</th>
                <th>Speicher Oben</th>
                <th>Speicher Unten</th>
                <th>Min. Oben</th>
                <th>Max. Unten</th>
            </thead>
            <tbody>
                <tr class="node-row" *ngFor="let record of overviewModel?.records" appContextmenu [items]="getMenuItems(record)" useLeftClick="true" [active]="hasDetailsPermission">
                    <td style="text-align: center;">
                        <ng-container *ngIf="!record.hasFailure">
                            <i *ngIf="record.isActive" class="fas fa-check fa-lg" style="color: green;"></i>
                            <i *ngIf="!record.isActive" class="fas fa-times fa-lg" style="color: red;"></i>
                        </ng-container>
                        <nb-icon *ngIf="record.hasFailure" icon="alert-triangle-outline" class="me-auto ms-auto" style="color: red;" nbTooltip="Bei diesem Anschluss ist ein Fehler aufgetreten. Details siehe in den Monitorings." nbTooltipStatus="primary"></nb-icon>
                    </td>
                    <td>{{record.nodeId}}</td>
                    <td>{{record.ownerName}}</td>
                    <!-- <td>{{record.address}}</td> -->
                    <td>{{record.wzVorlauf | unit: 1 : ' °C'}}</td>
                    <td>{{record.wzRuecklauf | unit: 1 : ' °C'}}</td>
                    <!-- <td>{{record.wzSpreizung | unit: 1 : ' °C'}}</td> -->
                    <td style="position: relative; min-width: 90px;">
                        <ng-container *ngIf="record.hasVentil">
                            <span class="progress-title">{{record.ventil | unit: 0 : '%'}}</span>
                            <nb-progress-bar [value]="record.ventil" [status]="status(record.ventil)" [displayValue]="false">
                            </nb-progress-bar>
                        </ng-container>
                    </td>
                    <td style="position: relative; min-width: 90px;">
                        <span class="progress-title">{{record.leistung | unit: 1 : 'kW'}}</span>
                        <nb-progress-bar *ngIf="record.leistung > 0" [value]="(record.leistung / record.contractualHeatCapacity) * 100" [status]="statusPower(record.leistung, record.contractualHeatCapacity)" [displayValue]="false">
                        </nb-progress-bar>
                    </td>
                    <!-- <td>{{record.volumenbedarf | unit: 1 : ' m³/MWh'}}</td> -->
                    <td>
                        <div *ngIf="record.ladevorgangAktiv; then laedt"></div>
                        <ng-template #laedt>
                            <i class="fas fa-faucet fa-lg" style="color: red;"></i>
                        </ng-template>
                    </td>
                    <td>
                        <div *ngIf="record.heizkreisAktiv; then hkaktiv"></div>
                        <ng-template #hkaktiv>
                            <i class="fas fa-burn fa-lg" style="color: orange;"></i>
                        </ng-template>
                    </td>
                    <td>{{record.volumenfluss | unit: 1 : ' l/h'}}</td>
                    <td>
                        <ng-container *ngIf="record.nodeId != 2220">
                            {{record.speicherOben | unit: 1 : ' °C'}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="record.nodeId != 2220">
                            {{record.speicherUnten | unit: 1 : ' °C'}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="record.nodeId != 220 && record.nodeId != 2220">
                            {{record.pufferEinOben | unit: 1 : ' °C'}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="record.nodeId != 220 && record.nodeId != 2220">
                            {{record.pufferAusUnten | unit: 1 : ' °C'}}
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </nb-card-body>
</nb-card>

<ng-template #loadList>
    <table class="table">
        <thead>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
            <th>
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
            </th>
        </thead>
    </table>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
    <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
</ng-template>