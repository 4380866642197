import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { User } from '@root/app/models/User';
import { UserModel } from '@root/app/models/UserModel';
import { UserService } from '@root/app/services/user.service';

@Component({
  selector: 'nwa-userpicker',
  templateUrl: './userpicker.component.html',
  styleUrl: './userpicker.component.less'
})
export class UserpickerComponent implements OnInit {
  @Output() userPicked: EventEmitter<UserModel[]> = new EventEmitter();

  @ViewChild('dialogUserPicker') dialogTemplateUserPicker;

  dialogRefUserPicker;
  users: UserModel[];

  constructor(private dialogService: NbDialogService,
    private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getNonSuperuserUsers().subscribe(x => this.users = x);
  }

  public open(): void {
    this.dialogRefUserPicker = this.dialogService.open(this.dialogTemplateUserPicker);
  }

  public onUserPicked(user: UserModel): void {
    this.close();
    let users : UserModel[] = [user];
 
    this.userPicked.emit(users);
  }

  public chooseAll() {
    this.close();
    this.userPicked.emit(this.users);
  }

  private close() {
    this.dialogRefUserPicker.close();
  }
}
