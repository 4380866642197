<table>
    <tr *ngFor="let treshold of monitoringTresholds">
        <td><nb-tag status="basic" appearance="filled" text="{{treshold.column}}"></nb-tag></td>
        <td>
            <div class="operator">
                <ng-container *ngIf="treshold.type == 0">&gt;</ng-container>
                <ng-container *ngIf="treshold.type == 1">&lt;</ng-container>
                <ng-container *ngIf="treshold.type == 2">=</ng-container>
                <ng-container *ngIf="treshold.type == 3">≥</ng-container>
                <ng-container *ngIf="treshold.type == 4">≤</ng-container>
            </div>
        </td>
        <td><nb-tag status="basic" appearance="filled" text="{{treshold.compareWithColumn ? treshold.columnToCompare : treshold.value}}"></nb-tag>
        <ng-container *ngIf="treshold.offset">
            <nb-icon *ngIf="treshold.offset >= 0" icon="plus-circle-outline" class="ms-1"></nb-icon>
            <nb-icon *ngIf="treshold.offset < 0" icon="minus-circle-outline" class="ms-1"></nb-icon>
            <nb-tag status="basic" class="ms-1" appearance="filled" text="{{myMath.abs(treshold.offset)}}"></nb-tag>
        </ng-container></td>
    </tr>
</table>