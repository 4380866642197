import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, tap, timeout } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService,
    private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const idToken = localStorage.getItem('userInformation');

    if (!!idToken) {
      var obj = JSON.parse(idToken);
      if (obj) {
        var token = obj.token;
      }
    }

    if (token) {
      const cloned = request.clone({
        headers: request.headers.set('Authorization',
          'Bearer ' + token)
      });
      return this.interceptMessages(next.handle(cloned));
    }
    return next.handle(request);
  }

  /**
   *
   * @param httpHandler Erzeugt automatisch ToastR Messages anhand der Messages aus der Api
   * @returns
   */
  interceptMessages(httpHandler) {
    return httpHandler.pipe(
      timeout(10000),
      tap(evt => {

        if (evt instanceof HttpResponse) {
          if (evt.body != null && evt.body.message != null) {
            this.toastr.success(evt.body.message, evt.body.title);
          }
        }
      }),
      catchError((err : any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error.message != null) {
            this.toastr.error(err.error.message, err.error.title);
          } else {
            // Weiterleitung zu Wartungsseite
            this.router.navigate(["/error"]);
          }
        }
        return of(err);
      })
    );
  }
}


// } else if (err.error.message == 'wartung') {
//   // Weiterleitung zu Wartungsseite
//   this.router.navigate(["/error"]);
// } else {
//   this.toastr.error('Es ist ein Fehler aufgetreten. Bitte Seite neu laden', 'Fehler');
// }
