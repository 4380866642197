import { UserActivationRequestModel } from './../models/UserActivationRequestModel';
import { UserModel } from './../models/UserModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  public getNonSuperuserUsers() {
    return this.http.get<UserModel[]>('/api/user?listSuperUser=false');
  }

  public getUsers() {
    return this.http.get<UserModel[]>('/api/user');
  }

  public addUser(user: UserModel) {
    return this.http.post('/api/user', user);
  }

  public changeUserActivation(user: UserActivationRequestModel) {
    return this.http.put('/api/user/activate', user);
  }

  public deleteUser(user: UserModel) {
    return this.http.delete('/api/user/' + user.userId);
  }

  public updateUser(user: UserModel) {
    return this.http.put('/api/user', user);
  }

  public isAnonymousMode() {
    return (this.authenticationService.getJwtValue('Anonymous') == 'true');
  }

  public changeAnonymousMode() {
    if (this.authenticationService.getJwtValue('Anonymous') == 'true') {
      return this.http.post('/api/user/anonymousMode', {
        anonymousMode: false,
      });
    }
    return this.http.post('/api/user/anonymousMode', { anonymousMode: true });
  }
}
