<nb-card>
    <nb-card-body class="d-flex flex-column justify-content-center">
        <div class="mb-auto title-header row">
            <div class="col-12 col-xxl-4">
                <span class="title">{{title}}</span>
                <span class="subtitle">{{subtitle}}</span>
            </div>
            <div class="col-12 col-xxl-8 d-flex align-items-center mt-2 mt-md-0 just justify-content-center justify-content-md-start">
                <nb-tabset #dataTabset (changeTab)="switchData($event)">
                    <nb-tab tabTitle="Verbrauch"></nb-tab>
                    <nb-tab tabTitle="Temperatur" *ngIf="hasTemperature"></nb-tab>
                    <nb-tab tabTitle="Effizienz" *ngIf="hasEfficiency"></nb-tab>
                </nb-tabset>
                <!-- <div *ngIf="showToggle">
                    <nb-toggle [(checked)]="toggleChecked" (checkedChange)="onTemperatureCheckedChange($event)">Temperatur</nb-toggle>
                </div> -->
            </div>
        </div>

        <div>
            <apx-chart [nbTooltipDisabled]="!chartConfig?.showClickHint" nbTooltip="Ein Klick auf einen Datensatz öffnet die Monatsstatistik" nbTooltipPlacement="left" nbTooltipStatus="info" #chart [series]="chartOptions.series" [tooltip]="chartOptions.tooltip" [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"></apx-chart>
        </div>

        <div class="icon d-none d-sm-block">
            <nb-icon icon="activity-outline"></nb-icon>
        </div>
    </nb-card-body>
</nb-card>