<nwa-pricefilter [(priceFilter)]="filter" (priceFilterChange)="load()"></nwa-pricefilter>
<table class="table">
    <thead>
        <th>Anschluss</th>
        <th>Wert</th>
        <th>Gültig von</th>
        <th>Gültig bis</th>
    </thead>
    <tbody>
        <tr *ngIf="!prices?.length" style="text-align: center;">
            <td style="border: none" colspan="4">
                <span style="font-weight: 600;">Für den aktuellen Filter sind keine Preise vorhanden...</span>
            </td>
        </tr>
        <tr *ngFor="let price of prices" [class.main-price]="price.isRegularPrice" appContextmenu [items]="getMenuItems(price.id)" (clicked)="onContextClicked($event)" (dblclick)="editPrice(price)">
            <td>
                <span [class.strong]="price.isRegularPrice" class="align-middle">{{price.isRegularPrice ? 'Stammpreis' :
          price.nodeOwner}}</span>
                <nb-icon *ngIf="price.isRegularPrice" icon="info" status="info" nbTooltip="Der Stammpreis gilt, wenn kein Anschlussspezifischer Preis hinterlegt wurde." nbTooltipStatus="primary" class="ms-1 align-middle"></nb-icon>
            </td>
            <td>{{price.price + " " + unitString}}</td>
            <td>{{price.validFrom | date: 'dd.MM.yyyy'}}</td>
            <td>{{price.validTo | date: 'dd.MM.yyyy'}}</td>
        </tr>
        <tr style="text-align: center;">
            <td style="border: none;" colspan="4" (click)="createNewPrice()">
                <nb-icon icon="plus-outline"></nb-icon>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #dialogPrice let-data let-ref="dialogRefPrice">
    <nb-card style="min-width: 300px;">
        <nb-card-header>{{dialogPriceTitle}}</nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col">
                    <label>Anschluss</label>
                    <nb-select placeholder="Anschlüsse" [(selected)]="formPrice.nodeId" fullWidth>
                        <nb-option *ngFor="let node of nodes" [value]="node.nodeId">
                            <ng-container *ngIf="node.nodeId != -1">
                                {{node.nodeId + " " + node.ownerFirstName + " " + node.ownerLastName}}
                            </ng-container>
                            <ng-container *ngIf="node.nodeId == -1">
                                <span class="strong">{{node.ownerFirstName}}</span>
                            </ng-container>
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label>Wert</label>
                    <input type="number" nbInput placeholder="Wert" [fullWidth]="true" [ngModel]="formPrice.price" (change)="formPrice.price = $event.target.value" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label>Gültig von</label><br>
                    <nb-form-field>
                        <input nbInput [nbDatepicker]="datepickerStart" [(ngModel)]="formPrice.validFrom">
                        <button nbSuffix nbButton ghost (click)="formPrice.validFrom = null">
                         <nb-icon icon="close-outline" pack="eva"></nb-icon>
                        </button>
                    </nb-form-field>
                    <nb-datepicker format="dd.MM.yyyy" #datepickerStart></nb-datepicker>
                </div>
                <div class="col">
                    <label>Gültig bis</label><br>
                    <nb-form-field>
                        <input nbInput [nbDatepicker]="datepickerEnd" [(ngModel)]="formPrice.validTo">
                        <button nbSuffix nbButton ghost (click)="formPrice.validTo = null">
                       <nb-icon icon="close-outline" pack="eva"></nb-icon>
                      </button>
                    </nb-form-field>
                    <nb-datepicker format="dd.MM.yyyy" #datepickerEnd></nb-datepicker>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="closePriceDialog()" class="float-start">Abbrechen</button>
            <button nbButton status="primary" (click)="savePrice()" class="float-end">Speichern</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogDeletePrice let-data let-ref="dialogDeletePrice">
    <nb-card style="max-width: 350px;">
        <nb-card-header>Preis löschen</nb-card-header>
        <nb-card-body>
            <span>Soll der Preis wirklich gelöscht werden? Ein Löschvorgang wirkt sich unmittelbar auf zukünftige Rechnungen aus.</span>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="dialogRefDeletePrice.close()" class="float-start">Abbrechen</button>
            <button nbButton status="danger" (click)="deleteSelectedPriceConfirmed()" class="float-end">Löschen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>