import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { MonitoringDetails } from '../../models/MonitoringDetails';
import { MonitoringFailure } from '../../models/MonitoringFailure';
import { MonitoringFailureDetails } from '../../models/MonitoringFailureDetails';
import { MonitoringService } from '../../services/monitoring.service';

@Component({
  selector: 'nwa-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.less']
})
export class MonitoringComponent implements OnInit {
  @ViewChild('dialogDeleteMonitoringFailure') dialogTemplateDeleteMonitoringFailure;
  monitoringDetails: MonitoringDetails;
  monitoringFailures: MonitoringFailureDetails[];
  monitoringFailureToDelete: MonitoringFailureDetails;
  loading: boolean = true;
  mailButtonText: string;

  dialogRefDeleteMonitoringFailure;

  constructor(
    private monitoringService: MonitoringService,
    private router: Router,
    private toastr: ToastrService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {
    this.loadMonitoringDetails();
  }

  private loadMonitoringDetails() {
    this.loading = true;
    this.monitoringService.getMonitoringDetails().subscribe(res => {
      this.monitoringDetails = res;
      this.monitoringFailures = this.monitoringDetails.monitoringFailureDetails;
      this.updateMailButton();
      this.loading = false;
    })
  }

  public updateMailButton() {
    if (this.monitoringDetails.mailNotificationActivated) {
      this.mailButtonText = "E-Mail Benachrichtigung deaktivieren";
    } else {
      this.mailButtonText = "E-Mail Benachrichtigung aktivieren";
    }
  }

  public routeTo(monitoringDetails: MonitoringFailureDetails) {
    this.router.navigate(["/monitoringdetails"], { queryParams: { id: monitoringDetails.monitoringFailure.monitoringFailureId } });
  }

  public requestMonitoringFailureDelete(monitoringDetails: MonitoringFailureDetails) {
    this.monitoringFailureToDelete = monitoringDetails;
    this.dialogRefDeleteMonitoringFailure = this.dialogService.open(this.dialogTemplateDeleteMonitoringFailure);
  }

  public deleteMonitoringFailure(monitoringDetails: MonitoringFailureDetails) {
    this.monitoringService.deleteMonitoringFailureDetails(monitoringDetails).subscribe(() => {
      this.dialogRefDeleteMonitoringFailure.close();
      this.loadMonitoringDetails();
    })
  }

  public toggleMailNotification() {
    this.monitoringService.updateMailNotification(!this.monitoringDetails.mailNotificationActivated).subscribe(x => {
      this.loadMonitoringDetails();
    })
  }

  public setMonitoringFailureInspected(monitoringFailure: MonitoringFailureDetails) {
    this.monitoringService.inspectFailure(monitoringFailure).subscribe(x => {
      this.loadMonitoringDetails();
    })
  }

  public hasFailures() {
    return Array.isArray(this.monitoringFailures) && this.monitoringFailures.length;
  }
}
