import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MonitoringFailureHistory } from '@root/app/models/MonitoringFailureHistory';
import { MonitoringService } from '@root/app/services/monitoring.service';
import { MonitoringHistoryDto } from '@root/app/models/MonitoringHistoryDto';

@Component({
  selector: 'nwa-historychart',
  templateUrl: './historychart.component.html',
  styleUrl: './historychart.component.less',
})
export class HistorychartComponent implements OnChanges {
  @Input() year: number = new Date().getFullYear();
  @Input() recent: boolean = false;
  @Output() datasetClicked: EventEmitter<MonitoringFailureHistory[]> = new EventEmitter();

  @ViewChild('chart') chart;
  private yAxisIndex: number = 0;
  private yAxisIndexNodeMapping: number[] = [];
  private historyData: MonitoringHistoryDto[];
  constructor(private monitoringService: MonitoringService) {}


  ngOnChanges(changes: SimpleChanges) {
    this.update();
  }

  private update() {
    this.yAxisIndex = 0;
    this.monitoringService
    .getMonitoringFailuresHistory(this.year, this.recent)
    .subscribe((res) => this.setChartData(res));
  }

  private getYAxisIndex(nodeId: number): number {
    let index = this.yAxisIndex++;
    this.yAxisIndexNodeMapping[index] = nodeId;

    return index;
  }

  private setChartData(monitoringFailureHistories: MonitoringHistoryDto[]) {
    this.historyData = monitoringFailureHistories;
    let chartData = monitoringFailureHistories.map((dto) => {
      let index = this.getYAxisIndex(dto.nodeId);
      return {
        name: 'Anschluss ' + dto.nodeId,
        data: dto.failureEntries.map((failure) => {
          return {
            x: failure.startDay,
            y: index,
          };
        }),
      };
    });
    this.chart.updateSeries(chartData);
  }

  private show(element: MonitoringFailureHistory) {
    let nodeFailures = this.historyData.filter((x) => x.nodeId == element.nodeId)[0];
    let dayNodeFailures = nodeFailures.failureEntries.filter(x => x.startDay == element.startDay);

    this.datasetClicked.emit(dayNodeFailures);
  }

  chartOptions = {
    series: [],
    chart: {
      height: '420',
      width: '98%',
      type: 'scatter',
      redrawOnParentResize: true,
      animations: {
        enabled: false,
      },
      events: {
        click: (event, chartContext, config) => {
          let element = this.historyData[config.seriesIndex];
          if (element) {
            let item = element.failureEntries[config.dataPointIndex];
            this.show(item);
          }
        },
      },
      defaultLocale: 'de',
      locales: [
        {
          name: 'de',
          options: {
            months: [
              'Januar',
              'Februar',
              'März',
              'April',
              'Mai',
              'Juni',
              'Juli',
              'August',
              'September',
              'Oktober',
              'November',
              'Dezember',
            ],
            shortMonths: [
              'Jan',
              'Feb',
              'Mär',
              'Apr',
              'Mai',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Okt',
              'Nov',
              'Dez',
            ],
            days: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              download: 'Download SVG',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom',
            },
          },
        },
      ],
    },
    xaxis: {
      title: {
        text: '',
      },
      type: 'datetime',
      tickPlacement: 'on',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {},
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          return this.yAxisIndexNodeMapping[value];
        },
      },
      title: {
        text: 'Anschluss',
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          let element =
            this.historyData[seriesIndex].failureEntries[dataPointIndex];
          return element.title;
        },
      },
    },
    markers: {
      size: 8,
    },
  };
}
