<ng-container [ngSwitch]="todaysSpecialDay">
  <!-- Normales Logo -->
  <img
    *ngSwitchCase="SpecialDay.None"
    src="assets/images/logo.svg"
    style="min-height: 45px; max-height: 50px"
  />
  <!-- Tag der Erde -->
  <div id="earth-day" *ngSwitchCase="SpecialDay.Earthday" class="row ms-2">
    <div class="col-auto">
      <nwa-earth class="icon"></nwa-earth>
    </div>
    <div class="col d-flex align-items-center d-none d-sm-block">
      <div>Tag der<br /><span style="font-size: 20pt">Erde</span></div>
    </div>
  </div>
  <!-- Weltbienentag -->
  <div id="bee-day" *ngSwitchCase="SpecialDay.Worldbeeday" class="row ms-2">
    <div class="col-auto">
        <img src="assets/images/honeycomb.svg" class="icon" />
    </div>
    <div class="col d-flex align-items-center d-none d-sm-block">
        <div>
          Welt-<br /><span style="font-size: 20pt">bienentag</span>
        </div>
      </div>
  </div>
</ng-container>
