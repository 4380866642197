import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@root/app/services/authentication.service';
import * as signalR from '@microsoft/signalr';

import {
  CustomEventDataType,
  CustomEventType,
  SSE,
  SSEOptions,
  SSEOptionsMethod,
} from 'sse-ts';
import { ChatMessage } from '@root/app/models/ChatMessage';
import { ToolCall } from '@root/app/models/ToolCall';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  private connection;

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {}

  public close() {
    this.connection.stop();
  }

  public async connect(context, onMessageReceive: MessageCallback, onThreadReceive: ThreadIdCallback, onToolCallsReceive: ToolCallCallback) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(window.location.origin +'/api/aichat', {
        accessTokenFactory: () => {
          return this.authenticationService.getJwtToken();
        }
      })
      .build();


    await this.connection.start()
      // .then(() => console.log('connected'))
      // .catch((err) => console.error('Error connecting to SignalR hub:', err));

    this.connection.on('chat', (data) => {
      const message = JSON.parse(data);
      onMessageReceive(context, message);
    });

    this.connection.on('threadId', (threadId) => {
      onThreadReceive(context, threadId);
    });

    this.connection.on('toolCall', (calls) => {
      let toolCalls: ToolCall[] = JSON.parse(calls.toString())
      onToolCallsReceive(context, toolCalls);
    })
  }

  public sendMessage(message: ChatMessage) {
    return this.http.post('/api/dashbot', message);
  }

  public createThread() {
    return this.http.post('/api/dashbot/open', null);
  }
}

interface MessageCallback {
  (context: any, message: any): void;
}

interface ThreadIdCallback {
  (context: any, threadId: string) : void;
}

interface ToolCallCallback {
  (context: any, toolCalls: ToolCall[]) : void;
}