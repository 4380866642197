import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[nwaInvalidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: InvalidatorDirective, multi: true}]
})
export class InvalidatorDirective implements Validator {
  @Input() validValues;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors {
    if (!this.validValues) {
      return null;
    }

    let res = this.validValues.includes(control.value);
    if (res) {
      return null;
    }
    return {
      columnName: control.value + ' doesnt exist!'
    };
  }
}
