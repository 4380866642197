<div class="keylogin" [nbSpinner]="loading">
    <div class="row mb-3">
        <div class="col">
            <h5 style="display: inline;">QR-Anmeldung verwalten</h5>
        </div>
        <div class="col d-flex justify-content-end">
            <button nbButton status="primary" shape="round" (click)="add()">
                <nb-icon icon="plus"></nb-icon>
            </button>
        </div>
    </div>

    <!-- Key -->
    @if(authKeys != null && authKeys.length) {
        <nb-card class="mt-3">
            <nb-card-body>
                <table class="table">
                    <thead>
                        <tr>
                            <th>UserId</th>
                            <th>Vorname</th>
                            <th>Nachname</th>
                            <th>Gültig von</th>
                            <th>Gültig bis</th>
                            <th>Aktiv</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        @for(authKey of authKeys; track authKey.authKeyId) {
                            <tr class="authKey">
                                <td>{{authKey.user.userId}}</td>
                                <td>{{authKey.user.firstname}}</td>
                                <td>{{authKey.user.lastname}}</td>
                                <td>
                                    <input nbInput [nbDatepicker]="datepickerValidFrom" [value]="authKey.validFrom | date: 'dd.MM.yyyy'">
                                    <nb-datepicker #datepickerValidFrom format="dd.MM.yyyy" (dateChange)="updateValidFrom(authKey.authKeyId, $event)"></nb-datepicker>
                                </td>
                                <td>
                                    <input nbInput [nbDatepicker]="datepickerValidTo" [value]="authKey.validTo | date: 'dd.MM.yyyy'">
                                    <nb-datepicker #datepickerValidTo format="dd.MM.yyyy" (dateChange)="updateValidTo(authKey.authKeyId, $event)"></nb-datepicker>
                                </td>
                                <td>
                                    <nb-checkbox #nbCheckbox [(checked)]="authKey.active" (change)="toggleAuthKeyActivation(authKey.authKeyId, $event)">
                                    </nb-checkbox>
                                </td>
                                <td class="buttons">
                                    <button nbButton status="control" class="action-button" (click)="showQrCode(authKey)">
                                        <nb-icon icon="fa-qrcode" pack="fa" style="font-size: 20px; margin-top: 2px"></nb-icon>
                                    </button>
                                    <button nbButton status="control" class="action-button" (click)="requestKeyDeletion(authKey.authKeyId)">
                                        <nb-icon icon="trash-outline" status="danger"></nb-icon>
                                    </button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>
    }

    @if (!loading && !authKeys.length) {
        <div class="no-keys d-flex flex-column align-items-center text-center">
            <nb-icon icon="alert-circle-outline" style="font-size: 20vh" class="mt-5"></nb-icon>
            <h1>Keine Keys hinterlegt</h1>
        </div>
    }
</div>

<ng-template #deleteConfirmDialog>
    <nb-card>
        <nb-card-header>Anmeldeschlüssel löschen</nb-card-header>
        <nb-card-body>Soll der gewählte Schlüssel wirklich gelöscht werden?</nb-card-body>
        <nb-card-footer>
            <button nbButton outline (click)="deletionDialog.close()" class="float-start">Abbrechen</button>
            <button nbButton status="danger" (click)="deleteKey()" class="float-end">Löschen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #qrDialogTemplate>
    <nb-card>
        <nb-card-header>QR-Code für {{selectedKey.user.firstname}} {{selectedKey.user.lastname}}</nb-card-header>
        <nb-card-body class="d-flex justify-content-center">
            <qrcode
                #qrCode
            [qrdata]="generateLoginUrl(selectedKey)"
            [allowEmptyString]="true"
            [cssClass]="'center'"
            [colorDark]="'#000000ff'"
            [colorLight]="'#ffffffff'"
            [elementType]="'canvas'"
            [errorCorrectionLevel]="'M'"
            [imageSrc]="'./assets/images/qrcodelogo.png'"
            [imageHeight]="75"
            [imageWidth]="75"
            [margin]="4"
            [scale]="1"
            [width]="300"
          ></qrcode>
        </nb-card-body>
        <nb-card-footer class="float-end">
            <button nbButton (click)="saveAsImage(selectedKey)" class="me-4" status="primary">Code herunterladen</button>
            <button nbButton (click)="qrDialog.close()">Schließen</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<nwa-userpicker (userPicked)="onUserPicked($event)"></nwa-userpicker>