import { MonitoringTreshold } from "./MonitoringTreshold";

export class Monitoring {

  constructor() {
    this.monitoringTresholds = [];
  }

    monitoringId: number;
    monitoringType: MonitoringType; 
    title: string;
    description: string;
    timeRange: number;
    failureRate: number;
    noMessages: boolean;
    failureSeparationTime: number;
    isActive: boolean;

    monitoringNodes: number[];
    monitoringTresholds: MonitoringTreshold[];
  }
  

  export enum MonitoringType {
    Treshold = 0,
    ProtocolTime = 1
}
  