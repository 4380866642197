<div class="d-flex justify-content-end align-items-center">
    <ng-container *ngIf="priceFilter?.nodeId">
        <div class="pill d-flex">
            <span class="align-middle">{{"Anschluss: " + priceFilter.nodeId}}</span>
            <nb-icon icon="close-outline" class="align-middle" (click)="removeFilter('node')"></nb-icon>
        </div>
    </ng-container>
    <ng-container *ngIf="priceFilter?.year">
        <div class="pill d-flex">
            <span class="align-middle">{{"Jahr: " + priceFilter.year}}</span>
            <nb-icon icon="close-outline" class="align-middle" (click)="removeFilter('year')"></nb-icon>
        </div>
    </ng-container>
    <button nbButton shape="semi-round" style="width: fit-content;" class="ms-2" (click)="openFilterDialog()"><nb-icon icon="funnel-outline"></nb-icon></button>
</div>

<ng-template #dialogFilter let-data let-ref="dialogRefpriceFilter">
    <nb-card style="min-width: 300px;">
        <nb-card-header>Filter anpassen</nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col">
                    <label>Jahr</label>
                    <nb-select [(selected)]="priceFilter.year" fullWidth placeholder="Jahr wählen">
                        <nb-option [value]="null">-</nb-option>
                        <nb-option *ngFor="let year of years" [value]="year">
                            {{year}}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label>Anschluss</label>
                    <nb-select [(selected)]="priceFilter.nodeId" fullWidth placeholder="Anschluss wählen">
                        <nb-option [value]="null">-</nb-option>
                        <nb-option *ngFor="let node of nodes" [value]="node.nodeId">
                            {{node.nodeId + " " + node.ownerFirstName + " " + node.ownerLastName}}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton status="primary" (click)="savePriceFilter()" class="float-end">Speichern</button>
        </nb-card-footer>
    </nb-card>
</ng-template>