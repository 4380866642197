import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from 'src/app/models/User';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { WebauthnRegistration } from '../models/Webauthn/WebauthnRegistration.js';
import { WebauthnService } from '../services/webauthn.service';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthKey } from '@root/app/models/AuthKey.js';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private webAuthnService: WebauthnService
  ) {}

  login(user: User) {
    return this.http.post('/api/authentication/', user).pipe(
      map((response) => {
        localStorage.setItem('userInformation', JSON.stringify(response));
        return response;
      })
    );
  }

  logout() {
    localStorage.removeItem('userInformation');
    this.router.navigate(['/login']);
  }

  public getPages() {
    var pages = this.getUserInformation().pages;

    if (pages) {
      return pages;
    }
    this.logout();
  }

  public getUserInformation() {
    if (localStorage.getItem('userInformation') != null) {
      return JSON.parse(localStorage.getItem('userInformation'));
    } else {
      this.logout();
    }
    return null;
  }

  public isAuthenticated(): boolean {
    const token = this.getUserInformation()?.token;
    return token != null && !this.jwtHelper.isTokenExpired(token);
  }

  public isAdmin(): boolean {
    return this.hasRole('Admin');
  }

  public getJwtToken(): string {
    const token = this.getUserInformation()?.token;
    return token;
  }

  public getJwtValue(valueName: string): string {
    const token = this.getUserInformation()?.token;
    var decodedToken = this.jwtHelper.decodeToken(token);
    if (decodedToken.hasOwnProperty(valueName)) {
      return decodedToken[valueName];
    }
    return null;
  }

  private hasRole(role: string): boolean {
    const token = this.getUserInformation()?.token;
    var decodedToken = this.jwtHelper.decodeToken(token);
    if (decodedToken.hasOwnProperty('role')) {
      return decodedToken.role.includes(role);
    }
    return false;
  }

  public getUsername() {
    return this.getUserInformation()?.username ?? '[Name]';
  }

  public getMail() {
    return this.getUserInformation()?.mail ?? null;
  }

  public register(user: User) {
    return this.http.post('api/users/register', user);
  }

  public ping() {
    return this.http.get('api/authentication/ping');
  }

  // WebAUTHN

  public async loginWebAuthn() {
    let requestModel = await this.webAuthnService.getLoginRequestModel();

    const assertion = (await navigator.credentials.get({
      publicKey: requestModel.creationOptions,
    }).catch((error)=> {
      return false;
    })) as any;

    if (assertion === false) {
      return of(false);
    }

    let loginData = await this.webAuthnService.getAssertionData(assertion);

    return this.http.post('api/authentication/webauthn/login', loginData).pipe(
      map((response) => {
        localStorage.setItem('userInformation', JSON.stringify(response));
        return response;
      })
    );
  }

  public async registerWebAuthn() {
    // Vorbereitung
    let requestModel = await this.webAuthnService.getRegistrationRequestModel();

    // Registrierung
    const newCredential = (await navigator.credentials.create({
      publicKey: requestModel.creationOptions,
    })) as any;

    const registration: WebauthnRegistration = {
      attestation: this.webAuthnService.decodeAttestationObj(newCredential),
      clientData: this.webAuthnService.decodeClientData(newCredential),
      sessionId: this.webAuthnService.getSession(),
    };

    return this.http
      .post('api/authentication/webauthn/register', registration)
      .toPromise();
  }

  // AuthKeys

  public createAuthKey(userId: number) {
    return this.http.put('/api/authentication/keys', { userId: userId });
  }

  public getAuthKeys() {
    return this.http.get('/api/authentication/keys');
  }

  loginQrKey(qrKey: string) {
    return this.http.post('/api/authentication/qrKey', { qrKey: qrKey }).pipe(
      map((response) => {
        localStorage.setItem('userInformation', JSON.stringify(response));
        return response;
      })
    );
  }

  public updateQrKey(key: AuthKey) {
    return this.http.post('/api/authentication/keys', key);
  }

  public deleteQrKey(keyId: number) {
    return this.http.delete(`/api/authentication/keys/${keyId}`);
  }
}
