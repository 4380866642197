import { NodeService } from './../../../services/node.service';
import { NbDialogService } from '@nebular/theme';
import { HeatAmountService } from './../../../services/heatamount.service';
import { ContractualAmountOfHeat } from './../../../models/ContractualAmountOfHeat';
import { FilterService } from './../../../services/pricefilter.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ContextMenuItem } from 'src/app/models/ContextMenuItem';

@Component({
  selector: 'nwa-heatamount',
  templateUrl: './heatamount.component.html',
  styleUrls: ['./heatamount.component.less']
})
export class HeatAmountComponent implements OnInit {
  records: ContractualAmountOfHeat[];
  filter;
  @ViewChild('dialogAmountOfHeat') dialogTemplateAmountOfHeat;
  @ViewChild('dialogDeleteAmountOfHeat') dialogTemplateDeleteAmountOfHeat;
  amountOfHeatToDelete: ContractualAmountOfHeat;
  dialogAmountOfHeatTitle;
  formAmountOfHeat: ContractualAmountOfHeat;
  nodes: any;
  years: number[];

  dialogRefAmountOfHeat;
  dialogRefDeleteAmountOfHeat;

  constructor(
    private filterService: FilterService,
    private heatAmountService: HeatAmountService,
    private dialogService: NbDialogService,
    private nodeService: NodeService,
  ) { }

  ngOnInit(): void {
    this.load();

    this.getNodeDropdownPoints();

    this.filterService.getYearSelection().subscribe(x => {
      this.years = x;
    });
  }

  public load() {
    this.filter = this.filterService.getPriceFilter();
    this.heatAmountService.getContractualAmountOfHeats(this.filter)
      .subscribe(x => this.records = x);
  }

  private getNodeDropdownPoints() {
    this.nodeService.getDropDownNodes().subscribe(nodes => {
      this.nodes = nodes;
    })
  }

  public getMenuItems(id): ContextMenuItem[] {
    var items: ContextMenuItem[] = [
      {
        title: 'Bearbeiten',
        icon: { icon: 'edit-outline', pack: '' },
        queryParams: id
      },
      {
        title: 'Löschen',
        icon: { icon: 'trash-outline', pack: '' },
        queryParams: id
      },
      {
        title: 'Neu',
        icon: { icon: 'plus-outline', pack: '' },
        queryParams: id
      }
    ];

    return items;
  }

  public onContextClicked(item: ContextMenuItem) {
    var selectedAmountOfHeat = this.records.filter(x => x.id == item.queryParams)[0];

    switch (item.title) {
      case 'Bearbeiten':
        this.editAmountOfHeat(selectedAmountOfHeat)
        break;
      case 'Neu':
        this.createAmountOfHeat(selectedAmountOfHeat.nodeId);
        break;
      case 'Löschen':
        this.amountOfHeatToDelete = selectedAmountOfHeat;
        this.dialogRefDeleteAmountOfHeat = this.dialogService.open(this.dialogTemplateDeleteAmountOfHeat);
        break;
    }
  }

  public createAmountOfHeat(nodeId?: number) {
    this.dialogAmountOfHeatTitle = "Datensatz erstellen";
    this.formAmountOfHeat = new ContractualAmountOfHeat();
    if (nodeId) {
      this.formAmountOfHeat.nodeId = nodeId;
    } else {
      this.formAmountOfHeat.nodeId = -1;
    }
    this.openAmountOfHeatDialog();
  }

  public editAmountOfHeat(price: ContractualAmountOfHeat) {
    this.dialogAmountOfHeatTitle = "Datensatz bearbeiten";
    this.formAmountOfHeat = price;
    this.openAmountOfHeatDialog();
  }

  public openAmountOfHeatDialog() {
    this.dialogRefAmountOfHeat = this.dialogService.open(this.dialogTemplateAmountOfHeat);
  }

  public saveAmountOfHeat() {
    this.heatAmountService.updateAmountOfHeat(this.formAmountOfHeat).subscribe(x => {
      this.dialogRefAmountOfHeat.close();
      this.load();
    });
  }

  public deleteSelectedPriceConfirmed() {
    this.heatAmountService.deleteAmountOfHeat(this.amountOfHeatToDelete).subscribe(x => {
      this.load();
      this.dialogRefDeleteAmountOfHeat.close();
    });
  }
}
