import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Role } from '@root/app/models/Role';
import { ToolCall } from '@root/app/models/ToolCall';

@Component({
  selector: 'nwa-chatmessage',
  templateUrl: './chatmessage.component.html',
  styleUrl: './chatmessage.component.less',
})
export class ChatMessageComponent implements OnInit {
  @Input() messageType: Role = Role.User;
  @Input() message: string;
  // sanitizedMessage;
  dots: number = 0;
  @Input() toolCalls: ToolCall[] = [];

  constructor(
    private sanitizer: DomSanitizer
  ) {}

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.sanitizedMessage = this.sanitizer.bypassSecurityTrustHtml(this.message);
  // }

  ngOnInit(): void {
    this.loadingText();
  }

  private loadingText() {
    if (this.messageType == Role.Loading) {
      const interval = setInterval(() => {
        this.dots = this.dots >= 3 ? 0 : this.dots + 1;
        this.message = `Denkt${'.'?.repeat(this.dots)}`;
      }, 500);
    }
  }
}
