<nb-card>
    <nb-card-header>Monitoring anlegen</nb-card-header>
    <nb-card-body>
        <!-- <pre>{{ monitoring | json }}</pre> -->
        <form id="monitoringForm" (ngSubmit)="onSubmit()" #monitoringForm="ngForm" class="form-inline">
            <div class="form-group-first">
                <label for="title">Name*</label>
                <input id="text" type="text" nbInput  name="title" required fullWidth [(ngModel)]="monitoring.title" #title="ngModel">
            </div>
            <div class="form-group">
                <label for="description">Beschreibung*</label>
                <textarea id="description" type="text" nbInput name="description" required fullWidth [(ngModel)]="monitoring.description"></textarea>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="monitoringNodes" style="display: inline">Anschlüsse*</label>
                        <button nbButton type="button" (click)="selectAll(monitoringNodes)" size="small" class="ms-1" class="node-button"><nb-icon icon="list-outline"></nb-icon></button><br>
                        <nb-select #monitoringNodes id="monitoringNodes" multiple name="monitoringNodes" [(ngModel)]="monitoring.monitoringNodes" (selectedChange)="updateMonitoringNodesLabel()" fullWidth>
                            <nb-select-label>
                                {{ monitoringNodesLabel }}
                            </nb-select-label>
                            <nb-option *ngFor="let node of nodes" [value]="node.nodeId">{{node.nodeId}}: {{node.ownerFirstName}}<ng-container *ngIf="node.ownerLastName">, {{node.ownerLastName}}</ng-container></nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="timeRange">Mindestdauer* <nb-icon icon="info" status="info" nbTooltip="Zeitraum in Minuten, wie lange der Fehler auftreten muss, damit er gemeldet wird" nbTooltipStatus="primary" class="ms-1"></nb-icon></label>
                        <input id="timeRange" type="number" nbInput  name="timeRange" required [(ngModel)]="monitoring.timeRange" fullWidth>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="failureSeparationTime">Trennzeit* <nb-icon icon="info" status="info" nbTooltip="Zeitraum in Minuten, wie lange ein Fehler nicht mehr auftreten muss, damit er als separater Fehler gemeldet wird." nbTooltipStatus="primary" class="ms-1"></nb-icon></label>
                        <input id="failureSeparationTime" type="number" nbInput name="failureSeparationTime" required [(ngModel)]="monitoring.failureSeparationTime" fullWidth>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="active">Aktivierung</label>
                        <nb-checkbox id="active" name="isActive" [(ngModel)]="monitoring.isActive">Monitoring aktiv</nb-checkbox>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="monitoring.monitoringType == 0">
                <label>Bedingungen</label>
                <div class="row treshold" *ngFor="let treshold of monitoring.monitoringTresholds;let index = index;">
                    <div class="col-md-4 text-center">
                        <nb-form-field>
                            <input name="treshold.column{{index}}" #autoInput nbInput type="text" placeholder="Spalte wählen" [nbAutocomplete]="auto" shape="round" 
                                (focus)="onFocusProtColumnInput()" (input)="onProtColumnTypeChange($event)" [(ngModel)]="treshold.column" autocomplete="off"
                                class="center" nwaInvalidator [validValues]="getValidatorList()" fullWidth>
                            <button nbSuffix nbButton (click)="autoInput.value= ''" shape="round" type="button">
                                <nb-icon icon="close-outline"></nb-icon>
                            </button>
                        </nb-form-field>
                        <nb-autocomplete #auto (selectedChange)="onProtColumnSelectionChange($event)">
                            <nb-option *ngFor="let option of filteredProtColumnOptions | async" [value]="option.name" class="d-flex flex-column">
                                  <span class="protcolumn-suggest">{{option.description}}</span>
                                  <span class="protcolumn-suggest-name">Spalte: {{option.name}}</span>
                            </nb-option>
                        </nb-autocomplete>
                    </div>
                    <div class="col-md-2 text-center">
                        <nb-select class="select-type" name="treshold.type{{index}}" [(ngModel)]="treshold.type" shape="round" fullWidth required>
                            <nb-option [value]="0">&gt;</nb-option>
                            <nb-option [value]="1">&lt;</nb-option>
                            <nb-option [value]="2">=</nb-option>
                            <nb-option [value]="3">≥</nb-option>
                            <nb-option [value]="4">≤</nb-option>
                        </nb-select>
                    </div>
                    <div class="col-md-4">
                            <!-- Number Input -->
                            <input type="number" *ngIf="!treshold.compareWithColumn" name="treshold.value{{index}}" nbInput [(ngModel)]="treshold.value" shape="round" class="center" style="width: 100%" required fullWidth>
                            <!-- Column Input -->
                            <nb-form-field *ngIf="treshold.compareWithColumn">
                                <input name="treshold.columnToCompare{{index}}" #autoCompareInput nbInput type="text" placeholder="Spalte wählen" [nbAutocomplete]="autoCompare" shape="round" 
                                    (focus)="onFocusProtColumnInput()" (input)="onProtColumnTypeChange($event)" [(ngModel)]="treshold.columnToCompare" autocomplete="off"
                                    class="center" nwaInvalidator [validValues]="getValidatorList()" fullWidth>
                                <button nbSuffix nbButton (click)="autoCompareInput.value= ''" shape="round" type="button">
                                    <nb-icon icon="close-outline"></nb-icon>
                                </button>
                            </nb-form-field>
                            <nb-autocomplete #autoCompare (selectedChange)="onProtColumnSelectionChange($event)">
                                <nb-option *ngFor="let option of filteredProtColumnOptions | async" [value]="option.name" class="d-flex flex-column">
                                      <span class="protcolumn-suggest">{{option.description}}</span>
                                      <span class="protcolumn-suggest-name">Spalte: {{option.name}}</span>
                                </nb-option>
                            </nb-autocomplete>
                            <!-- Offset -->
                            <div class="d-flex flex-row align-items-center mt-2 ms-2" *ngIf="treshold.compareWithColumn && treshold.offset != null">
                                <nb-icon icon="plus-circle-outline"></nb-icon>
                                <input type="number" name="treshold.offset{{index}}" nbInput [(ngModel)]="treshold.offset" (click)="$event.target.select()" shape="round" class="ms-1 offset-input" placeholder="0" width="20">
                            </div>
                    </div>
                    <div class="col-md-2 text-center">
                        <!-- Offset Button -->
                        <button nbButton *ngIf="treshold.compareWithColumn" class="align-self-end" type="button" name="add-offset-{{index}}" nbInput (click)="toggleOffset(index)" shape="round" status="control"><nb-icon icon="hash-outline"></nb-icon></button>
                        <!-- Toggle Button -->
                        <button nbButton class="align-self-end" type="button" name="toggle-comparevalue-{{index}}" nbInput (click)="toogleCompareWithColumn(treshold)" shape="round" status="control" style="width: 40px; height: 40px">
                            <i class="fas fa-sort-numeric-down" *ngIf="!treshold.compareWithColumn" style="font-size:15px; vertical-align: middle;"></i>
                            <nb-icon icon="bar-chart-2-outline" *ngIf="treshold.compareWithColumn"></nb-icon>
                        </button>
                        <!-- Remove Treshold Button -->
                        <button nbButton class="align-self-end" name="remove-treshold{{index}}" nbInput (click)="removeTreshold(index)" shape="round" status="control"><nb-icon icon="close-outline"></nb-icon></button>
                    </div>
                </div>
                <button nbButton id="add-treshold" type="button" name="add-treshold" (click)="createNewTreshold()" fullWidth class="mt-2"><nb-icon icon="plus-circle-outline"></nb-icon> Bedingung hinzufügen</button>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton outline class="float-start" (click)="closeForm()">Abbrechen</button>
        <button nbButton type="submit" form="monitoringForm" class="float-end" status="primary" [disabled]="!monitoringForm.form.valid">Speichern</button>
    </nb-card-footer>
</nb-card>