import { StatsService } from 'src/app/services/stats.service';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nwa-heatstationscheme',
  templateUrl: './heatstationscheme.component.html',
  styleUrls: ['./heatstationscheme.component.less']
})
export class HeatstationschemeComponent implements OnInit {
  @Input() params;
  @Input() node;
  @Input() onUpdate : EventEmitter<any>;
  stats;
  loading = true;
  efficiency;
  nodeSumStats;

  constructor(
    private statsService: StatsService
  ) { }

  ngOnInit(): void {

    this.onUpdate.subscribe(x => this.updateOverview());
    this.updateOverview();
  }

  private updateOverview() {
    this.statsService.getHeizomatOverviewStats().subscribe(x => {
      this.stats = x;
      this.loading = false;
    });

    this.statsService.getEfficiency().subscribe(x => {
      this.efficiency = x;
    });

    this.statsService.getNodeSumStats().subscribe(x => {
      this.nodeSumStats = x;
    });
  }

  public getPumpStateColor(pumpState) {
    if (pumpState > 0) {
      return 'rgb(77,207,2)'
    } else {
      return 'rgb(200,203,193)';
    }
  }

  public getMischerState(mischerState) {
    if (mischerState == 0) {
      return '';
    } else if (mischerState == 1) {
      return 'AUF';
    } else if (mischerState == 2) {
      return 'ZU';
    }
  }
}
