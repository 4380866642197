import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistorychartComponent } from '@root/app/components/historychart/historychart.component';
import { MonitoringFailureHistory } from '@root/app/models/MonitoringFailureHistory';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitoringService } from '@root/app/services/monitoring.service';

@Component({
  selector: 'nwa-monitoringhistory',
  templateUrl: './monitoringhistory.component.html',
  styleUrl: './monitoringhistory.component.less',
})
export class MonitoringhistoryComponent implements OnInit {
  historyFailures: MonitoringFailureHistory[];
  year: number;
  recent: boolean = false;
  yearButtons: string[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private monitoringService: MonitoringService) {}

  ngOnInit(): void {
    // Parameter auslesen
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.year) {
        this.year = params.year;
      } else {
        this.year = new Date().getFullYear();
      }
      if (params.recent) {
        this.recent = true;
      } else {
        this.recent = false;
      }
    });

    this.monitoringService.getYearButtons().subscribe(buttons => this.yearButtons = buttons);
  }

  public onDataSetClicked(historiesToShow: MonitoringFailureHistory[]) {
    this.historyFailures = historiesToShow;
  }

  public routeToYear(year: number) {
    this.router.navigate(["/monitoringhistory"], { queryParams: { year: year}});
  }

  public routeToRecent() {
    this.router.navigate(["/monitoringhistory"], { queryParams: { recent: 1}});
  }

  public routeTo(monitoringFailureId: number) {
    this.router.navigate(['/monitoringdetails'], {
      queryParams: { id: monitoringFailureId },
    });
  }
}
